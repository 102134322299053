import React, { useState, useEffect } from 'react';
import { Form, Input, FormText, FormFeedback, Alert } from "reactstrap";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import isEmpty from "../../utils/isEmpty";
import Erc20ABI from "../../ABI/Erc20"
import { toast } from "react-toastify";

const VerifyTokenStepOne = ({
	networkInfo,
	validationForFirstStep,
	clearTokenResponse,
	launchpad,
	handleChangeNetwork,
	handleChangeCurrency,
	getCurrencyOption,
	initializeWeb3,
	details,
}) => {
	const animatedComponents = makeAnimated();
	const [tokenInfo, setTokenInfo] = useState({
		tokenName: "",
		tokenSymbol: "",
		tokenDecimal: 0,
		tokenTotalSupply:0
	})

	const [selectedNetwork, setselectedNetwork] = useState(networkInfo[0]?.name)
	const [selectedCurrency, setselectedCurrency] = useState({})
	const [currencyName, setCurrencyName] = useState("")
	const [defaultNetwork, setDefaultNetwork] = useState({})

	useEffect(() => {
		let Network = networkInfo.find(network => network.chainId === '0x89' )
		setDefaultNetwork(Network.name)
		setselectedNetwork(Network)
		setselectedCurrency(Network.currencies[0])
		setCurrencyName(Network.currencies[0].name ?? "" )
	})

	useEffect(() => {

		const fetchDetails = async () => {
			if(details?.id){
				const web3 = initializeWeb3();
				let abi = Erc20ABI

				if (!web3) return;

				if (validationForFirstStep.values.tokenAddress) {
					const contractAddress = await validationForFirstStep.values.tokenAddress;
					const contract = await new web3.eth.Contract(abi, contractAddress);
					try {
						let name = await contract.methods.name().call();
						let symbol = await contract.methods.symbol().call();
						let decimals = await contract.methods.decimals().call();
						let tokenTotalSupply = await contract.methods.totalSupply().call();
						setTokenInfo({ tokenName: name, tokenSymbol: symbol, tokenDecimal: decimals, tokenTotalSupply:tokenTotalSupply});
						validationForFirstStep.values.tokenName = name
						validationForFirstStep.values.tokenSymbol = symbol
						validationForFirstStep.values.tokenDecimal = Number(decimals)
						validationForFirstStep.values.tokenTotalSupply = Number(tokenTotalSupply)
					} catch (error) {
						setTokenInfo({ tokenName: '', tokenSymbol: '', tokenDecimal: '' ,tokenTotalSupply: null});
						toast.error("Failed to fetch token details! Please check the token address and try again.")
						// Swal.fire({
						// 	icon: 'error',
						// 	title: 'Oops...',
						// 	text: 'Failed to fetch token details! Please check the token address and try again.',
						// });
					}
				}

				setselectedNetwork(details.network.name);
				setselectedCurrency(details.currency)
				setCurrencyName(details.currency[0]?.name ?? "")
			}
		}
		fetchDetails()
	}, [validationForFirstStep.values.tokenAddress])
	

	return (
		<React.Fragment>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					// console.log(e)
					validationForFirstStep.handleSubmit();
					return false;
				}}
			>
				<fieldset disabled={!isEmpty(details?.launchPadDetails?._id)}>
					<div className="text-center mb-4">
						<h5>Verify Token</h5>
						<p className="card-title-desc">
							Enter the token address and verify
						</p>
					</div>

					<div className="row">
						<div className="text-center mb-4">
							{details?.launchPadDetails?._id && (
								<Alert
									className="alert-outline text-danger"
									color="danger"
								>
									Token already deployed you can not edit this
									section
								</Alert>
							)}
						</div>
					</div>

					<div className="mb-3">
						<label
							htmlFor="basicpill-vatno-input"
							className="form-label"
						>
							Network <span style={{ color: 'red' }}>*</span>
						</label>

						<Input
							name="network"
							type="select"
							onChange={(e) => {
								validationForFirstStep.handleChange(e);
								setselectedNetwork(e.target.value);
								handleChangeNetwork(e);
								if (launchpad.tokenDetailResponse?.errors !== undefined) {
									dispatch(clearTokenResponse());
								}
							}}
							onBlur={(e) => {
								validationForFirstStep.handleBlur(e);
							}}
							value={validationForFirstStep.values.network || ""}
							invalid={
								validationForFirstStep.touched.network &&
									validationForFirstStep.errors.network
									? true
									: false
							}
						>
						{networkInfo.length &&
							networkInfo
							.filter(network => network.isActive)
							.map((network, index) => (
								<option
									key={`${index}network`}
									value={network.name}
								>
									{network.name}
								</option>
						))}
						</Input>
						{validationForFirstStep.touched.network &&
							validationForFirstStep.errors.network ? (
							<FormFeedback type="invalid">
								{validationForFirstStep.errors.network?.name}
							</FormFeedback>
						) : null}
					</div>
					<div className="row">
						<div className="col-lg-12">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Token Address <span style={{ color: 'red' }}>*</span>
								</label>
								<Input
									name="tokenAddress"
									type="text"
									placeholder="Token Address"
									onChange={(e) => {
										validationForFirstStep.handleChange(e);

										if (
											launchpad.tokenDetailResponse
												?.errors
										) {
											dispatch(clearTokenResponse());
										}
									}}
									onBlur={(e) => {
										validationForFirstStep.handleBlur(e);
									}}
									value={
										validationForFirstStep.values
											.tokenAddress || ""
									}
									invalid={
										validationForFirstStep.touched
											.tokenAddress &&
											validationForFirstStep.errors
												.tokenAddress
											? true
											: launchpad.tokenDetailResponse
												?.errors &&
												launchpad.tokenDetailResponse
													?.errors?.tokenAddress
												? true
												: false
									}
								/>
								<FormText
									color="primary"
									className="card-title-desc"
								>
									{`Symbol: ${tokenInfo.tokenSymbol || 'N/A'
										}, Decimal: ${tokenInfo.tokenDecimal || 'N/A'}, Total Supply: ${tokenInfo.tokenTotalSupply || 'N/A'
										}`} 
								</FormText>
								{validationForFirstStep.touched.tokenAddress &&
									validationForFirstStep.errors.tokenAddress ? (
									<FormFeedback type="invalid">
										{
											validationForFirstStep.errors
												.tokenAddress
										}
									</FormFeedback>
								) : null}

								{launchpad.tokenDetailResponse?.errors &&
									launchpad.tokenDetailResponse?.errors
										?.tokenAddress ? (
									<FormFeedback type="invalid">
										{
											launchpad.tokenDetailResponse
												?.errors?.tokenAddress
										}
									</FormFeedback>
								) : null}


							</div>
						</div>
					</div>

						<div className="row">
							{/* <div className="col-lg-6">
								<div className="mb-3">
									<label
										htmlFor="basicpill-vatno-input"
										className="form-label"
									>
										Token Name <span style={{ color: 'red' }}>*</span>
									</label>
									<Input
										name="tokenName"
										type="text"
										value={
											validationForFirstStep.values
												.tokenName || ""
										}
									/>
								</div>
							</div> */}
							<div className="col-lg-6">
								<div className="mb-3">
									<label
										htmlFor="basicpill-vatno-input"
										className="form-label"
									>
										Token Symbol <span style={{ color: 'red' }}>*</span>
									</label>
									<Input
										name="tokenSymbol"
										type="text"
										disabled
										value={
											validationForFirstStep.values
												.tokenSymbol || ""
										}
									/>
								</div>
							</div>

							<div className="col-lg-6">
								<div className="mb-3">
									<label
										htmlFor="basicpill-vatno-input"
										className="form-label"
									>
										Token Decimal <span style={{ color: 'red' }}>*</span>
									</label>
									<Input
										name="tokenDecimal"
										type="text"
										disabled
										value={
											validationForFirstStep.values
												.tokenDecimal || ""
										}
									/>
								</div>
							</div>
						
						</div>

					<div className="row">
						<div className="col-lg-12">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Token Owner Address <span style={{ color: 'red' }}>*</span>
								</label>
								<Input
									name="ownerAddress"
									type="text"
									placeholder="Token Owner Address"
									onChange={

										validationForFirstStep.handleChange

									}
									onBlur={validationForFirstStep.handleBlur}
									value={
										validationForFirstStep.values
											.ownerAddress || ""
									}
									invalid={
										validationForFirstStep.touched
											.ownerAddress &&
											validationForFirstStep.errors
												.ownerAddress
											? true
											: false
									}
								/>
								<FormText
									color="primary"
									className="card-title-desc"
								>
									{`Raised fund will be send To this wallet address`}
								</FormText>
								{validationForFirstStep.touched
									.ownerAddress &&
									validationForFirstStep.errors
										.ownerAddress ? (
									<FormFeedback type="invalid">
										{
											validationForFirstStep.errors
												.ownerAddress
										}
									</FormFeedback>
								) : null}
							</div>
						</div>
					</div>

					<div className="row">
						<div className="mb-3">
							<label className="control-label">Currency <span style={{ color: 'red' }}>*</span></label>
							<Select
								name="currency"
								value={
									validationForFirstStep.values.currency || defaultNetwork.currencies[0]
								}
								onChange={(value, action) => {
									handleChangeCurrency(value, action);
									setCurrencyName(getCurrencyOption.find(item => item.name === value.name)?.name);
								}}
								options={getCurrencyOption}
								isDisabled={
									!isEmpty(details?.launchPadDetails?.id)
								}
							/>
							<FormText
								color="primary"
								className="card-title-desc"
							>
								{`Users will pay with ${currencyName} for your token`}
							</FormText>
						</div>
					</div>
				</fieldset>
			</Form>
		</React.Fragment>
	);
};

export default VerifyTokenStepOne;