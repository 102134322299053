import React, { useEffect, useState, useRef } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
	Table,
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Label,
	Input,
} from "reactstrap";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import { apiUrl } from "../../config";
import Swal from "sweetalert2";
import {
	updateRole,
	createRole,
	removeRole,
	clearResponse,
} from "../../store/admin/actions";

const Role = (props) => {

	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const [modules, setModules] = useState([]);
	const getLoaderStatus = useRef(null);
	const [policies, setPolicies] = useState([]);
	const { response, auth } = useSelector((state) => ({
		response: state.admin.response,
		auth: state.auth,
	}));

	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [existingRoleDetails, handleFormData] = useState({
		active: true,
	});
	const [selectedRules, setSelectedRules] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [initialSelectedRule, setInitialSelectedRule] = useState([])
	const [roles, setRoles] = useState([]);
	const [selectedRoles, setSelectedRoles] = useState([])

	useEffect(() => {
		const fetchRoles = async () => {
			try {
				const response = await axios.get(`${apiUrl}/auth/roles`);
				setRoles(response.data);
			} catch (error) {
				console.error("Error fetching roles:", error);
			}
		};
		fetchRoles();
	}, []);

	useEffect(() => {
		if (response) {
			if (response.code === "200" || response.message === "New role with modules and policies created." || response.message === "Role with modules and policies updated." || response.message === "Role deleted successfully.") {
				refreshTableData.current();
				if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
				Swal.fire({
					title: "Success!",
					text: response.message,
					icon: "success",
				}).then(() => {
					dispatch(clearResponse());
				});
			}
			else if (response.message) {
				refreshTableData.current();
				Swal.fire({
					title: "Error!",
					text: response.message,
					icon: "error",
				}).then(() => {
					dispatch(clearResponse());
				});
			}
		}
	}, [dispatch, response]);

	useEffect(() => {
		const fetchModules = async () => {
			try {
				const response = await axios.get(`${apiUrl}/auth/modules`);
				setModules(response.data);
			} catch (error) {
				console.error("Error fetching modules:", error);
			}
		};
		fetchModules();
	}, []);

	useEffect(() => {
		const fetchPolicies = async () => {
			try {
				const response = await axios.get(`${apiUrl}/auth/policies`);
				setPolicies(response.data);
			} catch (error) {
				console.error("Error fetching policies:", error);
			}
		};
		fetchPolicies();
	}, []);

	const handleCheckboxChange = (moduleId, policyId, checked) => {
		setSelectedRules(prevSelectedRules => {
			const updatedRules = [...prevSelectedRules];
			if (checked) {
				if (!updatedRules.some(rule => rule.moduleId === moduleId && rule.policyId === policyId)) {
					updatedRules.push({ moduleId, policyId });
				}
			} else {
				const indexToRemove = updatedRules.findIndex(rule => rule.moduleId === moduleId && rule.policyId === policyId);
				if (indexToRemove !== -1) {
					updatedRules.splice(indexToRemove, 1);
				}
			}
			return updatedRules;
		});
	};

	const handleAddEditModal = (data = null) => {
		if (!isEmpty(data) && data?.id) {
			handleFormData({ ...data, role: data.roleName });
			setSelectedRoles(data.roles);

			// Initialize selectedRules based on data.roles
			const initialSelectedRules = data.roles.map(role => {
				const [policyName, moduleName] = role.split(":");
				const module = modules.find(m => m.name === moduleName);
				const policy = policies.find(p => p.name === policyName);
				return { moduleId: module?.id, policyId: policy?.id };
			});

			setInitialSelectedRule(initialSelectedRules)
			setSelectedRules(initialSelectedRules);
		} else {
			handleFormData({ active: true });
			setSelectedRoles([]);
			validation.resetForm();
		}
		// setSelectedRules([]); // Reset selected rules
		toggleAddEditModal(!isOpenAddEditModal);
	};

	const removeItem = (id) => {
		if (!isEmpty(id)) {
			Swal.fire({
				title: "Are you sure?",
				text: "Do you really want to delete?",
				icon: "warning",
				showCancelButton: true,
				confirmButtonColor: "#3085d6",
				cancelButtonColor: "#d33",
				confirmButtonText: "Confirm",
			}).then((result) => {
				if (result.value) {
					dispatch(removeRole(id));
				}
			});
		}
	};

	const useFormikOptions = {
		enableReinitialize: true,
		initialValues: {
			name: existingRoleDetails && existingRoleDetails.roleName ? existingRoleDetails.roleName : "",
			rules: existingRoleDetails && existingRoleDetails.rules ? existingRoleDetails.rules : "",
		},
		onSubmit: async (values) => {
			if (values.name === "" || selectedRules.length === 0) {
				Swal.fire({
					title: "Error!",
					text: "Name / Rules cannot be empty",
					icon: "error",
				});
				return;
			}
			const rules = selectedRules.map(rule => ({
				moduleId: rule.moduleId,
				policyId: rule.policyId
			}));

			if (existingRoleDetails && existingRoleDetails.id) {
				await dispatch(updateRole({
					...values,
					rules,
					id: existingRoleDetails.id,
				}));
				setSelectedRules([])
			} else {
				await dispatch(createRole({ ...values, rules }));
			}
		}
	};

	const validation = useFormik(useFormikOptions);

	const columns = () => [
		{
			label: "ID",
			name: "id",
			options: {
				filter: false,
				sortOrder: 'asc',
				sortCompare: (sortOrder) => {
					return (obj1, obj2) => {
					  return (obj1.data - obj2.data) * (sortOrder === 'asc' ? 1 : -1);
					};
				  },
				download: true,
				customBodyRender: (value) => {
					return (
						<div className="text-center">
							<div className=" font-size-14">{value}</div>
						</div>
					);
				},
			},
		},
		{
			label: "Role Name",
			name: "roleName",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (value) => {
					return (
						<div className="text-center">
							<div className=" font-size-14">{value}</div>
						</div>
					);
				},
			},
		},
		{
			label: "Roles",
			name: "modules",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (value) => {
					return (
						<div className="text-center">
							{value.map((module) => (
								<div key={module.name}>
									<div>
										<strong>{module.name}:</strong>{" "}
										{module.policies}
									</div>
								</div>
							))}
						</div>
					);
				},
			},
		},
		{
			label: "Actions",
			name: "action",
			options: {
				filter: false,
				sort: false,
				empty: true,
				display: true,
				download: false,
				customBodyRender: (data) => {
					return (
						<HasAnyPermission
							permission={["create:Users", "update:Users"]}
						>
							<div className="d-flex flex-wrap gap-2 text-center">
								<HasAnyPermission
									permission={["update:Roles"]}
								>
									<button
										type="button"
										className="btn btn-sm btn-primary"
										onClick={() => handleAddEditModal(data)}
									>
										Edit Roles
									</button>
								</HasAnyPermission>
								<HasAnyPermission
									permission={["delete:Roles"]}
								>
									<button
										type="button"
										className="btn btn-sm btn-danger"
										onClick={() => removeItem(data.id)}
									>
										Delete Role
									</button>
								</HasAnyPermission>
							</div>
						</HasAnyPermission>
					);
				},
			},
		},
	];

	const resultFormatter = (result) => {
		return result.map((item) => {
			const { roleName, roles } = item;
			const rolePolicies = roles.reduce((acc, role) => {
				const [action, module] = role.split(":");
				if (!acc[module]) {
					acc[module] = [];
				}
				acc[module].push(action);
				return acc;
			}, {});

			return {
				id: item.id,
				roleName: roleName,
				modules: Object.keys(rolePolicies).map((module) => ({
					name: module,
					policies: rolePolicies[module].join(", "),
				})),
				action: item,
			};
		});
	};

	const handlePageChange = async (newPage) => {
		await setPage(newPage + 1);
		await refreshTableData.current();
	};

	const handleRowsPerPageChange = async (newRowsPerPage) => {
		await setPage(1)
		await setRowsPerPage(newRowsPerPage);
		await refreshTableData.current();
	};

	const couldHaveAddUpdatePermission = () => {
		if (auth.user.isSuperAdmin) return true;
		const isUpdatePermission = hasPermission(
			["update:Roles"],
			auth.user.allowedRoles
		);
		const isAddPermission = hasPermission(
			["create:Roles"],
			auth.user.allowedRoles
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(existingRoleDetails.id)) return true;
		else if (isAddPermission && isEmpty(existingRoleDetails.id)) return true;
		else return false;
	};

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Admin | Rampstarter</title>
				</Helmet>
				<Container fluid>
					<Breadcrumbs title="Admin" breadcrumbItem="Roles" />
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"read:Roles",
														]}
													>
														<ReactDataTable
															url={`${apiUrl}/auth/roles?page=${page}&limit=${rowsPerPage}`}
															columns={columns()}
															resultFormatter={resultFormatter}
															setRefresh={refreshTableData}
															getLoaderStatus={getLoaderStatus}
															disableFilterIcon={true}
															disableSearchIcon={true}
															onChangePage={handlePageChange}
															onChangeRowsPerPage={handleRowsPerPageChange}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		Roles&nbsp;&nbsp;
																		<HasAnyPermission
																			permission={["create:Roles"]}
																		>
																			<button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				Add Role
																			</button>
																		</HasAnyPermission>
																	</div>
																</div>
															}
															rowsPerPage={10}
														/>
													</HasAnyPermission>

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														toggle={
															handleAddEditModal
														}
														backdrop={"static"}
														size="lg"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddEditModal
															}
															tag="h4"
														>
															{existingRoleDetails?.id
																? "Edit"
																: "Add"}
														</ModalHeader>
														<ModalBody>
															<Form
																disabled
																onSubmit={(e) => {
																	e.preventDefault();
																	validation.handleSubmit();
																	return false;
																}}
															>
																<fieldset
																	disabled={
																		!couldHaveAddUpdatePermission()
																	}
																>
																	<Row
																		form={
																			"true"
																		}
																	>
																		<Col
																			xs={
																				12
																			}
																		>
																			<div className="mb-3">
																				<Label className="form-label">
																					Role Name<span style={{ color: 'red' }}>*</span>
																				</Label>
																				<Input
																					name="name"
																					type="text"
																					onChange={
																						validation.handleChange
																					}
																					onBlur={
																						validation.handleBlur
																					}
																					value={
																						validation
																							.values
																							.name ||
																						""
																					}
																					invalid={
																						validation
																							.touched
																							.name &&
																							validation
																								.errors
																								.name
																							? true
																							: false
																					}
																				/>
																				{validation
																					.touched
																					.name &&
																					validation
																						.errors
																						.name ? (
																					<FormFeedback type="invalid">
																						{
																							validation
																								.errors
																								.name
																						}
																					</FormFeedback>
																				) : null}
																			</div>
																		</Col>
																		<Col xs={12}>
																			<div className="mb-3">
																				<Label className="form-label">Rules<span style={{ color: 'red' }}>*</span></Label>
																				<Table>
																					<thead>
																						<tr>
																							<th>Module</th>
																							{policies.map((policy) => (
																								<th key={policy.id}>{policy.name}</th>
																							))}
																						</tr>
																					</thead>
																					<tbody>
																						{modules.map(module => (
																							<tr key={module.id}>
																								<td>{module.name}</td>
																								{policies.map(policy => (
																									<td key={policy.id}>
																										<input
																											name={`selectedRules.${module.id}.${policy.id}`}
																											defaultChecked={selectedRoles.some(item => item === `${policy.name}:${module.name}`)}
																											type="checkbox"
																											onChange={(e) => {
																												const { checked } = e.target;
																												handleCheckboxChange(module.id, policy.id, checked);
																											}}
																										/>
																									</td>
																								))}
																							</tr>
																						))}
																					</tbody>
																				</Table>
																			</div>
																		</Col>
																	</Row>
																	<Row>
																		<Col>
																			<div className="text-end mt-3">
																				<button
																					type="submit"
																					className="btn btn-success save-user"
																				>
																					Submit
																				</button>
																			</div>
																		</Col>
																	</Row>
																</fieldset>
															</Form>
														</ModalBody>
													</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment>
	);
};

export default Role;
