import React, { useEffect, useState, useRef } from "react";
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from "axios";
import { Helmet } from "react-helmet";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
  FormGroup,
  Modal,
	ModalHeader,
	ModalBody,
  Label,
  Input,
} from "reactstrap";
import { apiUrl } from "../../config";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import {
  createWhitelist,
  clearResponse,
} from "../../store/admin/actions";
import Swal from "sweetalert2";
import hasPermission from "../../common/HasPermission";
import HasAnyPermission from "../../common/Permission";
import FactoryABI from "../../ABI/facoryContract.json"
import Web3 from 'web3';

const Admin = () => {
const dispatch = useDispatch();

const [whitelistRoot, setwhitelistRoot] = useState([]);
const [whitelistWalletcount, setWhitelistwallets] = useState([]);
const [networkInfo, setNetworkInfo] = useState([]);
const [isOpenWhitelist, toggleIsOpenWhitelist] = useState(false);

const [factoryContractAddress, setFactoryContractAddress] = useState([]);
const { response,auth } = useSelector((state) => ({
  response: state.admin.response,
  auth: state.auth,
}));

useEffect(() => {
  const fetchWhitelistaddress = async () => {
    if (auth.user.isSuperAdmin || hasPermission(["read:Proofs"],auth.user.allowedRoles)){
      try {
        const response = await axios.get(`${apiUrl}/networks/all`);
        setNetworkInfo(response.data);
      } catch (error) {
      }
    }
  };
  fetchWhitelistaddress();
}, [dispatch, response]);

useEffect(() => {
  const fetchNetworks = async () => {
    if (auth.user.isSuperAdmin || hasPermission(["read:Networks"],auth.user.allowedRoles)){
      try {
        const response = await axios.get(`${apiUrl}/proofs/whitelist`);
        const newValue = response.data[0].walletAddress.replaceAll(",", "\n");
        setwhitelistRoot(response.data[0].whitelistRoot);
        const lineCount = (newValue.match(/\n/g) || []).length + 1; 
        setWhitelistwallets(lineCount)
      } catch (error) {
      }
    }
  };
  fetchNetworks();
}, [dispatch, response]);

const initializeWeb3 = () => {
  if (window.ethereum) {
    return new Web3(window.ethereum);
  } else {
    console.error('MetaMask is not installed');
    return null;
  }
};

useEffect(() => {
  const fetchResponse = async() => {
  if (response) {
    if (response.code === "201" || response.whitelistRoot) {} 
    else if (response.message) {
      Swal.fire({
        title: "Error!",
        text: response.message,
        icon: "error",
      }).then(() => {
        dispatch(clearResponse());
      });
    }
    }
  }
  fetchResponse()
}, [dispatch, response]);

const useFormikOptions = {
  enableReinitialize: true,
    initialValues: {
      whitelistFile:""
    },
    onSubmit: async (values) => {
      var formData = new FormData();
      formData.append('whitelistFile', values.whitelistFile);
      // await dispatch(createWhitelist(formData))
      let res = await axios.post(`${apiUrl}/proofs/whitelist`, formData, {
          headers: {
          'content-Type': 'multipart/form-data'
        }
		  })
      const web3 = initializeWeb3();
      if (!web3) {
        Swal.fire('Error', 'Web3 initialization failed.', 'error');
        return;
      }
      try {
        const factoryABI = FactoryABI;
        const contractPublicSale = new web3.eth.Contract(factoryABI, factoryContractAddress);
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
        if (!res.data.whitelistRoot) {
          Swal.fire('Error', 'Failed to fetch whitelist root', 'error');
          return;
        }
        const uploadWhitelist = await contractPublicSale.methods
          .addwhitelistHash(res.data.whitelistRoot)
          .send({ from: fromAddress });
          Swal.fire('Success', 'Whitelist root added successfully!', 'success');
          toggleIsOpenWhitelist(!isOpenWhitelist)
      } catch (error) {
          console.error('Error setting whitelist root:', error);
          Swal.fire('Transaction Error', 'Error during whitelist root setting: ' + error.message, 'error');
          toggleIsOpenWhitelist(!isOpenWhitelist)
      }
    }
  };

const validation = useFormik(useFormikOptions);

const couldHaveProofPermission = () => {
  if (auth.user.isSuperAdmin) return true;
  const isUpdatePermission = hasPermission(
    ["update:Proofs"],
    auth.user.allowedRoles
  );
  const isAddPermission = hasPermission(
    ["create:Proofs"],
    auth.user.allowedRoles
  );
  if (isUpdatePermission && isAddPermission) return true;
  else if (isUpdatePermission && !isEmpty(details.id)) return true;
  else if (isAddPermission && isEmpty(details.id)) return true;
  else return false;
};

const checkNetwork = async (_chainId, rpc, networkName) => {
  if (window.ethereum) {
    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
    if (chainId !== _chainId ) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: _chainId}], 
        });
        // window.location.reload()
      } catch (error) {
        if (error.code === 4902) {
          try {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainId: _chainId,
                  chainName: networkName,
                  rpcUrls: [rpc],
                },
              ],
            });
            // window.location.reload()
          } catch (addError) {
            console.error('Failed to add the Fantom Testnet', addError);
          }
        }
        console.error('Failed to switch to the Fantom Testnet', error);
      }
    }
  } else {
    console.log('Ethereum object not found, install MetaMask.');
  }
}

const handleNetworkChange = async(e) => {
    let network =  networkInfo.find(net => net.name === e.target.value)
    await checkNetwork(network.chainId,network.rpc,network.name)
    setFactoryContractAddress(network.factoryContractAddress)
}

const toggle = () => {
  toggleIsOpenWhitelist(!isOpenWhitelist);
};
  return (
    <React.Fragment>
      <div className="page-content">
        <Helmet>
          <title>Admin | LFi</title>
        </Helmet>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Admin" breadcrumbItem="Whitelist" />
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="table-rep-plugin">
                        <div className="table-responsive">
                          {/* <Label for="whitelistAddresses">Whitelist Data</Label>
                          <br></br> */}
                          <span>
                                Whitelist Root : {whitelistRoot}
                              </span><br></br><br></br>
                              <span>
                                Total whitelisted wallets : {whitelistWalletcount}
                              </span><br></br>
                              <br></br>
                              <button
                              type="button"
                              className=" mt-5 btn btn-primary"
                              onClick={() => {
                                const accounts = window.ethereum.request({ method: 'eth_requestAccounts' });
                                const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
                                if (!fromAddress) {
                                  Swal.fire('Error', 'Please connect your wallet', 'error');
                                  return;
                                }
                                toggleIsOpenWhitelist(!isOpenWhitelist);
                              }}
                              >
                                Upload Whitelist data
                              </button>

                              <Modal
                              isOpen={
                                isOpenWhitelist
                              }
                              toggle={toggle}
                              backdrop={"static"}
                              size="lg"
                              centered={true}
                        >
                        <ModalHeader
                          tag="h4"
                          toggle={toggle}
                        >
                          Update Whitelist Hash
                        </ModalHeader>
                          <ModalBody>
                            <Form
                              disabled
                              onSubmit={(e) => {
                                e.preventDefault();
                                validation.handleSubmit();
                                return false;
                              }}
                            >
                            <fieldset
                                    disabled={
                                      !couldHaveProofPermission()
                                    }
                            >
                              <HasAnyPermission
                              permission={[
                                "read:Proofs",
                                "read:Proofs",
                              ]}>
                              <FormGroup>
                                  <Input
                                    name="network"
                                    type="select"
                                    defaultValue={""}
                                    onChange={(e) => {  
                                      handleNetworkChange(e)
                                    }}
                                  > 
                                    <option value="" disabled >
                                      Select Network
                                    </option>
                                    {networkInfo.length &&
                                      networkInfo.map((network, index) => (
                                        <option
                                          key={`${index}network`}
                                          value={network.name}
                                        >
                                          {network.name}
                                        </option>
                                      ))}
                                  </Input>
                                  <br></br>
                                  <Input
                                    name="whitelistFile"
                                    type="file"
                                    onChange={(e) => {
                                      validation.setFieldValue('whitelistFile', e.target.files[0]);
                                    }}
                                  />
                                <div className="text-end mt-3">
                                <button type="submit" className="btn btn-success save-user">
                                  Submit
                                </button>
                                </div>
                              </FormGroup>
                              </HasAnyPermission>
                            </fieldset>
                            </Form>  
                          </ModalBody>
                        </Modal>      
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Admin;
