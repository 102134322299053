import axios from "axios";
import { apiUrl } from "../../config";

export const create = (formData) =>
	axios
		.post(`${apiUrl}/projects`, formData,{
			headers: {
			  'Content-Type': 'multipart/form-data' 
			}
		  })
		.then((response) => response)
		.catch((err) => err.response);

export const getLaunchpadOptions = () =>
	axios
		// .get(`${apiUrl}/networks/all/?page=1&limit=10`)  
		.get(`${apiUrl}/networks/all`)  
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data) =>   {
	return axios
		.patch(`${apiUrl}/projects/${data.id}`, data)
		.then((response) => {response})
		.catch((err) => err.response);

}

export const toggle = (data) =>
	axios
		.patch(`${apiUrl}/projects/${data.id}`,{isActive: data.isActive})
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (id) =>
	axios
		.delete(`${apiUrl}/admin/launchpad/delete/${id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getTokenDetails = (data) =>
	axios
		.post(`${apiUrl}/admin/launchpad/get-token-details`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const deploy = (data) =>
	axios
		.post(`${apiUrl}/admin/launchpad/deploy`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const getLaunchpadService = () =>
	axios
		.get(`${apiUrl}/admin/launchpad/get-all`)
		.then((response) => response)
		.catch((err) => err.response);

export const getNetworkInfoService = () =>
	axios
		.get(`${apiUrl}/networks/all`)
		.then((response) => response)
		.catch((err) => err.response);

export const getLaunchpadDeployHistoryService = (data) =>
	axios
		.get(`${apiUrl}/admin/launchpad/deploy-history/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const toggleSwapService = (data) =>
	axios
		.get(`${apiUrl}/admin/launchpad/toggle-swap/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const updateStageService = (data) =>
	axios
		.put(
			`${apiUrl}/admin/launchpad/stage/${data._id}/${data.stageNumber}`,
			data
		)
		.then((response) => response)
		.catch((err) => err.response);

export const deployUpdatedStageService = (data) =>
	axios
		.put(
			`${apiUrl}/admin/launchpad/deploy/stage/${data._id}/${data.stageNumber}`,
			data
		)
		.then((response) => response)
		.catch((err) => err.response);

export const toggleStageSwapService = (data) =>
	axios
		.get(
			`${apiUrl}/admin/launchpad/toggle-swap-stage/${data._id}/${data.stageNumber}`
		)
		.then((response) => response)
		.catch((err) => err.response);

export const getUpdatedLaunchpadService = (data) =>
	axios
		.get(`${apiUrl}/admin/launchpad/details/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getLauchpadStagesService = (data) =>
	axios
		.get(`${apiUrl}/admin/launchpad/stages/${data._id}`)
		.then((response) => response)
		.catch((err) => err.response);
