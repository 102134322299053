import React ,{useState} from "react";
// import { FormFeedback, Form, Input } from "reactstrap";
import {FormFeedback, Form, Modal,ModalHeader,ModalBody ,Col, Row, Card, CardBody, CardHeader, Input,FormText   } from "reactstrap";
import Dropzone from "react-dropzone";
import isEmpty from "../../utils/isEmpty";

const AdditionalInfoStepThird = ({
	validationForThirdStep,
	validationForProjectPicture,
	handleAddProjectPicture,
	projectPicture
}) => {

	const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

	return (
		<React.Fragment>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					validationForThirdStep.handleSubmit();
					return false;
				}}
			>
				<div>
					<div className="text-center mb-4">
						<h5>Add Project Info</h5>
						<p className="card-title-desc">
							Let people know who you are
						</p>
					</div>
					<div className="row">
						<div className="col-lg-6">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Project Logo <span style={{ color: 'red' }}>*</span>
								</label> <span></span>
							<button
								type="button"
								className="btn btn-soft-primary waves-effect waves-light"
								onClick={(e) => {
									e.preventDefault();
									handleAddProjectPicture();
								}}
								invalid = {validationForProjectPicture.touched.picture &&
									validationForProjectPicture.errors.picture ? (
									<FormFeedback type="invalid">
										{validationForProjectPicture.errors.picture}
									</FormFeedback>
								) : null}
							> 
							{projectPicture?.picture ? "Edit Logo" : "Add Logo"}
							</button>
							</div>
						</div>

						
					</div>

					

					<div className="row">
						<div className="col-lg-6">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Project Title <span style={{ color: 'red' }}>*</span>
								</label>
								<Input
									name="projectTitle"
									type="text"
									onChange={
										validationForThirdStep.handleChange
									}
									onBlur={validationForThirdStep.handleBlur}
									value={
										validationForThirdStep.values
											.projectTitle || ""
									}
									invalid={
										validationForThirdStep.touched
											.projectTitle &&
											validationForThirdStep.errors
												.projectTitle
											? true
											: false
									}
								/>
								{validationForThirdStep.touched.projectTitle &&
									validationForThirdStep.errors.projectTitle ? (
									<FormFeedback type="invalid">
										{
											validationForThirdStep.errors
												.projectTitle
										}
									</FormFeedback>
								) : null}
							</div>
						</div>

						<div className="col-lg-6">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Project Sub Title <span style={{ color: 'red' }}>*</span>
								</label>
								<Input
									name="projectSubTitle"
									type="text"
									onChange={
										validationForThirdStep.handleChange
									}
									onBlur={validationForThirdStep.handleBlur}
									value={
										validationForThirdStep.values
											.projectSubTitle || ""
									}
									invalid={
										validationForThirdStep.touched
											.projectSubTitle &&
											validationForThirdStep.errors
												.projectSubTitle
											? true
											: false
									}
								/>
								{validationForThirdStep.touched
									.projectSubTitle &&
									validationForThirdStep.errors
										.projectSubTitle ? (
									<FormFeedback type="invalid">
										{
											validationForThirdStep.errors
												.projectSubTitle
										}
									</FormFeedback>
								) : null}
							</div>
						</div>
						</div>
						<div className="row">
							<div className="col-lg-6">
								<div className="mb-3">
									<label
										htmlFor="basicpill-firstname-input"
										className="form-label"
									>
										Website <span style={{ color: 'red' }}>*</span>
									</label>
									<Input
										name="website"
										type="text"
										onChange={
											validationForThirdStep.handleChange
										}
										onBlur={validationForThirdStep.handleBlur}
										value={
											validationForThirdStep.values
												.website || ""
										}
										invalid={
											validationForThirdStep.touched
												.website &&
												validationForThirdStep.errors.website
												? true
												: false
										}
									/>
									{validationForThirdStep.touched.website &&
										validationForThirdStep.errors.website ? (
										<FormFeedback type="invalid">
											{
												validationForThirdStep.errors
													.website
											}
										</FormFeedback>
									) : null}
								</div>
							</div>
							<div className="col-lg-6">
								<div className="mb-3">
									<label
										htmlFor="basicpill-firstname-input"
										className="form-label"
									>
										Embedded video URL <span style={{ color: 'red' }}>*</span>
									</label>
									<Input
										name="embeddedVideoUrl"
										type="text"
										onChange={
											validationForThirdStep.handleChange
										}
										onBlur={validationForThirdStep.handleBlur}
										value={
											validationForThirdStep.values
												.embeddedVideoUrl || ""
										}
										invalid={
											validationForThirdStep.touched
												.embeddedVideoUrl &&
												validationForThirdStep.errors
													.embeddedVideoUrl
												? true
												: false
										}
									/>
									{validationForThirdStep.touched.embeddedVideoUrl &&
										validationForThirdStep.errors.embeddedVideoUrl ? (
										<FormFeedback type="invalid">
											{
												validationForThirdStep.errors
													.embeddedVideoUrl
											}
										</FormFeedback>
									) : null}
								</div>
							</div>
						</div>

						<div className="col-lg-12">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Small Description <span style={{ color: 'red' }}>*</span>
								</label>
								<Input
									name="description"
									type="textarea"
									onChange={
										validationForThirdStep.handleChange
									}
									onBlur={validationForThirdStep.handleBlur}
									value={
										validationForThirdStep.values
											.description || ""
									}
									invalid={
										validationForThirdStep.touched
											.description &&
											validationForThirdStep.errors
												.description
											? true
											: false
									}
								/>
								{validationForThirdStep.touched.description &&
									validationForThirdStep.errors.description ? (
									<FormFeedback type="invalid">
										{
											validationForThirdStep.errors
												.description
										}
									</FormFeedback>
								) : null}
							</div>
						</div>
					</div>

					<div className="row">
						<div className="mb-3">
							<label
								htmlFor="basicpill-firstname-input"
								className="form-label"
							>
								About Project <span style={{ color: 'red' }}>*</span>
							</label>
							<Input
								type="textarea"
								name="aboutProject" // Make sure the 'name' attribute matches your state management expectations
								id="aboutProject" // Adding an 'id' for the label 'for' attribute
								rows="10"  // Sets the number of lines the textarea will display
								cols="100"
								onChange={validationForThirdStep.handleChange}
								onBlur={validationForThirdStep.handleBlur}
								value={validationForThirdStep.values.aboutProject || ""}
								invalid={!!validationForThirdStep.touched.aboutProject && !!validationForThirdStep.errors.aboutProject}
							/>
							{/* Conditionally rendering FormFeedback only if there are errors */}
							{validationForThirdStep.touched.aboutProject && validationForThirdStep.errors.aboutProject && (
								<FormFeedback>
									{validationForThirdStep.errors.aboutProject}
								</FormFeedback>
							)}
					</div>
					</div>

					<div className="row">
						<div className="col-lg-6">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Facebook
								</label>
								<Input
									name="facebook"
									type="text"
									onChange={
										validationForThirdStep.handleChange
									}
									onBlur={validationForThirdStep.handleBlur}
									value={
										validationForThirdStep.values
											.facebook || ""
									}
									invalid={
										validationForThirdStep.touched
											.facebook &&
											validationForThirdStep.errors.facebook
											? true
											: false
									}
								/>
								{validationForThirdStep.touched.facebook &&
									validationForThirdStep.errors.facebook ? (
									<FormFeedback type="invalid">
										{validationForThirdStep.errors.facebook}
									</FormFeedback>
								) : null}
							</div>
						</div>

						<div className="col-lg-6">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Twitter
								</label>
								<Input
									name="twitter"
									type="text"
									onChange={
										validationForThirdStep.handleChange
									}
									onBlur={validationForThirdStep.handleBlur}
									value={
										validationForThirdStep.values.twitter ||
										""
									}
									invalid={
										validationForThirdStep.touched
											.twitter &&
											validationForThirdStep.errors.twitter
											? true
											: false
									}
								/>
								{validationForThirdStep.touched.twitter &&
									validationForThirdStep.errors.twitter ? (
									<FormFeedback type="invalid">
										{validationForThirdStep.errors.twitter}
									</FormFeedback>
								) : null}
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-6">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Github
								</label>
								<Input
									name="github"
									type="text"
									onChange={
										validationForThirdStep.handleChange
									}
									onBlur={validationForThirdStep.handleBlur}
									value={
										validationForThirdStep.values.github ||
										""
									}
									invalid={
										validationForThirdStep.touched.github &&
											validationForThirdStep.errors.github
											? true
											: false
									}
								/>
								{validationForThirdStep.touched.github &&
									validationForThirdStep.errors.github ? (
									<FormFeedback type="invalid">
										{validationForThirdStep.errors.github}
									</FormFeedback>
								) : null}
							</div>
						</div>

						<div className="col-lg-6">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Telegram
								</label>
								<Input
									name="telegram"
									type="text"
									onChange={
										validationForThirdStep.handleChange
									}
									onBlur={validationForThirdStep.handleBlur}
									value={
										validationForThirdStep.values
											.telegram || ""
									}
									invalid={
										validationForThirdStep.touched
											.telegram &&
											validationForThirdStep.errors.telegram
											? true
											: false
									}
								/>
								{validationForThirdStep.touched.telegram &&
									validationForThirdStep.errors.telegram ? (
									<FormFeedback type="invalid">
										{validationForThirdStep.errors.telegram}
									</FormFeedback>
								) : null}
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-lg-6">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Instagram
								</label>
								<Input
									name="instagram"
									type="text"
									onChange={
										validationForThirdStep.handleChange
									}
									onBlur={validationForThirdStep.handleBlur}
									value={
										validationForThirdStep.values
											.instagram || ""
									}
									invalid={
										validationForThirdStep.touched
											.instagram &&
											validationForThirdStep.errors.instagram
											? true
											: false
									}
								/>
								{validationForThirdStep.touched.instagram &&
									validationForThirdStep.errors.instagram ? (
									<FormFeedback type="invalid">
										{
											validationForThirdStep.errors
												.instagram
										}
									</FormFeedback>
								) : null}
							</div>
						</div>

						<div className="col-lg-6">
							<div className="mb-3">
								<label
									htmlFor="basicpill-firstname-input"
									className="form-label"
								>
									Reddit
								</label>
								<Input
									name="reddit"
									type="text"
									onChange={
										validationForThirdStep.handleChange
									}
									onBlur={validationForThirdStep.handleBlur}
									value={
										validationForThirdStep.values.reddit ||
										""
									}
									invalid={
										validationForThirdStep.touched.reddit &&
											validationForThirdStep.errors.reddit
											? true
											: false
									}
								/>
								{validationForThirdStep.touched.reddit &&
									validationForThirdStep.errors.reddit ? (
									<FormFeedback type="invalid">
										{validationForThirdStep.errors.reddit}
									</FormFeedback>
								) : null}
							</div>
						</div>
					</div>
		</Form>
		</React.Fragment >
	);
};

export default AdditionalInfoStepThird;
