import React, { useState, useEffect } from "react";
import moment from "moment";

export const LiveDateTime = () => {
    const [date, setDate] = useState(moment.utc());

    useEffect(() => {
        // Update the date state every second to the current UTC time
        const timer = setInterval(() => setDate(moment.utc()), 1000);
        
        // Cleanup the timer when the component is unmounted
        return function cleanup() {
            clearInterval(timer);
        };
    }, []);

    return <>{`${date.format("YYYY-MM-DD HH:mm:ss")} UTC`}</>;
};

export default LiveDateTime;



// import React, { useState, useEffect } from "react";
// import moment from "moment";

// export const LiveDateTime = () => {
//     const [date, setDate] = useState(moment.utc());
    
//     useEffect(() => {
//         var timer = setInterval(() => setDate(moment.utc()), 1000);
//         return function cleanup() {
//             clearInterval(timer);
//         };
//     }, []);

//     // Calculate the GST time
//     const gstDate = date.clone().utcOffset(4);

//     return <>{`${gstDate.format("YYYY-MM-DD HH:mm:ss")} GST`}</>;
// };

// export default LiveDateTime;