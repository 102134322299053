import React, {	useEffect,useState,useRef,useMemo} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	Spinner,
} from "reactstrap";
import { useDispatch } from "react-redux";
import ReactDataTable from "../../common/ReactDataTable";
import axios from "axios"
import PublicSaleABI from "../../ABI/publicsaleFactory.json"
import { apiUrl } from "../../config";
import {
	getNetworkInfo,
} from "../../store/launchpad/actions";
import Swal from "sweetalert2";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import "../../assets/scss/launchpad.scss";

import Web3 from 'web3';

const Launchpad = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const getLoaderStatus = useRef(null);
	const [isTableVisible, setIsTableVisible] = useState(false);
	const [selectedProjectId, setSelectedProjectId] = useState(null);
	const [selectedProject, setSelectedProject] = useState(null);
	const [registeredUsers, setRegisteredUsers] = useState(0);
	// const [isLoadingUSDT, setIsLoadingUSDT] = useState(0);
	const [isLoading, setIsLoading] = useState(0);
	const [allocationMap, setAllocationMap] = useState(new Map());
	const [userMap, setUserMap] = useState(new Map());
	const [tokenDecimal,setTokenDecimal] = useState(0)
	const [decimal,setDecimal] = useState(0)
	const [totaldeposit,setTotaldeposit] = useState(0)
	const [rateInUSDT,setRateInUSDT] = useState(0)
	// const [startDate,setStartDate] = useState(0)
	// const [endDate,setEndDate] = useState(0)
	// const [tokenName,setTokenName] = useState('')
	// const [liquidity,setLiquidity] = useState(0)
	const [projectStatus,setStatus] = useState('')

	const handleToggleTableVisibility = async(project) => {
		await getDeposit(project.id)
		if(project.status == 0) setStatus("Upcoming")
		else if(project.status == 1) setStatus("Ongoing")
		else if(project.status == 2) setStatus("Closed")
		else setStatus("Draft")
		
		// setLiquidity(project.liquidity)
		// setTotalRaisedUSDT(0)
		setSelectedProjectId(project.id);
		setSelectedProject(project.projectTitle)
		setIsTableVisible(!isTableVisible);
	};
	
	useEffect(() => {
		dispatch(getNetworkInfo());
	}, []);
	
	const [total, setTotal] = useState(100);
	// const [totalRaisedUSDT, setTotalRaisedUSDT] = useState(0);

	useEffect(() => {
		const fetchAccount = async () => {
				try {
				const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
				let acc = await sessionStorage.getItem('metamaskAddress', accounts[0]);
				setAccount(acc)
			} catch (error) {
			}
		};
		fetchAccount();
	}, [dispatch]);

	const [projects, setProjects] = useState([]);

	useEffect(() => {
		const fetchProjects = async () => {
			try {
				let token = localStorage.getItem('jwtToken')
				const response = await axios.get(`${apiUrl}/projects/all?limit=1`, {
					headers: {
					'Authorization': `Bearer ${token}`, // Include your JWT token here
					},
				});
				setTotal(response.data.total)
				const totalProjects = await axios.get(`${apiUrl}/projects/all?limit=${response.data.total}`);
				setProjects(totalProjects.data.projects); 
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchProjects();
	}, [dispatch]);

	const initializeWeb3 = (chainId) => {
		if (window.ethereum) {
			if (chainId === '0x38' || chainId === 56) {
				return getWeb3Instance();
			}
			else{
				return new Web3(window.ethereum);
			}
		}else{
			console.error('MetaMask is not installed');
			return null;
		}
	}

	const checkNetwork = async (_chainId, rpc, networkName) => {
		if (window.ethereum) {
			const chainId = await window.ethereum.request({ method: 'eth_chainId' });
			if (chainId !== _chainId ) {
				try {
					await window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: _chainId}], 
					});
					return true
				} catch (error) {
					if (error.code === 4902) {
						try {
							// console.log(rpc)
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainId: _chainId,
										chainName: networkName,
										rpcUrls: [rpc],
									},
								],
							});
							return true
							// window.location.reload()
						} catch (addError) {
							console.error('Failed to add the Fantom Testnet', addError);
							return false
						}
					}
					console.error('Failed to switch to the Fantom Testnet', error);
					return false
				}
			}else return true
		} else {
			console.log('Ethereum object not found, install MetaMask.');
			return false
		}
	}

	const handlePageChange = async (newPage) => {
		await setPage(newPage+1); 
		await refreshTableData.current();
	};
  
	const handleRowsPerPageChange = async (newRowsPerPage) => {
		await setPage(1)
	  	await setRowsPerPage(newRowsPerPage);
	  	await refreshTableData.current();
	};

	const columns = () => [
		{
			label: "Id",
			name: "id",
			options: {
				filter: false,
				sort: true,
				download: true,
				customBodyRender: (item) => {
						return (
						<div className="text-center">
							<div className=" font-size-14">
								{item}
							</div>
						</div>
					);
				},
			},
		},
		{
			label: "Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => {
					return (

						<div className="text-left">
							<div className="font-size-14">
								{item}
							</div>
						</div>
					);
				},
			},
		},
		{
			label: "Network",
			name: "networkName",
			options: {
				filter: true,
				customFilterListOptions: { render: v => `Network: ${v}` },
				sort: false,
				download: true,
				customBodyRender: (item) => {
					return (
						<div className="text-center">
							<div className="font-size-14">
								{item}
							</div>
						</div>
					);
				},
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: true,
				customFilterListOptions: { render: v => `Status: ${v}` },
				sort: false,
				download: true,
				customBodyRender: (item) => {
					return (
						<div className="text-center">
							<div className="font-size-14">
								{item}
							</div>
						</div>
					)
				},
			},
		},
		{
            label: "Details",
            name: "action",
            options: {
                filter: false,
                sort: false,
                empty: true,
                download: false,
                customBodyRender: (data) => {
                    return (
                        <div className="d-flex flex-wrap gap-3 justify-content-center">
                            {data.isDeployed === true && (<button
                                type="button"
                                onClick={() => handleToggleTableVisibility(data)}
                                className="btn btn-sm btn-primary waves-effect waves-light"
                            >
							<i className="fas fa-file align-middle font-size-16 me-1"></i>{" "}
                                View Report
                            </button>)}
                        </div>
                    );
                },
            },
        },
	];

	const resultFormatter = (result) => {
		return result.projects.map((item) => {
			let status1 = "Draft"; 
			if (item.isDeployed) {
				let state = item.status;
				if (state === 0) status1 = "Upcoming";
				else if (state === 1) status1 = "Ongoing";
				else if (state === 2) status1 = "Closed";
				else status1 = "Draft";
			}
			return {
				...item,
				id: item.id,
				active: item,
				action: item,
				status: item.isDeployed ? status1 : "Draft",
				name: item.projectTitle,
				networkName: item.network?.name
			};
		});
	};

	const columns1 = () => [
		{
			label: "BID",
			name: "userBID",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => (
					<div className="text-center">
						<div className="font-size-14">
							{item}
						</div>
					</div>
				),
			},
		},
		{
			label: "Wallet Address",
			name: "walletAddress",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => (
					<div className="text-center">
						<div className="font-size-14">
							{item}
						</div>
					</div>
				),
			},
		},
		{
			label: "Registration Time",
			name: "registrationDate",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => (
					<div className="text-left">
						<div className="font-size-14">
							{ new Date(item).toUTCString()}
						</div>
					</div>
				),
			},
		},
		{
			label: "Token Allocation",
			name: "allocation",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => (
					<div className="text-center">
						<div className="font-size-14">
							{item}
						</div>
					</div>
				),
			},
		},
		{
			label: "Allocated USDT",
			name: "allocationUSDT",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => (
					<div className="text-center">
						<div className="font-size-14">
							{item}
						</div>
					</div>
				),
			},
		},
		{
			label: "Deposited USDT",
			name: "deposit",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => (
					<div className="text-center">
						<div className="font-size-14">
							{item}
						</div>
					</div>
				),
			},
		},
	];
	
	const resultFormatter1 = (result) => {
		setRegisteredUsers(result.count)
		try{
			// setIsLoadingUSDT(true)
			// const publicSaleABI = PublicSaleABI
			const project = projects?.find(
			(project) => project.id === selectedProjectId
			);
			let chain = checkNetwork(project.network.chainId,project.network.rpc,project.network.name)
			// .then((onChain) => {
			// 	if (onChain){
			// 		const web3 = initializeWeb3(project.network.chainId);
			// 		const contract = new web3.eth.Contract(publicSaleABI, project.launchpadContractAddress);
			// 		let totalRaised =  contract.methods.viewTotalRaisedUsdt().call().then( (result) => {
			// 		result = parseInt(result,10)
			// 		const formattedResult = result / (10 ** (project.network.currencies[0].decimal));
			// 		setTotalRaisedUSDT(formattedResult.toFixed(2))
			// 		setIsLoadingUSDT(false);
			// 	})
			// 	}else setIsLoadingUSDT(false);
			// })
		}catch(e){
			//  setIsLoadingUSDT(false)
			}
        return result.allUsers.map((item) => ({
            userBID: item.userBID,
            registrationDate: item.registrationDate,
            walletAddress: item.walletAddress,
			allocation: (allocationMap.get(item.walletAddress) ? (allocationMap.get(item.walletAddress) / (10 **  tokenDecimal)).toFixed(2) : 0),
			allocationUSDT: (allocationMap.get(item.walletAddress) ? ((allocationMap.get(item.walletAddress) / (10 **  tokenDecimal)) * rateInUSDT).toFixed(2) : 0),
			deposit: (userMap.get(item.walletAddress) ? (userMap.get(item.walletAddress) / (10 ** decimal)).toFixed(2)  : 0),
        }));
    };

	const rpcUrls = [
		'https://public.stackup.sh/api/v1/node/bsc-mainnet',
		'https://bsc-dataseed.binance.org/',
		'https://bsc-dataseed1.binance.org/',
		'https://bsc-dataseed2.binance.org/',
		'https://bsc-dataseed3.binance.org/',
		'https://bsc-dataseed4.binance.org/',
		'https://binance.llamarpc.co',			
		'https://bsc.rpc.blxrbdn.com',	
		'https://1rpc.io/bnb',		
		'https://api.zan.top/node/v1/bsc/mainnet/public',		
		// 'wss://bsc-rpc.publicnode.com',		
		'https://bsc-mainnet.nodereal.io/v1/64a9df0874fb4a93b9d0a3849de012d3',
		'https://bsc-pokt.nodies.app',
		'https://bsc.blockpi.network/v1/rpc/public',
		'https://rpc.ankr.com/bsc',
		'https://bsc.drpc.org',
		'https://bsc-rpc.publicnode.com'
	];

	let currentRpcIndex = 0; 

	function getWeb3Instance() {
		const web3 = new Web3(new Web3.providers.HttpProvider(rpcUrls[currentRpcIndex]));
		currentRpcIndex = (currentRpcIndex + 1) % rpcUrls.length;
		return web3;
	}
	
	const getDeposit = async(id) => {
		if (!id) return
		setIsLoading(true)
		const project = projects?.find(
			(project) => project.id === id
		);
		setTokenDecimal(project?.tokenDecimal)
		setRateInUSDT(project?.rateInUSDT)
		// setStartDate(project?.startDate)
		// setEndDate(project?.endDate)
		// setTokenName(project?.tokenSymbol)
		await setDecimal(project?.network?.currencies[0]?.decimal)
		let onChain = await checkNetwork(project.network.chainId,project.network.rpc,project.network.name)
		if(onChain){
			try {
				const hash = await axios.get(`${apiUrl}/proofs/project/${id}`)
	
				if (hash.data.message){
					setIsLoading(false)
					return
				}
				const allocationMap1 = new Map();
	
				for (const entry of hash.data.mappedData) {
					const [user, allocation] = entry.split('~');
					allocationMap1.set(user, Number(allocation));
				}
				await setAllocationMap(allocationMap1)
	
				const publicSaleABI = PublicSaleABI;
				const web3 = initializeWeb3(project.network.chainId)
				if (!web3) {
					Swal.fire('Error', 'Web3 initialization failed.', 'error');
					return;
				}
				const contractPublicSale = new web3.eth.Contract(publicSaleABI, project.launchpadContractAddress);
			
				try {
					const startDate = Math.floor(new Date(project.startDate).getTime() / 1000);
					const projectEndDate = Math.floor(new Date(project.endDate).getTime() / 1000);
					const currentDate = Math.floor(new Date().getTime() / 1000);
					const endDate = projectEndDate > currentDate ? currentDate : projectEndDate;
					const { startDateTimestamp, endDateTimestamp } = await getBlockNumbers(project.network.chainId,startDate, endDate);
			 
					const initialBatchSize = 2000; 
					const events = await getEventsInBatches(project.network.chainId, contractPublicSale, 'UsdtDeposited', Number(startDateTimestamp), Number(endDateTimestamp), initialBatchSize);

					const userMap1 = new Map();
					for (const event of events) {
						const usdtAmount = Number(event.returnValues.usdtAmount);
						const user = event.returnValues.user;
						if (userMap1.has(user)) {
							userMap1.set(user, userMap1.get(user) + Number(usdtAmount));
						} else {
							userMap1.set(user, Number(usdtAmount));
						}
					}
					await setUserMap(userMap1)
					const totalSum = await sumOfMapValues(userMap1);
					await setTotaldeposit((totalSum / (10 ** project?.network?.currencies[0]?.decimal)).toFixed(2))

				} catch (error) {
					// console.log("errr", error)
					Swal.fire('Error', 'Error fetching deposit info!', 'error');
				}

			}catch(e){
				console.log("error",e)
				setIsLoading(false)
		}
			setIsLoading(false)
		} else setIsLoading(false)
	}

	function sumOfMapValues(map) {
		let sum = 0;
		for (let value of map.values()) {
			// value = value / 10 ** decimal
			sum += value;
		}
		return sum;
	}

	async function getEventsInBatches(chain, contract, eventName, startBlock, endBlock, batchSize) {
		let events = [];
		let currentStartBlock = startBlock;
		let currentEndBlock = Math.min(currentStartBlock + batchSize - 1, endBlock);
		let attempts = 0;
		const maxAttempts = 15;
		const minBatchSize = 1;
	
		while (currentStartBlock <= endBlock) {
			try {
				const batchEvents = await contract.getPastEvents(eventName, {
					fromBlock: Number(currentStartBlock),
					toBlock: Number(currentEndBlock),
				});
				// if(batchEvents.length > 0) {
				// 	console.log(`Fetched ${batchEvents.length} events from block ${currentStartBlock} to ${currentEndBlock}`);
				// 	console.log("provider", rpcUrls[currentRpcIndex])
				// }
				events = events.concat(batchEvents);
				currentStartBlock = currentEndBlock + 1;
				currentEndBlock = Math.min(currentStartBlock + batchSize - 1, endBlock);
				attempts = 0; 
			} catch (error) {
				console.error(`Error fetching events from block ${currentStartBlock} to ${currentEndBlock}:`, error);
				// if (error.message.includes('limit exceeded')) {
				// 	batchSize = Math.max(Math.floor(batchSize / 2), minBatchSize); // Ensure batchSize never goes below minBatchSize
				// 	console.log(`Reducing batch size to ${batchSize}`);
				// 	currentEndBlock = Math.min(currentStartBlock + batchSize - 1, endBlock);
				// 	// Implement exponential backoff
				// 	await new Promise(resolve => setTimeout(resolve, 2000 * attempts)); // Exponential backoff
				// 	currentRpcIndex = (currentRpcIndex + 1) % rpcUrls.length; // Switch to the next RPC URL
				// 	contract.setProvider(new Web3.providers.HttpProvider(rpcUrls[currentRpcIndex]));
				// } else {
				if(chain === '0x38' || chain === 56){
					attempts += 1;
					if (attempts >= maxAttempts) {
						throw new Error(`Failed to fetch events after ${maxAttempts} attempts`);
					}
					console.log(`Retrying... Attempt ${attempts}`);
					await new Promise(resolve => setTimeout(resolve, 2000 * attempts)); 
					currentRpcIndex = (currentRpcIndex + 1) % rpcUrls.length; 
					contract.setProvider(new Web3.providers.HttpProvider(rpcUrls[currentRpcIndex]));
				}	
				// }
			}
		}
		return events;
	}

	async function getBlockNumbers(chain,startDate, endDate) {
		try {
			const startDateTimestamp = await getBlockNumberByTimestamp(chain,startDate);
			const endDateTimestamp = await getBlockNumberByTimestamp(chain, endDate);
			return {startDateTimestamp,endDateTimestamp}
		} catch (error) {
			console.error("Error fetching block numbers:", error);
		}
	}

	async function getBlockNumberByTimestamp(chain,timestamp) {
		const web3 = initializeWeb3(chain);
		if (!web3) {
			Swal.fire('Error', 'Web3 initialization failed.', 'error');
			return;
		}

		timestamp = BigInt(timestamp);
	
		let startBlock = 0n; 
		let endBlock = BigInt(await web3.eth.getBlockNumber()); 
		let blockNumber = null;
	
		while (startBlock <= endBlock) {
			let middleBlock = (startBlock + endBlock) / 2n;
			let middleBlockInfo = await web3.eth.getBlock(Number(middleBlock)); 
	
			if (BigInt(middleBlockInfo.timestamp) < timestamp) {
				startBlock = middleBlock + 1n;
			} else if (BigInt(middleBlockInfo.timestamp) > timestamp) {
				endBlock = middleBlock - 1n;
			} else {
				blockNumber = middleBlock;
				break;
			}
		}
	
	   
		if (!blockNumber) {
			blockNumber = endBlock;
		}
	
		return blockNumber;
	}

	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Launchpad | Rampstarter</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="Admin"
						breadcrumbItem="Report"
					/>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
											
												<div className="table-responsive">
													<ReactDataTable
														title={'Projects'}		
														url={`${apiUrl}/projects/all?limit=${total}`}
														columns={columns()}
														resultFormatter={resultFormatter}
														setRefresh={refreshTableData}
														getLoaderStatus={getLoaderStatus}
														disableFilterIcon={false}
														disableSearchIcon={false}
														onChangePage={handlePageChange}
														onChangeRowsPerPage={handleRowsPerPageChange}
														total={total}
														sort={'des'}
														sortColumn={'id'}
														origin={
															<div className="row">
																<div className="col-auto h4">
																	Projects Report
																	&nbsp;
																</div>
															</div>
														}
													/>
												{isLoading ? <div className="loading-overlay">
													Fetching Data, Please Wait...
												<Spinner color="primary" />
												</div>:<Modal
														isOpen={isTableVisible}
														size="xl"
														centered={true}
														toggle={handleToggleTableVisibility}
													>
														<ModalHeader
														toggle={handleToggleTableVisibility}
														>
															{selectedProject ? selectedProject : ''} 
														</ModalHeader>
														<br></br>
														
														<ModalBody>
																<ReactDataTable
																	url={`${apiUrl}/projects/interested/${selectedProjectId}`}
																	columns={columns1()}
																	resultFormatter={resultFormatter1}
																	disableFilterIcon={true}
																	disableSearchIcon={true}
																	sort={'des'}
																	sortColumn={'deposit'}
																	origin={
																		<div className="row">
																			<ul className="list-group">
																				{/* <li className="list-group-item">
																					Project Name :
																						<span className="font-size-16  float-end">
																							<b>{selectedProject ? selectedProject : ''}</b>
																					</span>
																				</li>
																				<li className="list-group-item">
																					Project ID :
																						<span className="font-size-16  float-end">
																							<b>{selectedProjectId ? selectedProjectId : ''}</b>
																					</span>
																				</li>
																				<li className="list-group-item">
																					Token Symbol :
																						<span className="font-size-16  float-end">
																							<b>{tokenName}</b>
																					</span>
																				</li> */}
																				<li className="list-group-item">
																					Total Deposited USDT :
																					{/* {isLoading ? <Spinner color="primary" className="small-spinner"/> : */}
																						<span className="font-size-16 float-end">
																						<b>{totaldeposit}</b>
																					</span>
																				</li>
																				<li className="list-group-item">
																					Total Registered Users :
																					<span className="font-size-16 float-end">
																					<b>{registeredUsers}</b> 
																					</span>
																				</li>
																				{/* <li className="list-group-item">
																					Total Liquidity :
																					<span className="font-size-16 float-end">
																					<b>{liquidity}</b> 
																					</span>
																				</li> */}
																				{/* <li className="list-group-item">
																					Project Start Date :
																					<span className="font-size-16 float-end">
																					<b>{new Date(startDate).toUTCString()}</b> 
																					</span>
																				</li>
																				<li className="list-group-item">
																					Project End Date :
																					<span className="font-size-16 float-end">
																					<b>{new Date(endDate).toUTCString()}</b> 
																					</span>
																				</li> */}
																				<li className="list-group-item">
																					Status : 
																					<span className="font-size-16 float-end">
																					<b>{projectStatus}</b> 
																					</span>
																				</li>
																				{/* <li className="list-group-item">
																					Rate In USDT :
																					<span className="font-size-16 float-end">
																					<b>{rateInUSDT}</b> 
																					</span>
																				</li> */}
																			</ul>
																			{/* <b><br></br>Deposit Details: <br></br></b> */}
																			<h5>Deposit details : </h5>
																			<br></br>
																			</div>
																	}
																/>
														</ModalBody>
													</Modal>}
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		</React.Fragment >
	);
};

export default Launchpad;