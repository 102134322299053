import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
} from "reactstrap";
//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import Avatar from "react-avatar";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const ProfileMenu = (props) => {

	const [menu, setMenu] = useState(false);
	const history = useHistory();
	
	const handleLogoutClick = (e) => {
		e.preventDefault();

		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#3085d6',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, logout!',
		}).then((result) => {
			if (result.isConfirmed) {
				history.push('/logout');
			}
		});
	};

	return (
		<React.Fragment>
			<Dropdown
				isOpen={menu}
				toggle={() => setMenu(!menu)}
				className="d-inline-block"
			>
				<DropdownToggle
					className="btn header-item bg-soft-light border-start border-end"
					id="page-header-user-dropdown"
					tag="button"
				>
					<Avatar
						className="d-none d-xl-inline-block ms-2 me-1"
						color={"#74788d"}
						name={props.auth.user?.username}
						round={true}
						size={40}
					/>
					<span className="d-none d-xl-inline-block ms-2 me-1">
						{props.auth.user?.username}
					</span>
					<i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
				</DropdownToggle>
				<DropdownMenu className="dropdown-menu-end">
					<a href="/logout" onClick={handleLogoutClick} className="dropdown-item">
						<i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
						<span>{props.t("Logout")}</span>
					</a>
				</DropdownMenu>
			</Dropdown>
		</React.Fragment>
	);
};

ProfileMenu.propTypes = {
	auth: PropTypes.any,
	t: PropTypes.any,
};

const mapStatetoProps = (state) => ({
	auth: state.auth,
});

export default connect(mapStatetoProps, {})(withTranslation()(ProfileMenu));
