import React, {
	useEffect,
	useState,
	useMemo,
} from "react";
import { Card, CardBody, Col, Row, Input } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import axios from "axios"
import { apiUrl } from "../../config";
import FactoryABI from "../../ABI/facoryContract.json"
import Web3 from 'web3';
import Swal from "sweetalert2";

const LaunchpadPieChart = () => {
	const [status, setStatus] = useState({ live: 0, draft: 0 })
	const [totalRaised, setTotalRaised] = useState("0")
	const [networks, setNetwork] = useState([])

	useEffect(() => {
		const fetchProjects = async () => {
			try {
				let token = localStorage.getItem('jwtToken')
				const response = await axios.get(`${apiUrl}/projects/all?limit=1`, {
					headers: {
					'Authorization': `Bearer ${token}`, 
					},
				});
				let live = 0
				let draft = 0
				const totalProjects = await axios.get(`${apiUrl}/projects/all?limit=${response.data.total}`);
				totalProjects.data.projects.map((value, index) => {
					if (value.isDeployed) {
						live++
					} else {
						draft++
					}
				})
				setStatus({ live, draft })
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}
		fetchProjects()
	}, [])

	useEffect(() => {
		const fetchNetworks = async () => {
			try {
				const totalNetworks = await axios.get(`${apiUrl}/networks/all`);
				setNetwork(totalNetworks.data)
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		}
		fetchNetworks()
	}, [])

	const initializeWeb3 = () => {
		if (window.ethereum) {
			return new Web3(window.ethereum);
		} else {
			console.error('MetaMask is not installed');
			return null;
		}
	};

	const checkNetwork = async (_chainId, rpc, networkName) => {
		if (window.ethereum) {
			const chainId = await window.ethereum.request({ method: 'eth_chainId' });

			if (chainId !== _chainId) {
				try {
					await window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: _chainId }],
					});
				} catch (error) {
					if (error.code === 4902) {
						try {
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainId: _chainId,
										chainName: networkName,
										rpcUrls: [rpc],
									},
								],
							});
						} catch (addError) {
							console.error(`Failed to add the network: ${networkName}`, addError);
							Swal.fire({
								title: 'Error',
								text: `Failed to add the network: ${networkName}`,
								icon: 'error',
							});
						}
					} else {
						console.error(`Failed to switch to the network: ${networkName}`, error);
						Swal.fire({
							title: 'Error',
							text: `Failed to switch to the network: ${networkName}`,
							icon: 'error',
						});
					}
				}
			}
		} else {
			console.log('Ethereum object not found, install MetaMask.');
			Swal.fire({
				title: 'MetaMask Not Found',
				text: 'Ethereum object not found, install MetaMask.',
				icon: 'warning',
			});
		}
	}
	
	const getGraphOptions = useMemo(() => {
		return {
			chart: {
				width: 227,
				height: 227,
				type: "pie",
			},
			labels: ["Live", "Draft"],
			colors: ["#2ab57d","#ffbf53"],
			stroke: {
				width: 0,
			},
			legend: {
				show: false,
			},
			responsive: [
				{
					breakpoint: 480,
					options: {
						chart: {
							width: 200,
						},
					},
				},
			],
		};
	}, []);

	const getGraphDetails = useMemo(() => {
		let graphValues = [0, 0];
		if (status) {
			graphValues = ["live", "draft"].map(
				(item) => {
					return status[item]
						? parseFloat(
							(status[item] /
								(status.live + status.draft)) *
							100
						) ?? 0
						: 0;
				}
			);
		}
		return graphValues;
	}, [status]);

	const selectNetwork = async (e) => {
		const factoryABI = FactoryABI;
		const web3 = initializeWeb3();
		for (let i = 0; i < networks.length; i++) {
			if (networks[i].name === e.target.value) {
				await checkNetwork(networks[i].chainId, networks[i].rpc, networks[i].name)
				const contractFactory = new web3.eth.Contract(factoryABI, networks[i].factoryContractAddress);
				const deposit = await contractFactory.methods.totalCurrentUsdtAllProjects().call();
				// const decimal = await networks[i].currencies[0].decimal;
				const intValue = parseInt(deposit, 10) / (10 ** networks[i].currencies[0].decimal);
				setTotalRaised(intValue)
			}
		}
	}

	return (
		<React.Fragment>
			<Col xl={10}>
				<Card className="card-h-100">
					<CardBody>
						<div className="d-flex flex-wrap align-items-center mb-4">
							<h5 className="card-title me-2">Launchpad</h5>
						</div>
						<Row className="align-items-center">
							<div className="col-sm">
								<div
									id="wallet-balance"
									className="apex-charts"
								>
									<ReactApexChart
										options={getGraphOptions}
										series={getGraphDetails}
										type="pie"
										height="227"
									/>
								</div>
							</div>
							<div className="col-sm align-self-center">
								<ul className="list-group">
									<li className="list-group-item">
										<i className="mdi mdi-circle align-middle font-size-10 me-2 text-success"></i>{" "}
										Live{"    "}
										<span className="badge badge-soft-success font-size-16 font-weight-bold rounded-pill bg-soft-success float-end">
											{status.live
												?? 0}
										</span>
									</li>
									<li className="list-group-item">
										<i className="mdi mdi-circle align-middle font-size-10 me-2 text-warning"></i>{" "}
										Draft{"    "}
										<span className="badge badge-soft-warning font-size-16 rounded-pill bg-soft-warning float-end">
											{status
												?.draft ?? 0}
										</span>
									</li>
									<li className="d-flex align-items-center justify-content-between list-group-item">
                                        <div>
											<i className="mdi mdi-circle align-middle font-size-10 me-2 text-success"></i>{" "}
											Total Raised USDT
                                        </div>
                                        <div className="mt-4 mt-sm-0">
                                            <Input
                                                className="
                                                    border: 2px solid #007bff
                                                    border-radius: 2px
                                                    padding: 6px
                                                    font-size: 16px
                                                    color: #a8a29e
                                                    width: 100%
                                                    text-black
                                                    bg-[#a8a29e]
                                                    text-color: white
                                                    background-color: #a8a29e
                                                "
                                                name="network"
                                                type="select"
                                                onChange={selectNetwork}
                                                defaultValue=""
                                            >
                                                <option value="" disabled className="text-black">
                                                    Select Network
                                                </option>
                                                {networks.length &&
                                                    networks.map((network, index) => (
                                                        <option
                                                            key={`${index}network`}
                                                            value={network.name}
                                                        >
                                                            {network.name}
                                                        </option>
                                                    ))}
                                            </Input>
                                        </div>
                                        <span className="badge badge-soft-success font-size-16 rounded-pill bg-soft-success">
                                            {totalRaised}
                                        </span>
                                    </li>									
                                    <li className="list-group-item">
										<i className="mdi mdi-circle align-middle font-size-10 me-2 text-primary"></i>{" "}
											Total Projects
										<span className="badge badge-soft-primary font-size-16 rounded-pill bg-soft-primary float-end">
											{status
												?.draft + status?.live ?? 0}
										</span>
								    </li>
							    </ul>							
							</div>
						</Row>
					</CardBody>
				</Card>
			</Col>
		</React.Fragment>
	);
};

export default LaunchpadPieChart;
