import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import { Card, CardBody, Col, Container, Row, Input, Label, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, CardHeader, Form } from "reactstrap";
import Swal from "sweetalert2";
import { useSelector, useDispatch } from "react-redux";
import { deploy, getNetworkInfo } from "../../store/launchpad/actions";
import Web3 from 'web3';
import PublicSaleABI from "../../ABI/publicsaleFactory.json";
import FactoryContract from "../../ABI/facoryContract.json";
import ProxyContract from "../../ABI/ProxyContract.json"
import proxyBytecode from "../../ByteCode/ProxyBytecode";
import FactoryBytecode from "../../ByteCode/FactoryBytecode";
import { apiUrl } from "../../config";
import axios from "axios"

const ContractDeploy = (props) => {
    const dispatch = useDispatch();
    const { auth, launchpad } = useSelector((state) => ({
        auth: state.auth,
        launchpad: state.launchpad,
    }));

    const { isLoading, networkInfo } = launchpad;

    useEffect(() => {
        dispatch(getNetworkInfo());
    }, [dispatch]);

    const [selectedNetwork, setSelectedNetwork] = useState("");
    const [selectedNetworkAdmin, setSelectedNetworkAdmin] = useState("");
    const [selectedNetworkId, setSelectedNetworkId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [contractAddress, setContractAddress] = useState('');
    const [proxyAddress, setProxyAddress] = useState('');
    const [addresses, setAddresses] = useState(['']);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    const checkNetwork = async (_chainId, rpc, networkName) => {
        if (window.ethereum) {
            const chainId = await window.ethereum.request({ method: "eth_chainId" });

            if (chainId !== _chainId) {
                try {
                    await window.ethereum.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: _chainId }],
                    });
                } catch (error) {
                    if (error.code === 4902) {
                        try {
                            await window.ethereum.request({
                                method: 'wallet_addEthereumChain',
                                params: [
                                    {
                                        chainId: _chainId,
                                        chainName: networkName,
                                        rpcUrls: [rpc],
                                    },
                                ],
                            });
                        } catch (addError) {
                            console.error(`Failed to add the network: ${networkName}`, addError);
                            Swal.fire({
                                title: 'Error',
                                text: `Failed to add the network: ${networkName}`,
                                icon: 'error',
                            });
                        }
                    } else {
                        console.error(`Failed to switch to the network: ${networkName}`, error);
                        Swal.fire({
                            title: 'Error',
                            text: `Failed to switch to the network: ${networkName}`,
                            icon: 'error',
                        });
                    }
                }
            }
        } else {
            console.log('Ethereum object not found, install MetaMask.');
            Swal.fire({
                title: 'MetaMask Not Found',
                text: 'Ethereum object not found, install MetaMask.',
                icon: 'warning',
            });
        }
    }

    const getContractAbi = () => {
        try {
            return PublicSaleABI;
        } catch (error) {
            console.error('Error parsing contract ABI:', error);
            return null;
        }
    };

    const getProxyABI = () => {
        try {
            return ProxyContract;
        } catch (error) {
            console.error('Error parsing contract ABI:', error);
            return null;
        }
    };

    const initializeWeb3 = () => {
        if (window.ethereum) {
            return new Web3(window.ethereum);
        } else {
            console.error('MetaMask is not installed');
            return null;
        }
    };

    const selectNetwork = async (e) => {
        const selectedNetworkName = e.target.value;
        setSelectedNetwork(selectedNetworkName);

        const selectedNetworkInfo = networkInfo.find(
            (network) => network.name === selectedNetworkName
        );

        if (selectedNetworkInfo) {
            setSelectedNetworkId(selectedNetworkInfo.id);
            await checkNetwork(
                selectedNetworkInfo.chainId,
                selectedNetworkInfo.rpc,
                selectedNetworkInfo.name
            );
        }
    };

    const selectNetworkAdmin = async (e) => {
        const selectedNetworkName = e.target.value;
        setSelectedNetworkAdmin(selectedNetworkName)

        const selectedNetworkInfo = networkInfo.find(
            (network) => network.name === selectedNetworkName
        );

        if (selectedNetworkInfo) {
            await checkNetwork(
                selectedNetworkInfo.chainId,
                selectedNetworkInfo.rpc,
                selectedNetworkInfo.name
            );
        }
    };

    const deployContract = async (e) => {
        e.preventDefault()
        const web3 = initializeWeb3();
        if (!web3) return;

        const abi = getContractAbi();
        if (!abi) return;
        setLoading(true);
        setError('');
        try {
            const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
            const deployerAccount = sessionStorage.getItem('metamaskAddress', accounts[0]); 
           
            const contract = new web3.eth.Contract(abi);
            const newContractInstance = await contract.deploy({
                data: FactoryBytecode,
            }).send({
                from: deployerAccount,
            });
            setContractAddress(newContractInstance.options.address);
            const proxyAbi = getProxyABI()
            const proxyContract = new web3.eth.Contract(proxyAbi);
            const deployedProxyContract = await proxyContract.deploy({
                data: proxyBytecode,
                arguments: [newContractInstance.options.address, '0x8129fc1c']
            }).send({
                from: deployerAccount,
            });

            // console.log("Proxy Contract deployed at: proxyInstance ", deployedProxyContract);
            setProxyAddress(deployedProxyContract.options.address);
            Swal.fire({
                title: 'Success!',
                text: `Implementation Contract deployed at ${newContractInstance.options.address} \t \n and Proxy Contract deployed at: ${deployedProxyContract.options.address}`,
                icon: 'success'
            });
            let data = {
                factoryContractAddress: deployedProxyContract.options.address
            }
            await axios.patch(`${apiUrl}/networks/${selectedNetworkId}`, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            })
        } catch (err) {
            console.error('Contract deployment failed:', err);
            setError(err.message);
            Swal.fire({
                title: 'Error!',
                text: `Contract deployment failed: ${err.message}`,
                icon: 'error'
            });
        } finally {
            setLoading(false);
            setSelectedNetwork("")
            setSelectedNetworkId(null)
        }
    };

    const addAdmin = async(e) => {
        let flag = false;
        if(e.target.innerText === "Add Admin") flag = true
        const web3 = initializeWeb3();
        if (!web3) return;

        const abi = getContractAbi();
        if (!abi) return;

        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
		if (!fromAddress) {
			Swal.fire('Error', 'Please connect your wallet', 'error');
			return;
		}
        try {
            if (selectedNetworkAdmin){
                const factoryContract = FactoryContract;
                const selectedNetworkInfo = networkInfo.find(
                    (network) => network.name === selectedNetworkAdmin
                );
                let contractAddress = selectedNetworkInfo?.factoryContractAddress
                const contractFactory = new web3.eth.Contract(factoryContract, contractAddress);
                const isOwner = await contractFactory.methods.owner().call();
                let owner = isOwner?.toString()?.toLowerCase()
                let connectedWallet = fromAddress?.toString()?.toLowerCase()
                if ( addresses[0]){
                    if (owner === connectedWallet && flag){
                        console.log("flag ",flag)
                        const updateAdmin = await contractFactory.methods
                        .addNRemoveAdmin(addresses, flag)
                            .send({ from: fromAddress });
                            Swal.fire('Success', `Address ${connectedWallet} has been added as an Admin!`, 'success');
                            return 
                    }
                    else if (owner === connectedWallet && !flag){
                        console.log("flag ",flag)
                        const updateAdmin = await contractFactory.methods
                        .addNRemoveAdmin(addresses, flag)
                            .send({ from: fromAddress });
                        Swal.fire('Success', `Address ${connectedWallet} has been remove as an Admin!`, 'success');
                        return

                    }
                    else Swal.fire('Error', 'The connected address is not the owner of the contract.', 'error');
                    return
                }
                else Swal.fire('Error','Please enter an address','error')
            }
            else  Swal.fire('Error','Please choose a network','error')
		} catch (error) {
			Swal.fire('Transaction Error', 'Error in setting admin: ' + error.message, 'error');
		}finally {
            setLoading(false);
            setSelectedNetworkAdmin("")
            setAddresses([''])
        }
    }

    const handleAddressChange = (index, event) => {
        const newAddresses = [...addresses];
        newAddresses[index] = event.target.value;
        setAddresses(newAddresses);
    };
    
    const addAddressField = () => {
        setAddresses([...addresses, '']);
    };
    
    const removeAddressField = (index) => {
        if(index > 0){
            const newAddresses = addresses.filter((_, i) => i !== index);
            setAddresses(newAddresses);
        }
    };


    const handleDeployClick = async(e) => {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const deployerAccount = sessionStorage.getItem('metamaskAddress', accounts[0]);
        if (selectedNetworkId === null || !deployerAccount){
            toggleDeploy()
            return
        } 
        e.preventDefault();
        toggleModal();
    };

    const handleConfirmDeploy = (e) => {
        toggleModal();
        deployContract(e);
    };

    const toggleDeploy = async () => {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const deployerAccount = sessionStorage.getItem('metamaskAddress', accounts[0]); 
        if (!deployerAccount) {
            Swal.fire('Error', 'Please connect your wallet', 'error');
            return;
        }
        else Swal.fire('Error','Please Select a network','error')
    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Helmet>
                    <title>Deploy Smart Contract | Rampstarter</title>
                </Helmet>
                <Container fluid>
                    <Breadcrumbs title="Admin" breadcrumbItem="Contract Deployement" />
                        <Row>
                            <Col md="8">
                                <Card>
                                    <CardBody>
                                        <div className="">
                                            <Input
                                                className="mb-2"
                                                name="network"
                                                type="select"
                                                onChange={selectNetwork}
                                                value={selectedNetwork}
                                            >
                                                <option value="" disabled className="text-black">
                                                    Select Network
                                                </option>
                                                {networkInfo.length &&
                                                    networkInfo.map((network, index) => (
                                                        <option
                                                            key={`${index}network`}
                                                            value={network.name}
                                                        >
                                                            {network.name}
                                                        </option>
                                                    ))}
                                            </Input>
                                            <Button className="mb-3" color="primary" onClick={handleDeployClick} disabled={loading}>
                                                {loading && selectedNetwork ? <Spinner size="sm" /> : 'Deploy Contract'}
                                            </Button>
                                            <Modal isOpen={isModalOpen} toggle={toggleModal}>
                                                <ModalHeader toggle={toggleModal}>Confirmation</ModalHeader>
                                                <ModalBody>
                                                    This action will replace the existing smart contract address of the selected network. Are you sure you would like to proceed?
                                                </ModalBody>
                                                <ModalFooter>
                                                    <Button color="primary" onClick={handleConfirmDeploy}>Confirm</Button>
                                                    <Button color="secondary" onClick={toggleModal}>Cancel</Button>
                                                </ModalFooter>
                                            </Modal>
                            
                                            {contractAddress && (
                                                <div>
                                                    <p>Implementation Contract deployed at: {contractAddress}</p>
                                                </div>
                                            )}
                                            {proxyAddress && (
                                                <div className="">
                                                    <p>Proxy Contract deployed at: {proxyAddress}</p>
                                                </div>
                                            )}
                                            {error && (
                                                <div className="">
                                                    <p style={{ color: 'red' }}>{error}</p>
                                                </div>
                                            )}
                                        </div>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <h5>Add / Remove Admin</h5>
                        <Row>
                            <Col md="8">
                                <Card>
                                    <CardBody>
                                    <div className="">
                                        
                                        <div>
                                        <Row>
                                        <Col>
                                            <Card>
                                                <CardBody>
                                        <span >
                                            <b>Network</b>
                                        </span>
                                        
                                        
                                        
                                        <Input
                                            className="mb-2"
                                            name="network"
                                            type="select"
                                            onChange={selectNetworkAdmin}
                                            value={selectedNetworkAdmin}
                                        >
                                            <option value="" disabled className="text-black">
                                                Select Network
                                            </option>
                                            {networkInfo.length &&
                                                networkInfo.map((network, index) => (
                                                    <option
                                                        key={`${index}network`}
                                                        value={network.name}
                                                    >
                                                        {network.name}
                                                    </option>
                                            ))}
                                        </Input>
                                        <br></br>
                                        <div>
                                           
                                            </div>
                                            <FormGroup>
                                                <Label htmlFor="addresses"><b>Addresses to be added / removed</b></Label>
                                                {addresses.map((address, index) => (
                                                    <div key={index} className="d-flex align-items-center mb-2">
                                                        <Input
                                                            type="text"
                                                            value={address}
                                                            onChange={(e) => handleAddressChange(index, e)}
                                                            style={{ minHeight: '38px', maxHeight: '300px' }}
                                                        />
                                                        <Button color="danger" onClick={() => removeAddressField(index)} className="ms-2">
                                                            -
                                                        </Button>
                                                       
                                                    </div>
                                                ))}
                                             <Button color="primary" onClick={addAddressField} className="ms-2">
                                                        + 
                                             </Button>
                                                
                                            </FormGroup>
                                            </CardBody>

                                                </Card>
                                                </Col>
                                                </Row>
                                            <Button className="mb-3" color="success" onClick={(e) => addAdmin(e, 'add')} disabled={loading}>
                                                Add Admin
                                            </Button> <span> </span>
                                            <Button className="mb-3" color="danger" onClick={(e) => addAdmin(e, 'delete')} disabled={loading}>
                                                Remove Admin
                                            </Button>
                                        </div>
                                    </div>
                                </CardBody>

                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ContractDeploy;