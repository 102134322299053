import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/user`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data) => {
	const { id, ...payload } = data; 
	if (payload.role === "") delete payload.role
	return axios
	  .patch(`${apiUrl}/user/${id}`, payload) 
	  .then((response) => response)
	  .catch((err) => err.response);
  };
  
export const remove = (id) =>
	axios
		.delete(`${apiUrl}/user/${id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const createPermission = (data) =>
	axios
		.post(`${apiUrl}/admin/role-permission/create`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const updatePermission = (data) =>
	axios
		.put(`${apiUrl}/admin/role-permission/update/${data._id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const togglePermission = (id) =>
	axios
		.patch(`${apiUrl}/admin/role-permission/toggle-status/${id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const removePermission = (id) =>
	axios
		.delete(`${apiUrl}/admin/role-permission/delete/${id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const createRole = (data) =>
	axios
		.post(`${apiUrl}/auth/roles`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const updateRole = (data) => {
	const { id,name, ...payload } = data; // Extract id from data
	return axios
	.patch(`${apiUrl}/auth/roles/${data.id}`, payload)
		.then((response) => response)
		.catch((err) => err.response);
	};

export const removeRole = (id) =>
	axios
		.delete(`${apiUrl}/auth/roles/${id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const toggleRole = (id) =>
	axios
		.patch(`${apiUrl}/admin/role/toggle-status/${id}`)
		.then((response) => response)
		.catch((err) => err.response);

export const getPermissions = () =>
	axios
		.get(`${apiUrl}/auth/roles`)
		.then((response) => response)
		.catch((err) => err.response);


export const getRoles = () =>
	axios.get(`${apiUrl}/auth/roles`)
		.then((response) => response);

export const createAirdrop = (data) =>
	axios
		.post(`${apiUrl}/proofs/airdrop`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const createWhitelist = (data) =>
	axios
		.post(`${apiUrl}/proofs/whitelist`, data, {
		headers: {
		'content-Type': 'multipart/form-data'
		}
		})
		.then((response) => response)
		.catch((err) => err.response);

export const updateProject = ({ id, data }) => {
	return axios
	  .patch(`${apiUrl}/projects/${id}`, data, {
		headers: {
		  'Content-Type': 'multipart/form-data'
		}
	  })
	  .then((response) => response)
	  .catch((err) => {
		throw err.response ? err.response.data : err;
	  });
  };