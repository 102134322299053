import React, { useState, useEffect } from "react";
import {
	Card, CardBody,
	Row,
	FormFeedback,
	FormText,
	Form,
	Label,
	Input,
	FormGroup,
	InputGroup,
	Alert,
} from "reactstrap";

import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import LiveDateTime from "./LiveDateTime";
import moment from "moment";

const StageDetailStepSecond = ({
	couldHaveAddUpdatePermission,
	validationForSecondStep,

}) => {

	const [registrationDateEnabled, setRegistrationDateEnabled] = useState(false);

	let startDate = validationForSecondStep.values.startDate;
	let depositEndDate = validationForSecondStep.values.depositEndDate;

	const [errors, setErrors] = useState('');

	let snapshotStart = validationForSecondStep.values.snapshotStartDate

	// validationForSecondStep.values.depositStartDate = snapshotStart
	validationForSecondStep.values.cliffingStartDate = depositEndDate
	let currentUTCDateString = moment.utc().format('YYYY-MM-DDTHH:mm')

	const { values, errors: formikErrors, touched } = validationForSecondStep


	// Function to handle the validation and formatting
	const handleRateChange = (e, handleChange) => {
		const { value } = e.target;
		const regex = /^\d+(\.\d{0,8})?$/; 
		if (value === '' || regex.test(value)) {
		handleChange(e); 
		}
	};

	useEffect(() => {
		if (startDate && depositEndDate) {
			setRegistrationDateEnabled(true);
		} else {
			setRegistrationDateEnabled(false);
		}
	}, [startDate, depositEndDate]);

	const getDateWithoutTime = (dateString) => {
		const date = new Date(dateString);
		date.setHours(0, 0, 0, 0); // Set time to midnight
		return date;
	};
	
	const minSnapshotstart = validationForSecondStep.values.registerationEndDate
    ? getDateWithoutTime(validationForSecondStep.values.registerationEndDate)
    : currentUTCDateString;

	const minSnapshotend = validationForSecondStep.values.snapshotStartDate
    ? getDateWithoutTime(validationForSecondStep.values.snapshotStartDate)
    : currentUTCDateString;

	return (
		<React.Fragment>
			<Form
				onSubmit={(e) => {
					e.preventDefault();
					validationForSecondStep.handleSubmit();
					return false;
				}}
			>
				<div>
					<div className="text-center mb-4">
						<h5>Launchpad Stages Info</h5>
						<p className="card-title-desc">
							Enter the launchpad stages information.
						</p>
					</div>
					<Row>
						<Card className="editStagesBody">
							<CardBody
								style={{
									padding: "0",
								}}
							>
								<Form
									onSubmit={(e) => {
										e.preventDefault();
										validationForSecondStep.handleSubmit();
										return false;
									}}
								>
									<fieldset disabled={!couldHaveAddUpdatePermission()}>
										<div className="row">
											<div className="row">

												<div className="col-lg-12">
													<div className="mb-3">
														<label
															htmlFor="basicpill-firstname-input"
															className="form-label"
														>

															Liquidity <span style={{ color: 'red' }}>*</span>
														</label>
														<Input
															name="liquidity"
															type="text"
															placeholder="Liquidity"
															onChange={(e) => {
																e.target.value =
																	e.target.value.match(
																		/^([0-9]{1,})?(\.)?([0-9]{1,})?$/
																	)
																		? e.target.value.match(
																			/([0-9]*[\.|\,]{0,1}[0-9]{0,2})/s
																		)[0]
																		: validationForSecondStep
																			.values
																			?.liquidity ||
																		"";
																validationForSecondStep.handleChange(
																	e
																);
															}}
															onBlur={
																validationForSecondStep.handleBlur
															}
															value={
																validationForSecondStep
																	.values
																	.liquidity || ""
															}
															invalid={
																validationForSecondStep
																	.touched
																	.liquidity &&
																	validationForSecondStep
																		.errors
																		.liquidity
																	? true
																	: false
															}
														/>
														{validationForSecondStep.touched
															.liquidity &&
															validationForSecondStep.errors
																.liquidity ? (
															<FormFeedback type="invalid">
																{
																	validationForSecondStep
																		.errors
																		.liquidity
																}
															</FormFeedback>
														) : null}
													</div>
												</div>

												<div className="col-lg-12">
													<div className="mb-3">
														<label className="control-label">
															Rate in USDT <span style={{ color: 'red' }}>*</span>
														</label>

														<Input
															name="rateInUSDT"
															type="text"
															placeholder="Rate in USDT"
															onChange={(e) => handleRateChange(e, validationForSecondStep.handleChange)}
															// onChange={
															// 	validationForSecondStep.handleChange
															// }
															onBlur={
																validationForSecondStep.handleBlur
															}
															value={
																validationForSecondStep.values
																	.rateInUSDT || ""
															}
															invalid={
																validationForSecondStep.touched
																	.rateInUSDT &&
																	validationForSecondStep.errors
																		.rateInUSDT
																	? true
																	: false
															}
														/>

														{validationForSecondStep.touched.rateInUSDT &&
															validationForSecondStep.errors.rateInUSDT ? (
															<FormFeedback type="invalid">
																{
																	validationForSecondStep
																		.errors.rateInUSDT
																}
															</FormFeedback>
														) : null}
													</div>
												</div>
											</div>

											{/* Rate in PPPP Ratio  */}

											<div className="col">
												<div className="col-lg-12">
													<div className="mb-3">
														<label
															htmlFor="basicpill-firstname-input"
															className="form-label"
														>
															PPPP Ratio % <span style={{ color: 'red' }}>*</span>
														</label>
														<div className="row">
															<div className="col-md-3 mb-2">
																<Input
																	name="pump"
																	type="text"
																	placeholder="Pump"
																	onChange={
																		validationForSecondStep.handleChange
																	}
																	onBlur={validationForSecondStep.handleBlur}
																	value={
																		validationForSecondStep.values
																			.pump 
																	}
																	invalid={touched.pump && formikErrors.pump ? true : false}
																/>
																{touched.pump && formikErrors.pump ? (
																	<FormFeedback type="invalid">
																		{formikErrors.pump}
																	</FormFeedback>
																) : null}
															</div>

															<div className="col-md-3 mb-2">
																<Input
																	name="power"
																	type="text"
																	placeholder="Power"
																	onChange={
																		validationForSecondStep.handleChange
																	}
																	onBlur={validationForSecondStep.handleBlur}
																	value={
																		validationForSecondStep.values
																			.power 
																	}
																	invalid={touched.power && formikErrors.power ? true : false}
																/>
																{touched.power && formikErrors.power ? (
																	<FormFeedback type="invalid">
																		{formikErrors.power}
																	</FormFeedback>
																) : null}
															</div>

															<div className="col-md-3 mb-2">
																<Input
																	name="persistance"
																	type="text"
																	placeholder="Persistance"
																	onChange={
																		validationForSecondStep.handleChange
																	}
																	onBlur={validationForSecondStep.handleBlur}
																	value={
																		validationForSecondStep.values
																			.persistance 
																	}
																	invalid={touched.persistance && formikErrors.persistance ? true : false}
																/>
																{touched.persistance && formikErrors.persistance ? (
																	<FormFeedback type="invalid">
																		{formikErrors.persistance}
																	</FormFeedback>
																) : null}
															</div>
															<div className="col-md-3 mb-2">
																<Input
																	name="probability"
																	type="text"
																	placeholder="Probability"
																	onChange={
																		validationForSecondStep.handleChange
																	}
																	onBlur={validationForSecondStep.handleBlur}
																	value={
																		validationForSecondStep.values
																			.probability 
																	}
																	invalid={touched.probability && formikErrors.probability ? true : false}
																/>
																{touched.probability && formikErrors.probability ? (
																	<FormFeedback type="invalid">
																		{formikErrors.probability}
																	</FormFeedback>
																) : null}
															</div>
														</div>
														{errors && (
															<Alert color="danger">
																{errors}
															</Alert>
														)}

													</div>
												</div>
											</div>
												


											<div className="row">
												<div className="text-center">
													<Alert
														className="alert-outline text-default"
														color="info"
													>
														Current UTC DATE TIME : {"  "}
														<LiveDateTime />
													</Alert>
												</div>
											</div>

											{validationForSecondStep.values.isExchangeList !==
												true && (
													<div className="row">

														<div className="col-lg-6">
															<FormGroup className="mb-3">
																<Label>Registration Start Time (UTC) <span style={{ color: 'red' }}>*</span></Label>
																<InputGroup>
																	<Flatpickr
																		className={`form-control d-block ${validationForSecondStep
																			.touched
																			.startDate &&
																			validationForSecondStep
																				.errors.startDate
																			? "is-invalid"
																			: ""
																			}`}
																		placeholder="Select End Data-Time"
																		options={{
																			altInput: true,
																			altFormat: "Y-m-d H:i",
																			dateFormat: "Y-m-d H:i",
																			enableTime: true,
																			time_24hr: true,
																			minDate: currentUTCDateString || validationForSecondStep.values.startDate
																		}}
																		name="startDate"
																		onChange={(date, dateInString) => {
																			validationForSecondStep.setFieldValue("startDate", dateInString);
																		}}
																		onBlur={
																			validationForSecondStep.handleBlur
																		}
																		value={
																			validationForSecondStep
																				.values
																				.startDate || ""
																		}
																	/>

																	{validationForSecondStep.touched.startDate && validationForSecondStep.errors.startDate ? (
																		<FormFeedback type="invalid">{validationForSecondStep.errors.startDate}</FormFeedback>
																	) : null}
																</InputGroup>
															</FormGroup>
														</div>

														<div className="col-lg-6">
															<FormGroup className="mb-3">
																<Label>Register Interest End Time (UTC) <span style={{ color: 'red' }}>*</span></Label>
																<InputGroup>
																	<Flatpickr
																		className={`form-control d-block
																			${validationForSecondStep
																				.touched
																				.registerationEndDate &&
																				validationForSecondStep
																					.errors
																					.registerationEndDate
																				? "is-invalid"

																				: ""
																			}`}
																		placeholder="Select Register Interest End Time"
																		options={{
																			altInput: true,
																			altFormat: "Y-m-d H:i",
																			dateFormat: "Y-m-d H:i",
																			enableTime: true,
																			time_24hr: true,
																			minDate: validationForSecondStep.values.startDate || currentUTCDateString
																		}}
																		onChange={(date, dateInString) => {
																			validationForSecondStep.setFieldValue("registerationEndDate", dateInString);
																		}}
																		onBlur={
																			validationForSecondStep.handleBlur
																		}
																		value={
																			validationForSecondStep
																				.values
																				.registerationEndDate || ""
																		}

																		name="registerationEndDate"
																	/>
																	{validationForSecondStep.touched.registerationEndDate && validationForSecondStep.errors.registerationEndDate ? (
																		<FormFeedback type="invalid">{validationForSecondStep.errors.registerationEndDate}</FormFeedback>
																	) : null}
																</InputGroup>
															</FormGroup>
														</div>

													</div>
												)}

											{/* Snapshots */}

											<div className="row">
												<div className="col-lg-6">
													<FormGroup className="mb-3">
														<Label>SnapShot Start Time  <span style={{ color: 'red' }}>*</span></Label>

														<InputGroup>
															<Flatpickr
																className={`form-control d-block ${validationForSecondStep
																	.touched
																	.snapshotStartDate &&
																	validationForSecondStep
																		.errors.snapshotStartDate
																	? "is-invalid"
																	: ""
																	}`}
																placeholder="Select SnapShot Start Date"
																options={{
																	altInput: true,
																	enableTime: false,
																	minDate: validationForSecondStep.values.startDate || currentUTCDateString
																	// minDate: minSnapshotstart
																}}
																name="snapshotStartDate"
																onChange={(date, dateInString) => {
																	validationForSecondStep.setFieldValue("snapshotStartDate", dateInString);
																}}
																onBlur={
																	validationForSecondStep.handleBlur
																}
																value={
																	validationForSecondStep
																		.values
																		.snapshotStartDate || ""
																}
															/>
															{validationForSecondStep.touched.snapshotStartDate && validationForSecondStep.errors.snapshotStartDate ? (
																<FormFeedback type="invalid">{validationForSecondStep.errors.snapshotStartDate}</FormFeedback>
															) : null}
														</InputGroup>
													</FormGroup>
												</div>

												<div className="col-lg-6">
													<FormGroup className="mb-3">
														<Label>SnapShot End Time <span style={{ color: 'red' }}>*</span></Label>

														<InputGroup>
															<Flatpickr
																className={`form-control d-block ${validationForSecondStep
																	.touched
																	.snapshotEndDate &&
																	validationForSecondStep
																		.errors.snapshotEndDate
																	? "is-invalid"
																	: ""
																	}`}
																placeholder="Select SnapShot End Date"
																options={{
																	altInput: true,
																	enableTime: false,
																	minDate: validationForSecondStep.values.registerationEndDate || currentUTCDateString
																	// minDate: minSnapshotend
																}}
																name="snapshotEndDate"
																onChange={(date, dateInString) => {
																	validationForSecondStep.setFieldValue("snapshotEndDate", dateInString);
																}}
																onBlur={
																	validationForSecondStep.handleBlur
																}
																value={
																	validationForSecondStep
																		.values
																		.snapshotEndDate || ""
																}
															/>
															{validationForSecondStep.touched.snapshotEndDate && validationForSecondStep.errors.snapshotEndDate ? (
																<FormFeedback type="invalid">{validationForSecondStep.errors.snapshotEndDate}</FormFeedback>
															) : null}
														</InputGroup>
													</FormGroup>
												</div>
											</div>

											{/* -----Register Interest End Date &  Deposit Start Date------- */}

											<div className="row">
												<div className="col-lg-6">
													<FormGroup className="mb-3">
														<Label>Deposit Start Time<span style={{ color: 'red' }}>*</span></Label>

														<InputGroup>
															<Flatpickr
																className={`form-control d-block ${validationForSecondStep
																	.touched
																	.depositStartDate &&
																	validationForSecondStep
																		.errors.depositStartDate
																	? "is-invalid"
																	: ""
																	}`}
																placeholder="Select Deposit Start Date-Time"
																options={{
																	altInput: true,
																	altFormat: "Y-m-d H:i",
																	dateFormat: "Y-m-d H:i",
																	enableTime: true,
																	time_24hr: true,
																	minDate: validationForSecondStep.values.startDate || currentUTCDateString
																	// minDate: validationForSecondStep.values.registerationEndDate || currentUTCDateString
																}}
																// disabled={true}
																name="depositStartDate"
																onChange={(date, dateInString) => {
																	validationForSecondStep.setFieldValue("depositStartDate", dateInString);
																}}
																onBlur={
																	validationForSecondStep.handleBlur
																}
																value={
																	validationForSecondStep
																		.values
																		.depositStartDate || ""
																}
															/>
															{validationForSecondStep.touched.depositStartDate && validationForSecondStep.errors.depositStartDate ? (
																<FormFeedback type="invalid">{validationForSecondStep.errors.depositStartDate}</FormFeedback>
															) : null}
														</InputGroup>
													</FormGroup>
												</div>

												<div className="col-lg-6">
													<FormGroup className="mb-3">
														<Label>Deposit End Time  <span style={{ color: 'red' }}>*</span></Label>

														<InputGroup>
															<Flatpickr
																className={`form-control d-block ${validationForSecondStep
																	.touched
																	.depositEndDate &&
																	validationForSecondStep
																		.errors.depositEndDate
																	? "is-invalid"
																	: ""
																	}`}
																placeholder="Select Deposit End Data-Time"
																options={{
																	altInput: true,
																	altFormat: "Y-m-d H:i",
																	dateFormat: "Y-m-d H:i",
																	enableTime: true,
																	time_24hr: true,
																	minDate: validationForSecondStep.values.depositStartDate || currentUTCDateString
																}}
																name="depositEndDate"
																onChange={(date, dateInString) => {
																	validationForSecondStep.setFieldValue("depositEndDate", dateInString);
																}}
																onBlur={
																	validationForSecondStep.handleBlur
																}
																value={
																	validationForSecondStep
																		.values
																		.depositEndDate || ""
																}
															/>
															{validationForSecondStep.touched.depositEndDate && validationForSecondStep.errors.depositEndDate ? (
																<FormFeedback type="invalid">{validationForSecondStep.errors.depositEndDate}</FormFeedback>
															) : null}
														</InputGroup>
													</FormGroup>
												</div>
											</div>


											{/* -----Cliffing Start Date cliffing End Date------- */}

											<div className="row">
												<div className="col-lg-6">
													<FormGroup className="mb-3">
														<Label>Cliffing Start Time <span style={{ color: 'red' }}>*</span></Label>
														<InputGroup>
															<Flatpickr
																className={`form-control d-block ${validationForSecondStep
																	.touched
																	.cliffingStartDate &&
																	validationForSecondStep
																		.errors
																		.cliffingStartDate
																	? "is-invalid"
																	: ""
																	}`}
																placeholder="Select Cliffing Start Time"
																options={{
																	altInput: true,
																	altFormat: "Y-m-d H:i",
																	dateFormat: "Y-m-d H:i",
																	enableTime: true,
																	time_24hr: true,
																	minDate: validationForSecondStep.values.depositEndDate || currentUTCDateString
																}}
																disabled={true}
																onChange={(date, dateInString) => {
																	validationForSecondStep.setFieldValue("cliffingStartDate", dateInString);
																}}
																onBlur={
																	validationForSecondStep.handleBlur
																}
																value={
																	validationForSecondStep
																		.values
																		.cliffingStartDate || ""
																}

																name="cliffingStartDate"
															/>
															{validationForSecondStep.touched.cliffingStartDate && validationForSecondStep.errors.cliffingStartDate ? (
																<FormFeedback type="invalid">{validationForSecondStep.errors.cliffingStartDate}</FormFeedback>
															) : null}
														</InputGroup>
													</FormGroup>
												</div>

												<div className="col-lg-6">
													<FormGroup className="mb-3">
														<Label>Cliffing End Time <span style={{ color: 'red' }}>*</span></Label>

														<InputGroup>
															<Flatpickr
																className={`form-control d-block ${validationForSecondStep
																	.touched
																	.cliffingEndDate &&
																	validationForSecondStep
																		.errors.cliffingEndDate
																	? "is-invalid"
																	: ""
																	}`}
																placeholder="Select Cliffing End Time"
																options={{
																	altInput: true,
																	altFormat: "Y-m-d H:i",
																	dateFormat: "Y-m-d H:i",
																	enableTime: true,
																	time_24hr: true,
																	minDate: validationForSecondStep.values.cliffingStartDate || currentUTCDateString
																}}
																name="cliffingEndDate"
																onChange={(date, dateInString) => {
																	validationForSecondStep.setFieldValue("cliffingEndDate", dateInString);
																}}
																onBlur={
																	validationForSecondStep.handleBlur
																}
																value={
																	validationForSecondStep
																		.values
																		.cliffingEndDate || ""
																}
															/>
															{validationForSecondStep.touched.cliffingEndDate && validationForSecondStep.errors.cliffingEndDate ? (
																<FormFeedback type="invalid">{validationForSecondStep.errors.cliffingEndDate}</FormFeedback>
															) : null}
														</InputGroup>
													</FormGroup>
												</div>
											</div>



											{/* Release Date-Time (UTC) SnapShot Date */}


											<div className="row">
												<div className="col-lg-6">
													<FormGroup className="mb-3">
														<Label>TG Time <span style={{ color: 'red' }}>*</span></Label>

														<InputGroup>
															<Flatpickr
																className={`form-control d-block ${validationForSecondStep
																	.touched
																	.tokenReleaseDate &&
																	validationForSecondStep
																		.errors
																		.tokenReleaseDate
																	? "is-invalid"
																	: ""
																	}`}
																placeholder="Select Token Release Date-Time"
																options={{
																	altInput: true,
																	altFormat: "Y-m-d H:i",
																	dateFormat: "Y-m-d H:i",
																	enableTime: true,
																	time_24hr: true,
																	minDate: validationForSecondStep.values.cliffingStartDate || currentUTCDateString,
																	maxDate: validationForSecondStep.values.cliffingEndDate || currentUTCDateString
																}}
																onChange={(date, dateInString) => {
																	validationForSecondStep.setFieldValue("tokenReleaseDate", dateInString);
																}}
																onBlur={
																	validationForSecondStep.handleBlur
																}
																value={
																	validationForSecondStep
																		.values
																		.tokenReleaseDate || ""
																}
																name="tokenReleaseDate"
															/>
															{validationForSecondStep.touched.tokenReleaseDate && validationForSecondStep.errors.tokenReleaseDate ? (
																<FormFeedback type="invalid">{validationForSecondStep.errors.tokenReleaseDate}</FormFeedback>
															) : null}

														</InputGroup>
													</FormGroup>
												</div>

												{/* SnapShot Date */}


											</div>

											{/* SnapShot */}



											<div className="row">
												<div className="col-lg-6">
													<div className="mb-3">
														<label
															htmlFor="basicpill-firstname-input"
															className="form-label"
														>
															Vesting Interval (days) <span style={{ color: 'red' }}>*</span>
														</label>

														<Input
															name="vestingFrequency"
															type="text"
															placeholder="Vesting Frequency"
															onChange={(e) => {
																validationForSecondStep.setFieldValue(
																	"liquidityLockup",
																	e.target
																		.value
																);
																validationForSecondStep.handleChange(
																	e
																);
															}}
															onBlur={
																validationForSecondStep.handleBlur
															}
															value={
																validationForSecondStep
																	.values
																	.vestingFrequency ||
																""
															}
															invalid={
																validationForSecondStep
																	.touched
																	.vestingFrequency &&
																	validationForSecondStep
																		.errors
																		.vestingFrequency
																	? true
																	: false
															}
														/>
														{validationForSecondStep
															.touched.vestingFrequency &&
															validationForSecondStep
																.errors.vestingFrequency ? (
															<FormFeedback type="invalid">
																{
																	validationForSecondStep
																		.errors
																		.vestingFrequency
																}
															</FormFeedback>
														) : null}
													</div>
												</div>
												<div className="col-lg-6">
													<div className="mb-3">
														<label
															htmlFor="basicpill-firstname-input"
															className="form-label"
														>
															Vesting Percentage <span style={{ color: 'red' }}>*</span>
														</label>

														<Input
															name="vestingPercentage"
															type="text"
															placeholder="Vesting Percentage %"
															onChange={
																validationForSecondStep.handleChange
															}
															onBlur={
																validationForSecondStep.handleBlur
															}
															value={
																validationForSecondStep
																	.values
																	.vestingPercentage ||
																""
															}
															invalid={
																validationForSecondStep
																	.touched
																	.vestingPercentage &&
																	validationForSecondStep
																		.errors
																		.vestingPercentage
																	? true
																	: false
															}
														/>
														{validationForSecondStep
															.touched.vestingPercentage &&
															validationForSecondStep
																.errors.vestingPercentage ? (
															<FormFeedback type="invalid">
																{
																	validationForSecondStep
																		.errors
																		.vestingPercentage
																}
															</FormFeedback>
														) : null}


													</div>
												</div>

												{/* Token Release Percentage */}
												<div className="col-lg-12">
													<div className="mb-3">
														<label
															htmlFor="basicpill-firstname-input"
															className="form-label"
														>
															TG Percentage (%) <span style={{ color: 'red' }}>*</span>
														</label>

														<Input
															name="tokenReleasePercentage"
															type="text"
															placeholder="Token Release Percentage"
															onChange={
																validationForSecondStep.handleChange
															}
															onBlur={
																validationForSecondStep.handleBlur
															}
															value={
																validationForSecondStep
																	.values
																	.tokenReleasePercentage ||
																""
															}
															invalid={
																validationForSecondStep
																	.touched
																	.tokenReleasePercentage &&
																	validationForSecondStep
																		.errors
																		.tokenReleasePercentage
																	? true
																	: false
															}
														/>
														{validationForSecondStep
															.touched.tokenReleasePercentage &&
															validationForSecondStep
																.errors.tokenReleasePercentage ? (
															<FormFeedback type="invalid">
																{
																	validationForSecondStep
																		.errors
																		.tokenReleasePercentage
																}
															</FormFeedback>
														) : null}


													</div>
												</div>

											</div>
										</div>
									</fieldset>
								</Form>
							</CardBody>
						</Card>
					</Row>
				</div>
			</Form>
		</React.Fragment>
	);
};

export default StageDetailStepSecond;
