import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import PermissionModal from "./PermissionModal";

const hasAnyPermission = (currentUserRolePermissions, permission, props) => {
	return currentUserRolePermissions?.some((item) =>
		permission?.includes(item)
	);
};

const PrivateRoute = ({ Component, auth, permission, Layout,isSuperAdmin, ...rest }) => {
	return (
        <Route
            {...rest}
            render={(props) =>
                auth.isAuthenticated === true ? (
                    isSuperAdmin && !auth.user.isSuperAdmin ? ( 
                        <Layout>
                            <PermissionModal {...props} /> 
                        </Layout>
                    ) : (
                        permission && auth.loading === false ? (
                            (hasAnyPermission(auth?.user.allowedRoles, permission, props) || auth.user.isSuperAdmin) ? (
                                <Layout>
                                    <Component {...props} />{" "}
                                </Layout>
                            ) : (
                                <Layout>
                                    <PermissionModal {...props} />
                                </Layout>
                            )
                        ) : (
                            <Layout>
                                <Component {...props} />{" "}
                            </Layout>
                        )
                    )
                ) : (
                    <Redirect to="/login" />
                )
            }
        />
    );
};

PrivateRoute.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProp = (state) => ({
	auth: state.auth,
});

export default connect(mapStateToProp, {})(PrivateRoute);
