import {
	ERRORS,
	CLEAR_RESPONSE,
	CREATE_ADMIN_REQUEST,
	UPDATE_ADMIN_REQUEST,
	REMOVE_ADMIN_REQUEST,
	CREATE_ROLE_REQUEST,
	UPDATE_ROLE_REQUEST,
	TOGGLE_ROLE_REQUEST,
	REMOVE_ROLE_REQUEST,
	CREATE_PERMISSION_REQUEST,
	UPDATE_PERMISSION_REQUEST,
	REMOVE_PERMISSION_REQUEST,
	TOGGLE_PERMISSION_REQUEST,
	PERMISSION_REQUEST,
	CREATE_WHITELIST_REQUEST,
	ROLE_REQUEST,
	CREATE_AIRDROP_REQUEST,
	UPDATE_PROJECT_REQUEST
} from "./actionTypes";

export const clearResponse = () => {
	return {
		type: CLEAR_RESPONSE,
		payload: {},
	};
};

export const create = (data) => {
	return {
		type: CREATE_ADMIN_REQUEST,
		payload: { data },
	};
};

// export const create = (data, callback) => {
//     return async (dispatch) => {
//         try {
//             // Make the API call to create the admin
//             const response = await yourAPICallFunction(data);

//             // Check if the response is successful
//             if (response && response.code === "200") {
//                 // Dispatch a success action
//                 dispatch({
//                     type: CREATE_ADMIN_SUCCESS,
//                     payload: response.data, // Assuming the data returned by the backend is included in the response
//                 });
                
//                 // Call the callback function with the response data
//                 if (callback) {
//                     callback(response.data);
//                 }
//             } else {
//                 // Dispatch a failure action if the response is not successful
//                 dispatch({
//                     type: CREATE_ADMIN_FAILURE,
//                     payload: response.error, // Assuming the error message returned by the backend is included in the response
//                 });
//             }
//         } catch (error) {
//             // Dispatch a failure action if an error occurs during the API call
//             dispatch({
//                 type: CREATE_ADMIN_FAILURE,
//                 payload: error.message, // Pass the error message to the failure action
//             });
//         }
//     };
// };


export const update = (data) => {
	return {
		type: UPDATE_ADMIN_REQUEST,
		payload: { data },
	};
};

export const remove = (id) => {
	return {
		type: REMOVE_ADMIN_REQUEST,
		payload: { id },
	};
};

export const getRoles = () => {
	return {
		type: ROLE_REQUEST,
		payload: {},
	};
};

export const createRole = (data) => {
	return {
		type: CREATE_ROLE_REQUEST,
		payload: { data },
	};
};

export const updateRole = (data) => {
	return {
		type: UPDATE_ROLE_REQUEST,
		payload: { data },
	};
};

export const removeRole = (id) => {
	return {
		type: REMOVE_ROLE_REQUEST,
		payload: { id },
	};
};

export const toggleRole = (id) => {
	return {
		type: TOGGLE_ROLE_REQUEST,
		payload: { id },
	};
};

export const getPermissions = () => {
	return {
		type: PERMISSION_REQUEST,
		payload: {},
	};
};

export const createPermission = (data) => {
	return {
		type: CREATE_PERMISSION_REQUEST,
		payload: { data },
	};
};

export const updatePermission = (data) => {
	return {
		type: UPDATE_PERMISSION_REQUEST,
		payload: { data },
	};
};

export const togglePermission = (id) => {
	return {
		type: TOGGLE_PERMISSION_REQUEST,
		payload: { id },
	};
};

export const removePermission = (id) => {
	return {
		type: REMOVE_PERMISSION_REQUEST,
		payload: { id },
	};
};

export const createAirdrop = (data) => {
	return {
		type: CREATE_AIRDROP_REQUEST,
		payload: { data },
	};
};

export const updateProject = (data) => {
	return {
		type: UPDATE_PROJECT_REQUEST,
		payload: { data },
	};
};

export const createWhitelist = (data) => {
	return {
		type: CREATE_WHITELIST_REQUEST,
		payload: { data },
	};
};