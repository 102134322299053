import React from "react";
import {
	Col,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	FormFeedback,
	Form,
	Input,
} from "reactstrap";
import "../../assets/scss/launchpad.scss";
import Dropzone from "react-dropzone";

const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/png"];

const ProjectLogoModal = ({
	projectLogoModalDetails,
	toggleProjectLogoModal,
	validationForProjectPicture,
	selectedLogoFile,
}) => {
	return (
		<React.Fragment>
				<Modal
					isOpen={projectLogoModalDetails}
					size="lg"
					centered={true}
				>
					<ModalHeader
						toggle={() => {
							toggleProjectLogoModal()
							validationForProjectPicture.resetForm();
						}}
						tag="h4"
					>
						Project Logo
					</ModalHeader>
					<ModalBody>
					<Form
						onSubmit={(e) => {
							e.preventDefault();
							validationForProjectPicture.handleSubmit();
							return false;
						}}
					>
						<fieldset>
							<div className="col-lg-12">
								<div className="mb-3">
									<label
										htmlFor="basicpill-firstname-input"
										className="form-label"
									>
										Upload Image
									</label>
									<Dropzone
										onDrop={(acceptedFiles) => {
											selectedLogoFile(
												acceptedFiles
											);
										}}
										accept="image/png, image/jpeg, image/webp"
										maxFiles={1}
									>
										{({
											getRootProps,
											getInputProps,
										}) => (
											<div className="dropzone">
												{
													<div
														className="dz-message needsclick mt-2"
														{...getRootProps()}
													>
														<input
															name="image"
															{...getInputProps()}
														/>
														<div className="mb-3">
															<i className="display-4 text-muted bx bxs-cloud-upload" />

															{validationForProjectPicture
																.values
																.picture
																?.path ? (
																<div className="dropzon-input">
																	<div className="imgBox">
																		<img
																			width="100px"
																			src={
																				validationForProjectPicture
																					.values
																					.picture
																					? URL.createObjectURL(
																						validationForProjectPicture
																							.values
																							.picture
																					)
																					: validationForProjectPicture
																						.values
																						.picture
																						?.path
																			}
																			alt={
																				validationForProjectPicture
																					.values
																					.picture
																					?.path
																			}
																		/>
																	</div>
																	{
																		validationForProjectPicture
																			.values
																			.picture
																			?.path
																	}
																</div>
															) : validationForProjectPicture
																.values
																?.picture ? (
																<div className="dropzon-input">
																	<div className="imgBox">
																		<img
																			width="100px"
																			src={
																				
																				validationForProjectPicture
																					.values
																					.picture
																			}
																			alt={
																				
																				validationForProjectPicture
																					.values
																					.picture
																			}
																		/>
																	</div>
																</div>
															) : (
																<h4>
																	click
																	to
																	upload.
																</h4>
															)}
														</div>
													</div>
												}
											</div>
										)}
									</Dropzone>
									<small className="form-text text-muted">
										Supported formats: {SUPPORTED_FORMATS.map(format => format.split('/')[1]).join(', ')}
										<br></br>
										Preferred logo height: 130 
									</small>
								</div>
									{/* </div> */}

									<Row>
									<Col>
										<div className="text-end mt-3">
											<button
												type="submit"
												className="btn btn-success save-user"
											>
												Save
											</button>
										</div>
									</Col>
								</Row>
							</div>
						</fieldset>
					</Form>
				</ModalBody>
			</Modal>
		</React.Fragment>
	);
};

export default ProjectLogoModal;
