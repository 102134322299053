const hasPermission = (permission, userPermissions) => {
	const couldShow = permission.some((perm) => userPermissions.includes(perm));
	return couldShow;
  };
export default hasPermission;

// const hasPermission = (isSuperAdmin, permission, userPermissions) => {
//     if (isSuperAdmin) {
//         return true;
//     }

//     const couldShow = permission.some((perm) => userPermissions.includes(perm));
//     return couldShow;
// };

// export default hasPermission;