import axios from "axios";
import { apiUrl } from "../../config";

export const create = (data) =>
	axios
		.post(`${apiUrl}/admin/network-info/create`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const update = (data, id) =>
	axios
		.patch(`${apiUrl}/currencies/${id}`, data)
		.then((response) => response)
		.catch((err) => err.response);

export const toggle = (data) =>
	axios
		.patch(`${apiUrl}/networks/${data.id}`,{isActive: data.isActive})
		.then((response) => response)
		.catch((err) => err.response);

export const remove = (id) =>
	axios
		.delete(`${apiUrl}/networks/${id}`)
		.then((response) => response)
		.catch((err) => err.response);
