import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useSelector } from "react-redux";

const Permission = (props) => {

	const { userPermissions, isSuperAdmin } = useSelector((state) => ({
		userPermissions: state.auth.user.permissions, 
		isSuperAdmin: state.auth.user.isSuperAdmin,
	}));
	
	if (isSuperAdmin) {
		return <>{props.children}</>;
	}

	const couldShow = props.userPermissions.some((item) =>
		props.permission.includes(item)
	);

	return couldShow ? props.children : null;
};

Permission.propTypes = {
	permission: PropTypes.array.isRequired,
	userPermissions: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
	userPermissions: state.auth.user.allowedRoles,
});

export default connect(mapStateToProps)(Permission);
