const environment = process.env.REACT_APP_ENVIRONMENT;

let apiUri;

switch (environment) {
    case 'production':
        apiUri = process.env.REACT_APP_PROD_API_URL;
        break;
    case 'development':
        apiUri = process.env.REACT_APP_DEV_API_URL;
        break;
    case 'staging':
        apiUri = process.env.REACT_APP_STAGING_API_URL;
        break;
    default:
        throw new Error('Unknown environment: ' + environment);
}

export const apiUrl = apiUri;
