import React, {	useEffect,useState,useRef,useMemo} from "react";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Helmet } from "react-helmet";
import {
	Card,
	CardBody,
	Col,
	Container,
	Row,
	Modal,
	ModalHeader,
	ModalBody,
	NavItem,
	TabContent,
	TabPane,
	NavLink,
	Input,
	Label,
	Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import classnames from "classnames";
// import * as yup from "yup";
import * as Yup from 'yup';
import { useFormik } from "formik";
//redux
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import HasAnyPermission from "../../common/Permission";
import hasPermission from "../../common/HasPermission";
import ReactDataTable from "../../common/ReactDataTable";
import isEmpty from "../../utils/isEmpty";
import stringToBoolean from "../../utils/stringToBoolean";
import axios from "axios"
import PublicSaleABI from "../../ABI/publicsaleFactory.json"
import { apiUrl } from "../../config";
import Erc20ABI from "../../ABI/Erc20.json"
import {
	toggle,
	getNetworkInfo,
	getTokenDetails,
	getLaunchpadOptions,
	clearTokenResponse,
	clearNotification,
	updateStage,
	toggleStageSwap,
} from "../../store/launchpad/actions";
import Swal from "sweetalert2";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import moment from "moment";
import "../../assets/scss/launchpad.scss";
import VerifyTokenStepOne from "./VerifyTokenStepOne";
import StageDetailStepSecond from "./StageDetailStepSecond";
import AdditionalInfoStepThird from "./AdditionalInfoStepThird";
import TeamTokenomicWhitepaperInfoStepFour from "./TeamTokenomicWhitepaperInfoStepFour";
import ReviewStepFive from "./ReviewStepFive";

import ListingStageInfo from "./ListingStageInfo";
import TeamInfoModal from "./TeamInfoModal";
import ProjectLogoModal from "./ProjectLogoModal";
import TokenomicModal from "./TokenomicModal";
import Web3 from 'web3';
import ContractInteraction from "./ContractInteraction";
import FacoryContract from "../../ABI/facoryContract.json"

let editor;

const Launchpad = (props) => {
	const dispatch = useDispatch();
	const refreshTableData = useRef(null);
	const getLoaderStatus = useRef(null);
	const [isTableVisible, setIsTableVisible] = useState(false);
	const [selectedProjectId, setSelectedProjectId] = useState(null);
	const [selectedProject, setSelectedProject] = useState(null);
	const [registeredUsers, setRegisteredUsers] = useState(0);
	const [isLoadingUSDT, setIsLoadingUSDT] = useState(false);

	let currentUTCDateString = moment.utc()

	const handleToggleTableVisibility = (project) => {
		setTotalRaisedUSDT(0)
		setSelectedProjectId(project.id);
		setIsTableVisible(!isTableVisible);
		setSelectedProject(project.projectTitle)
	};
	const { auth, launchpad } = useSelector((state) => ({
		auth: state.auth,
		launchpad: state.launchpad,
	}));
	const {
		isLoading,
	} = launchpad;
	useEffect(() => {
		dispatch(getNetworkInfo());
	}, []);
	const [listingStageInfo, setListingStageInfo] = useState({
		isOpenModal: false,
	});
	const { notifications, networkInfo } = launchpad;
	const [isOpenAddEditModal, toggleAddEditModal] = useState(false);
	const [isAdditionalSetting, toggleAdditionalSetting] = useState(false);
	const [settingData, setSettingData] = useState({})
	const [stageModalDetails, toggleStageModal] = useState({
		active: false,
		action: "add",
		isDirectInsert: false,
	});
	const [teamInfoModalDetails, toggleTeamInfoModal] = useState({
		active: false,
		action: "add",
	});
	
	const [projectLogoModalDetails, toggleProjectLogoModal] = useState(false)

	const [teamInfoIndex, setTeamInfoIndex] = useState(0);
	const [total, setTotal] = useState(100);
	const [tokenomicModalDetails, toggleTokenomicModal] = useState({
		active: false,
		action: "add",
	});
	const [networkDetails, setNetworkDetails] = useState({});
	const [tokenomicDetailsIndex, setTokenomicDetailsIndex] = useState(0);
	const [stageDetailIndex, setStageDetailIndex] = useState(0);
	const [activeTabFormStep, setActiveTabFormStep] = useState(1);
	const [currencyFeeOptions, setCurrencyFeeOptions] = useState([]);
	const [account, setAccount] = useState("");
	const [totalSumTokenomic, setTotalSumTokenomic]= useState(0);
	const [totalRaisedUSDT, setTotalRaisedUSDT] = useState(0);
	const [stageDetails, handleStageFormData] = useState([
		{
			rateInUSDT: "",
			liquidity: "",
			vestingFrequency: "",
			startDate: "",
			depositEndDate: "",
			pump: 0,
			power: 0,
			persistance: 0,
			probability: 0,
			vestingPercentage: "",
			snapshotStartDate: "",
			registerationEndDate: "",
			tokenReleaseDate: "",
			depositStartDate: "",
			cliffingStartDate: "",
			cliffingEndDate: "",
			tokenReleasePercentage: "",
			snapshotEndDate: "",
			active: false,
		},
	]);
	const [teamInfo, handleTeamInfoFormData] = useState([
		{
			name: "",
			picture: [],
			designation: "",
			active: true,
		},
	]);

	const [projectPicture, handleProjectPictureFormData] = useState([
		{
			picture: "",
			active: true,
		},
	]);

	const [tokenomicDetails, handleTokenomicFormData] = useState([
		{
			label: "",
			percentage: "",
			active: false,
		},
	]);
	const [whitepaper, handleWhitePapersFormData] = useState({
		path: "",
		url: "",
		uploadFile: null,
	});
	const [details, handleformData] = useState({
		tokenAddress: "",
		network: "",
		tokenName: "",
		tokenSymbol: "",
		tokenDecimal: "",
		ownerAddress: "",
		tokenTotalSupply:"",
		currency: [],
		additionalInfo: {},
	});
	const [additionalInfoData, handleformInfo] = useState({
		// logoUrl: "",
		website: "",
		projectTitle: "",
		projectSubTitle: "",
		aboutProject: "",
		embeddedVideoUrl: "",
		description: "",
		facebook: "",
		twitter: "",
		github: "",
		telegram: "",
		reddit: "",
		instagram: "",
		// mainPicture: "",
		projectPictures : "",
	})
	const [whitePaperData, handleWhitePaper] = useState({
		whitepaperUrl: "",
	})
	const [deployInfo, setDeployInfo] = useState({
		isOpenModal: false,
		launchpad: {},
	});
	const [launchpadOptions, setLaunchpadOption] = useState({});

	useEffect(() => {
		dispatch(getLaunchpadOptions());
	}, [dispatch]);

	useEffect(() => {
		const fetchAccount = async () => {
				try {
				const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
				let acc = await sessionStorage.getItem('metamaskAddress', accounts[0]);
				setAccount(acc)
			} catch (error) {
			}
		};
		fetchAccount();
	}, [dispatch]);

	const handleChangeNetwork = async (e) => {
		e.preventDefault();
		const networkDetails = networkInfo?.find(
			(network) => network.name === e.target.value
		);
		await checkNetwork(networkDetails.chainId,networkDetails.rpc,networkDetails.name)
		// const nativeCurrency = networkDetails?.currencies?.find(
		// 	(curr) => curr.name != "USDT"
		// );
		const currency = getSelectedCurrencyOption(
			e.target.value
		);
		validationForFirstStep?.setFieldValue("currency", currency);
		setNetworkDetails({ ...networkDetails });
	};

	const handleChangeCurrency = (selectedCurrencies, actionMeta) => { 
		switch (actionMeta.action) {
			case 'clear':
				toast.error("You can't remove all selected currencies.");
				break;
			case 'remove-value':
				if (actionMeta.removedValue?.isActive || actionMeta.removedValue?.value === 'USDT') {
					toast.error("You can't remove this selected currency.");
				} else {
					validationForFirstStep.setFieldValue('currency', selectedCurrencies);
				}
				break;
			case 'select-option':
			case 'deselect-option':
			case 'set-value':
				validationForFirstStep.setFieldValue('currency', selectedCurrencies);
				break;
			default:
				break;
		}
	};

	useEffect(() => {
		const options = {};

		launchpad.launchpadOptions.forEach((launchpadOption, index) => {
			options[launchpadOption.type] = launchpadOption;
			if (launchpadOption.type === "currency") {
				handleformData({
					...details,
					feeOption: launchpadOption.data[0].feeOption[0],
				});
				setCurrencyFeeOptions(1);
				validationForFirstStep.setFieldValue(
					"feeOption",
					launchpadOption.data[0].feeOption[0]
				);
			}
		});
		setLaunchpadOption(options);
	}, [launchpad.launchpadOptions]);

	useEffect(() => {
		let { tokenSymbol, tokenDecimal, tokenName ,tokenTotalSupply} = launchpad.tokenDetailResponse?.data ?? {};
		tokenName = tokenName ? tokenName : null;
		tokenSymbol = tokenSymbol ? tokenSymbol : null;
		tokenDecimal = tokenDecimal ? tokenDecimal : null;
		tokenTotalSupply = tokenTotalSupply ? tokenTotalSupply : null;
		validationForFirstStep.setFieldValue("tokenName", tokenName);
		validationForFirstStep.setFieldValue("tokenSymbol", tokenSymbol);
		validationForFirstStep.setFieldValue("tokenDecimal", tokenDecimal);
		validationForFirstStep.setFieldValue("tokenTotalSupply", tokenTotalSupply);
	}, [launchpad.tokenDetailResponse]);

	const onEditorChange = (data) => {
		validationForThirdStep.setFieldValue("description", data);
	};

	useEffect(() => {
		if (notifications && notifications.status === 200) {
			Swal.close();
			refreshTableData.current();
			if (isOpenAddEditModal) toggleAddEditModal(!isOpenAddEditModal);
			toast.success(notifications?.message, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		} else if (notifications && notifications?.message) {
			Swal.close();
			refreshTableData.current();
			toast.error(notifications.message, {
				onOpen: () => {
					dispatch(clearNotification());
				},
			});
		}
	}, [dispatch, notifications]);

	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [projects, setProjects] = useState([]);

	useEffect(() => {
		const fetchProjects = async () => {
			try {
				let token = localStorage.getItem('jwtToken')
				const response = await axios.get(`${apiUrl}/projects/all?limit=1`, {
					headers: {
					'Authorization': `Bearer ${token}`, // Include your JWT token here
					},
				});
				setTotal(response.data.total)
				const totalProjects = await axios.get(`${apiUrl}/projects/all?limit=${response.data.total}`);
				setProjects(totalProjects.data.projects); 
			} catch (error) {
				console.error('Error fetching data:', error);
			}
		};
		fetchProjects();
	}, [dispatch]);

	const toggleTab = async (tab, previous = false) => {
		if (activeTabFormStep !== tab) {
			if (previous) setActiveTabFormStep(tab);
			else {
				if (tab >= 1 && tab <= 5) {
					if (tab === 2) {
						const result =
							await validationForFirstStep.validateForm();
						if (
							isEmpty(result) &&
							isEmpty(
								launchpad.tokenDetailResponse?.errors
									?.tokenAddress
							)
						) {
							setActiveTabFormStep(tab);
						} else {
							validationForFirstStep.handleSubmit();
						}
					} else if (tab === 3) {
						const result =
							await validationForSecondStep.validateForm();
						validationForSecondStep.handleSubmit();
						if (isEmpty(result)) {
							setActiveTabFormStep(tab);
						} else {
							validationForSecondStep.handleSubmit();
						}
					} else if (tab === 4) {
						const result =	await validationForThirdStep.validateForm();
						if (isEmpty(result)) {
							setActiveTabFormStep(tab);
						} else {
							validationForThirdStep.handleSubmit();
						}
					} else {
						setActiveTabFormStep(tab);
					}
				}
			}
		}
	};

	const projectSetting = async (data) => {
		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
		if (!fromAddress) return Swal.fire({
			icon: 'error',
			title: 'Wallet not connected',
			text: 'Please connect your wallet',
		});
		await checkNetwork(data?.network?.chainId, data?.network?.rpc, data?.network?.name)
		setSettingData({ ...data })
		toggleAdditionalSetting(!isAdditionalSetting);
	}

	const defaultNetwork = networkInfo.find(network => network.chainId === '0x89' )

	const getInitialValueForFirstStep = useMemo(() => {
		let initialValues = {
			tokenAddress:
				details?.tokenAddress ?? "",
			tokenName: details?.tokenName ?? "",
			network: details.network?.name ?? "",
			// network: details.network?.name ?? defaultNetwork?.name,
			tokenSymbol:
				details.tokenSymbol ?? "",
			tokenDecimal:
				details.tokenDecimal ?? "",
			tokenTotalSupply:
				details?.tokenTotalSupply
				?? "",
			ownerAddress: details?.ownerAddress ?? [],
			currency: details?.currency ?? "",
			// currency: details?.currency ?? defaultNetwork?.currencies[0],
		};
		initialValues["currency"] =
			details && details.currency ? details.currency : [];
		return initialValues;
	}, [isOpenAddEditModal, listingStageInfo.isOpenModal]);

	const useFormikOptionsForFirstStep = {
		enableReinitialize: true,
		initialValues: getInitialValueForFirstStep,
		validationSchema: Yup.object({
			network: Yup.string().required(),
			tokenAddress: Yup.string()
				// .matches(/^0x[a-fA-F0-9]{40}$/i, 'Invalid Address')
				.required('Token address is required'),
			ownerAddress: Yup.string()
				// .matches(/^0x[a-fA-F0-9]{40}$/i, 'Invalid Address')
				.required('Owner address is required'),
		})
		// .test('token-exists', 'Token already exists for the selected network',async function(value) {
		// 		let id = await details.id
		// 		if(id === undefined){
		// 			const { path, createError } = this;
		// 			if (Array.isArray(projects)){
		// 			const { network ,tokenAddress } = value;
		// 				let networkProjects = projects.filter(project => project.network?.name?.toLowerCase() === network?.toLowerCase());
		// 				if (Array.isArray(networkProjects)){
		// 					const tokenExists =  networkProjects?.some(project => project.tokenAddress?.toLowerCase() === tokenAddress?.toLowerCase());
		// 						if (tokenExists) {
		// 						return createError({ path: 'tokenAddress', message: 'Project already exists for the selected token' });
		// 					}
		// 					return true; 
		// 				}
		// 			}
		// 		}else{
		// 			return true;
		// 		}
		// 	})
			,
		onSubmit: (values) => {},
	};

	const validationForFirstStep = useFormik(useFormikOptionsForFirstStep);

	const getInitialValueForThirdStep = useMemo(() => {
		let initialValues = {
			// logoUrl: additionalInfoData && additionalInfoData.logoUrl
			// 	? additionalInfoData.logoUrl
			// 	: "",
			website: additionalInfoData && additionalInfoData.website
				? additionalInfoData.website
				: "",
			facebook: additionalInfoData && additionalInfoData.facebook
				? additionalInfoData.facebook
				: "",
			twitter: additionalInfoData && additionalInfoData.twitter
				? additionalInfoData.twitter
				: "",
			github: additionalInfoData && additionalInfoData.github
				? additionalInfoData.github
				: "",
			telegram: additionalInfoData && additionalInfoData.telegram
				? additionalInfoData.telegram
				: "",
			instagram: additionalInfoData && additionalInfoData.instagram
				? additionalInfoData.instagram
				: "",
			reddit: additionalInfoData && additionalInfoData.reddit
				? additionalInfoData.reddit
				: "",
			discord: details.additionalInfo?.discord
				? details.additionalInfo.discord
				: "",
			linkedin: details.additionalInfo?.linkedin
				? details.additionalInfo.linkedin
				: "",
			description: additionalInfoData && additionalInfoData.description
				? additionalInfoData.description
				: "",

			// mainPicture: details.additionalInfo?.mainPicture
			// 	? details.additionalInfo.mainPicture
			// 	: "",
			// details && details.tokenAddress ? details.tokenAddress : "",
			projectTitle:
				additionalInfoData && additionalInfoData.projectTitle
					? additionalInfoData.projectTitle
					: "",
			projectSubTitle:
				additionalInfoData && additionalInfoData.projectSubTitle
					? additionalInfoData.projectSubTitle
					: "",
			aboutProject: additionalInfoData && additionalInfoData.aboutProject
				? additionalInfoData.aboutProject
				: "",
			embeddedVideoUrl: additionalInfoData && additionalInfoData.embeddedVideoUrl
				? additionalInfoData.embeddedVideoUrl
				: "",
			// projectPictures : additionalInfoData && additionalInfoData.projectPictures
			// ? additionalInfoData.projectPictures
			// : "",
		};
		return initialValues;
	}, [isOpenAddEditModal, listingStageInfo.isOpenModal]);

	const useFormikOptionsForThirdStep = {
		enableReinitialize: true,
		initialValues: getInitialValueForThirdStep,
		validationSchema: Yup.object({
			// logoUrl:Yup.string().required(),
			embeddedVideoUrl:Yup.string().required(),
			description: Yup.string().required(),
			website: Yup.string().required(),
			projectTitle: Yup.string().required(),
			projectSubTitle: Yup.string().required(),
			aboutProject: Yup.string().required(),
			// projectPictures : Yup.string().required(),
		}),
		onSubmit: (values) => {
		 },
	};

	const validationForThirdStep = useFormik(useFormikOptionsForThirdStep);

	const initialValuesForWhitePaper = {
		enableReinitialize: true,
		initialValues: {
			whitepaperUrl: whitePaperData && whitePaperData.whitepaperUrl
				? whitePaperData.whitepaperUrl
				: "",
		},
		validationSchema: Yup.object({
			whitepaperUrl: Yup.string().required(),
		}),
		onSubmit: (values) => { },
	}

	const validationForWhitePaper = useFormik(initialValuesForWhitePaper);

	const initialValuesForStage = {
		
		liquidity: stageDetails[stageDetailIndex]?.liquidity
			? stageDetails[stageDetailIndex].liquidity
			: "",
		liquidityLockup: stageDetails[stageDetailIndex]?.liquidityLockup
			? stageDetails[stageDetailIndex].liquidityLockup
			: "",

		vestingFrequency: stageDetails[stageDetailIndex]?.vestingFrequency
			? stageDetails[stageDetailIndex].vestingFrequency
			: "",
		tokenReleaseDate: stageDetails[stageDetailIndex]?.tokenReleaseDate
			? stageDetails[stageDetailIndex]?.tokenReleaseDate.replace("Z", "")
			: "",
		startDate: stageDetails[stageDetailIndex]?.startDate
			? stageDetails[stageDetailIndex]?.startDate.replace("Z", "")
			: "",
		depositEndDate: stageDetails[stageDetailIndex]?.depositEndDate
			? stageDetails[stageDetailIndex]?.depositEndDate.replace("Z", "")
			: "",

		// Adding
		rateInUSDT: stageDetails[stageDetailIndex]?.rateInUSDT
			? stageDetails[stageDetailIndex].rateInUSDT
			: "",
		pump: stageDetails[stageDetailIndex]?.pump
			? stageDetails[stageDetailIndex].pump
			: 0,
		power: stageDetails[stageDetailIndex]?.power
			? stageDetails[stageDetailIndex].power
			: 0,
		probability: stageDetails[stageDetailIndex]?.probability
			? stageDetails[stageDetailIndex].probability
			: 0,
		persistance: stageDetails[stageDetailIndex]?.persistance
			? stageDetails[stageDetailIndex].persistance
			: 0,
		vestingPercentage: stageDetails[stageDetailIndex]?.vestingPercentage
			? stageDetails[stageDetailIndex].vestingPercentage
			: "",
		snapshotStartDate: stageDetails[stageDetailIndex]?.snapshotStartDate
			? stageDetails[stageDetailIndex].snapshotStartDate
			: "",
		snapshotEndDate: stageDetails[stageDetailIndex]?.snapshotEndDate
			? stageDetails[stageDetailIndex].snapshotEndDate
			: "",
		tokenReleasePercentage: stageDetails[stageDetailIndex]?.tokenReleasePercentage
			? stageDetails[stageDetailIndex].tokenReleasePercentage
			: "",
		registerationEndDate: stageDetails[stageDetailIndex]?.registerationEndDate
			? stageDetails[stageDetailIndex].registerationEndDate.replace("Z", "")
			: "",
		depositStartDate: stageDetails[stageDetailIndex]?.depositStartDate
			? stageDetails[stageDetailIndex].depositStartDate.replace("Z", "")
			: "",
		cliffingStartDate: stageDetails[stageDetailIndex]?.cliffingStartDate
			? stageDetails[stageDetailIndex].cliffingStartDate.replace("Z", "")
			: "",
		cliffingEndDate: stageDetails[stageDetailIndex]?.cliffingEndDate
			? stageDetails[stageDetailIndex].cliffingEndDate.replace("Z", "")
			: "",
	};

	// Helper function to strip time from a date
	const stripTime = (date) => {
		const newDate = new Date(date);
		newDate.setHours(0, 0, 0, 0);
		return newDate;
	};
  
  Yup.addMethod(Yup.date, 'dateOnlyMin', function (ref, message) {
	return this.test('dateOnlyMin', message, function (value) {
	  const { path, createError } = this;
	  if (!value || !ref) return true;
	  return stripTime(value) >= stripTime(this.resolve(ref)) || createError({ path, message });
	});
  });

  Yup.addMethod(Yup.date, 'dateOnlyMax', function (ref, message) {
	return this.test('dateOnlyMax', message, function (value) {
	  const { path, createError } = this;
	  if (!value || !ref) return true;
	  return stripTime(value) <= stripTime(this.resolve(ref)) || createError({ path, message });
	});
  });
  
	const useFormikOptionsForStage = {
		enableReinitialize: true,
		initialValues: initialValuesForStage,
		validationSchema: Yup.object()
			.shape({
				rateInUSDT: Yup.string().required(),
				vestingPercentage: Yup.number().required()
					.max(100,'Must be atmost 100'),
				vestingFrequency: Yup.string().required(),
				tokenReleasePercentage: Yup.number().required()
					.max(100,'Must be atmost 100'),
				// liquidity: Yup.number().required().max(validationForFirstStep.values.tokenTotalSupply,"Liquidity cannot be more than total supply"),
				startDate: Yup.date().required("Project Start Time is required").min(moment.utc().format('YYYY-MM-DDTHH:mm'), "Project Start Time cannot be in the past"),
				registerationEndDate: Yup.date()
					.required("Register Interest End Time is required")
					.min(Yup.ref('startDate'), "Register Interest End Time must be after Project Start Time"),
				snapshotStartDate: Yup.date()
					.required("Snapshot Start Time is required")
					.dateOnlyMin(Yup.ref('startDate'), "Snapshot Start Date must be after Project Start Date"),
				snapshotEndDate: Yup.date()
					.required("Snapshot End Time is required")
					.dateOnlyMin(Yup.ref('snapshotStartDate'), "Snapshot End Time must be after Snapshot Start Time")
					.dateOnlyMin(Yup.ref('registerationEndDate'), "Snapshot End date must be after Registration End date)"),
				depositStartDate: Yup.date()
					.required("Deposit Start Time is required")
					.dateOnlyMin(Yup.ref('snapshotStartDate'), "Deposit Start Time must be after Snapshot Start  Time")
					.dateOnlyMax(Yup.ref('snapshotEndDate'), "Deposit Start Time must be before Snapshot End Time"),
				depositEndDate: Yup.date()
					.required("Deposit End Time is required")
					.min(Yup.ref('depositStartDate'), "Deposit End Time must be after Deposit Start time")
					.dateOnlyMin(Yup.ref('snapshotEndDate'), "Deposit End Time must be after Snapshot End time"),
				cliffingStartDate: Yup.date()
					.required("Cliffing Start Time is required")
					.min(Yup.ref('depositEndDate'), "Cliffing Start Time must be after Deposit End Time")
					.test('sameAsSnapshotStart', 'Cliffing Start Time must be same as Deposit End Time', function (value) {
						return moment(value).isSame(this.parent.depositEndDate);
					}),
				cliffingEndDate: Yup.date()
					.required("Cliffing End Time is required")
					.min(Yup.ref('cliffingStartDate'), "Cliffing End Time must be after Cliffing Start Time"),
				tokenReleaseDate: Yup.date()
					.required("Token Release Date is required")
					.min(Yup.ref('cliffingStartDate'), "Token Release Date must be after Cliffing Start Time")
					.max(Yup.ref('cliffingEndDate'), "Token Release Date must be before Cliffing End Time"),
				
					pump: Yup.number()
					.required('Required')
					.typeError('Must be a number')
					.min(0, 'Must be at least 0')
					.max(100, 'Must be at most 100'),
				power: Yup.number()
					.required('Required')
					.typeError('Must be a number')
					.min(0, 'Must be at least 0')
					.max(100, 'Must be at most 100'),
				persistance: Yup.number()
					.required('Required')
					.typeError('Must be a number')
					.min(0, 'Must be at least 0')
					.max(100, 'Must be at most 100'),
				probability: Yup.number()
					.required('Required')
					.typeError('Must be a number')
					.min(0, 'Must be at least 0')
					.max(100, 'Must be at most 100'),
			}).test('total-100',  'Total percentage must be 100',async function(values) {
				const { pump, power, persistance, probability } = values;
					const { path, createError } = this;
					if ((Number(pump) + Number(power) + Number(persistance) + Number(probability)) !== 100) {
					  return createError({ path : 'pump', message: 'Total percentage of PPPP must be 100' });
					}
					return true
			  })
			  .test('Liquidity', 'Liquidity cannot be more than total supply', async function(value) {
				const { liquidity } = value;
				const { path, createError } = this;
				if (details.id) {
				  const totalSupply = validationForFirstStep.values.tokenTotalSupply;
				if (totalSupply && liquidity > totalSupply) {
					return createError({ path: 'liquidity', message: 'Liquidity cannot be more than total supply' });
				  }
				}
				return true;
			  })
			  ,
	
		onSubmit: (values) => {
			let currencyRateDetails = [];
			
			let stageNumber = stageDetails.length;

			if (stageDetails?.[stageDetailIndex]) {

				stageNumber = stageDetailIndex;
				stageDetails[stageDetailIndex] = {
					...stageDetails[stageDetailIndex],
					...values,
					stageNumber: stageDetailIndex,
					isExchangeList: stringToBoolean(values.isExchangeList),
					active: true,
				};
				handleStageFormData([...stageDetails]);
			} else {
				handleStageFormData([
					...stageDetails,
					{
						...values,
						stageNumber: stageNumber,
						active: true,
					},
				]);
			}
			if (stageModalDetails.isDirectInsert === true) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				const startDate = values.startDate.replace("Z", "");
				const depositEndDate = values.depositEndDate.replace("Z", "");
				let myDateStartDate = new Date(startDate + "Z");
				let myDateEndDate = new Date(depositEndDate + "Z");
				let startDateFormat = myDateStartDate.toUTCString();
				let endDateFormat = myDateEndDate.toUTCString();

				let releaseDateFormat = values.tokenReleaseDate;
				if (values.tokenReleaseDate) {
					const tokenReleaseDate = values.tokenReleaseDate?.replace("Z", "");
					let myDateReleaseDate = new Date(tokenReleaseDate + "Z");
					releaseDateFormat = myDateReleaseDate.toUTCString();
				}
				dispatch(
					updateStage({
						...values,
						startDate: startDateFormat,
						depositEndDate: endDateFormat,
						tokenReleaseDate: releaseDateFormat,
						stageNumber: stageNumber,
						active: true,
						id: details?.id,
					})
				);
			}
			toggleStageModal({
				...stageModalDetails,
				active: false,
				isDirectInsert: false,
			});
		},
	};
	const validationForSecondStep = useFormik(useFormikOptionsForStage);

	const validationForStage = useFormik(useFormikOptionsForStage);

	const useFormikOptionsForTeamInfoStep = {
		enableReinitialize: true,
		initialValues: {
			name: teamInfo[teamInfoIndex]?.name
				? teamInfo[teamInfoIndex].name
				: "",
			designation: teamInfo[teamInfoIndex]?.designation
				? teamInfo[teamInfoIndex].designation
				: "",

			picture: teamInfo[teamInfoIndex]?.picture
				? teamInfo[teamInfoIndex].picture : [],

			active: teamInfo[teamInfoIndex]?.active
				? teamInfo[teamInfoIndex].active
				: "",
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			if (teamInfo?.[teamInfoIndex]) {
				teamInfo[teamInfoIndex] = {
					...teamInfo[teamInfoIndex],
					...values,
					active: true,
				};
				handleTeamInfoFormData([...teamInfo]);
				toast.success("Team updated successfully.");
			} else {
				handleTeamInfoFormData([
					...teamInfo,
					{
						...values,
						active: true,
					},
				]);
				toast.success("Team add successfully.");
			}
			toggleTeamInfoModal({ ...teamInfoModalDetails, active: false });
		},
	};


	const useFormikOptionsForProjectPicture = {
		enableReinitialize: true,
		initialValues: {
			picture: projectPicture?.picture
				? projectPicture.picture : "",
		},
		validationSchema: Yup.object({
			picture : Yup.string().required(),
		}),
		onSubmit: (values) => {
			handleProjectPictureFormData({
				picture: values.picture,
			});
			toast.success("Logo updated successfully.");
			toggleProjectLogoModal(false);
		},
	};

	const validationForTeamInfoStep = useFormik(
		useFormikOptionsForTeamInfoStep
	);

	const validationForProjectPicture = useFormik(
		useFormikOptionsForProjectPicture
	);

	const useFormikOptionsForTokenomicStep = {
		enableReinitialize: true,
		initialValues: {
			label: tokenomicDetails[tokenomicDetailsIndex]?.label
				? tokenomicDetails[tokenomicDetailsIndex].label
				: "",
			percentage: tokenomicDetails[tokenomicDetailsIndex]?.percentage
				? tokenomicDetails[tokenomicDetailsIndex].percentage
				: "",
			active: tokenomicDetails[tokenomicDetailsIndex]?.active
				? tokenomicDetails[tokenomicDetailsIndex].active
				: "",
		},
		validationSchema: Yup.object({}),
		onSubmit: (values) => {
			const newPercentage = parseFloat(values.percentage || 0);
			const otherPercentages = tokenomicDetails.reduce((total, item, index) => {
				if (index !== tokenomicDetailsIndex) {
					return total + parseFloat(item.percentage || 0);
				}
				return total;
			}, 0);
			const currentTotalPercentage = otherPercentages + newPercentage;
			setTotalSumTokenomic(currentTotalPercentage)
			if (currentTotalPercentage > 100) {
				toast.error("Total percentage cannot exceed 100%");
				return;
			}
			
			if (tokenomicDetails?.[tokenomicDetailsIndex]) {
				tokenomicDetails[tokenomicDetailsIndex] = {
					...tokenomicDetails[tokenomicDetailsIndex],
					...values,
					active: true,
				};

				handleTokenomicFormData([...tokenomicDetails]);
				toast.success("Tokenomics updated successfully.");
			} else {
				handleTokenomicFormData([
					...tokenomicDetails,
					{
						...values,
						active: true,
					},
				]);
				toast.success("Tokenomics add successfully.");
			}

			toggleTokenomicModal({ ...tokenomicModalDetails, active: false });
		},
	};

	const validationForTokenomicStep = useFormik(
		useFormikOptionsForTokenomicStep
	);

	const getCurrencyOption = useMemo(() => {
		const result = networkDetails?.currencies
		?.filter(item => item.isActive) 
		?.map(item => ({
			label: `${item.name}`,
			value: item.id,
			isActive: true,
			name: item.name,
		}));
		// console.log("Result---- ",result)
		return result ?? [];
	}, [networkDetails]);

	const getSelectedCurrencyOption = (currencies, selectedNetwork) => {
		const networkDetails = networkInfo.find(network => network.name === selectedNetwork);
		const result = [];
		networkDetails?.currencies?.forEach((item) => {
			if (currencies.includes(item.name)) {
				result.push({
					label: `${item.name}`,
					value: item.id,
					isActive: item.isActive,
					name: item.name,
					address: item.contractAddress,
				});
			}
		});
		return result ?? [];
	};

	const [currencyOptions, setCurrencyOptions] = useState([]);

	const handleAddEditModal = async (data = null, isOnlyForStage = false) => {
		if(isOpenAddEditModal) window.location.reload()
		setActiveTabFormStep(1);
		validationForFirstStep.resetForm();
		validationForSecondStep.resetForm();
		validationForThirdStep.resetForm();
		validationForWhitePaper.resetForm();
		
		if (!isEmpty(data) && data?.id) {
			if (data?.network) {
				await checkNetwork(data?.network?.chainId, data?.network?.rpc, data?.network?.name)
				const networkDetails = networkInfo?.find(
					(network) => network.id === data.network.id
				);
				setNetworkDetails({ ...networkDetails });
			}
			const selectedNetworkName = data.network?.name;
			const currency = getSelectedCurrencyOption(data.currency?.name, selectedNetworkName);
			handleformData({ ...data, currency: currency });
			handleTokenomicFormData(data.tokenomics ?? [{ active: false }]);
			handleTeamInfoFormData(data.teamMembers ?? [{ active: false }]);
			handleProjectPictureFormData(data.projectPictures ?? [{ active: false }]);
			handleStageFormData([{ ...data, "tokenReleaseDate": data.tokenReleaseDate }])

			if (data?.tokenomics) {
				const totalPercentage = data.tokenomics.reduce((total, item) => total + parseFloat(item.percentage || 0), 0);
				setTotalSumTokenomic(totalPercentage);
			}

			handleformInfo({
				...data,
				"facebook": data.socialMedia.facebook,
				"twitter": data.socialMedia.twitter,
				"github": data.socialMedia.github,
				"telegram": data.socialMedia.telegram,
				"reddit": data.socialMedia.reddit,
				"instagram": data.socialMedia.instagram,
				"marketingPictures": data.marketingPictures,
			})

			handleWhitePaper({ ...data })
		}

		else {
			await dispatch(clearTokenResponse());

			// await checkNetwork(defaultNetwork?.chainId, defaultNetwork?.rpc, defaultNetwork?.name)
			setNetworkDetails({ ...defaultNetwork });

			const selectedNetworkName = defaultNetwork?.name;
			const currency = getSelectedCurrencyOption(defaultNetwork?.currencies[0]?.name, selectedNetworkName);

			handleformData({
				tokenAddress: "0x0e80Ac387f4447f7B9f323c41B5504649bDf88b9",
				network: defaultNetwork,
				tokenSymbol: "GAMMA",
				tokenDecimal: 8,
				ownerAddress: "0x00",
				currency: currency,
				feeOption: "",
				tokenTotalSupply:700000,
				additionalInfo: {},
			});
			setStageDetailIndex(0);
			setTotalSumTokenomic(0);
			handleStageFormData([
				{
					stage: "",
					rateInUSDT: 0.0025,
					liquidity: 1000,
					vestingFrequency: 1,
					vestingPercentage : 10,
					tokenReleasePercentage: 10,
					tokenReleaseDate: "",
					active: false,
					startDate: currentUTCDateString.clone().add(10, 'minutes').format('YYYY-MM-DD HH:mm'),
					registerationEndDate: currentUTCDateString.clone().add(10, 'days').format('YYYY-MM-DD HH:mm'),
					snapshotStartDate:currentUTCDateString.clone().add(10, 'minutes').format('YYYY-MM-DD HH:mm'),
					snapshotEndDate:currentUTCDateString.clone().add(10, 'days').format('YYYY-MM-DD HH:mm'),
					depositStartDate: currentUTCDateString.clone().add(10, 'minutes').format('YYYY-MM-DD HH:mm'),
					depositEndDate:currentUTCDateString.clone().add(10, 'days').format('YYYY-MM-DD HH:mm'),
					cliffingStartDate: currentUTCDateString.clone().add(10, 'days').format('YYYY-MM-DD HH:mm'),
					tokenReleaseDate:currentUTCDateString.clone().add(10, 'days').format('YYYY-MM-DD HH:mm'),
					cliffingEndDate: currentUTCDateString.clone().add(10, 'days').format('YYYY-MM-DD HH:mm'),
					pump: 25,
					power: 25,
					persistance: 25,
					probability: 25,
				},
			]);
			handleformInfo([{
				logoUrl: "",
				website: "",
				projectTitle: "",
				projectSubTitle: "",
				aboutProject: "",
				embeddedVideoUrl: "",
				description: "",
				facebook: "",
				twitter: "",
				github: "",
				telegram: "",
				reddit: "",
				instagram: "",
			}])

			handleTokenomicFormData([{
				label: "",
				percentage: "",
				active: false
			}])

			handleTeamInfoFormData([{
				name: "",
				picture: "",
				designation: "",
				active: false
			}])
			handleProjectPictureFormData([{
				picture:""
			}])
		}

		if (isOnlyForStage === false) toggleAddEditModal(!isOpenAddEditModal);
	};

	const selectedFile = (selectedFiles) => {
		if (isEmpty(selectedFiles)) {
			return false;
		} else {
			let droppedFile = {};
			selectedFiles.map((file) => {
				droppedFile = file;
				return true;
			});
			handleWhitePapersFormData({
				...whitepaper,
				uploadFile: droppedFile,
			});
		}
	};

	const selectedTeamFile = (selectedFiles, index) => {
		if (isEmpty(selectedFiles)) {
			validationForTeamInfoStep.setFieldValue("picture", null);
			return false;
		} else {
			let droppedFile = {};
			selectedFiles.map((file) => {
				droppedFile = file;
				return true;
			});
			validationForTeamInfoStep.setFieldValue("picture", droppedFile);
		}
	};

	const selectedLogoFile = (selectedFiles) => {
		if (isEmpty(selectedFiles)) {
			validationForProjectPicture.setFieldValue("picture", null);
			return false;
		} else {
			let droppedFile = {};
			selectedFiles.map((file) => {
				droppedFile = file;
				return true;
			});
			validationForProjectPicture.setFieldValue("picture", droppedFile);
		}
	};

	const handleFinalFormSubmit = async () => {
		let feeOption = {};
		if (validationForFirstStep.values.feeOption) {
			feeOption = currencyFeeOptions.find(
				(feeOp) =>
					parseInt(feeOp.fee) ===
					parseInt(validationForFirstStep.values.feeOption)
			);
		}
		if (isEmpty(feeOption)) {
			feeOption = details.feeOption;
		}
		let whitePaperFile = null;
		if (whitepaper.uploadFile) {
			whitePaperFile = whitepaper.uploadFile;
		}

		let networkId;
		networkInfo.map((idx, value) => {
			if (idx.name === validationForFirstStep.values.network) {
				networkId = idx.id
			}
		})

		let currency = 0
		if (Array.isArray(validationForFirstStep.values.currency)) {
			currency = validationForFirstStep.values.currency[0]?.value
		}else{
			currency =validationForFirstStep.values.currency?.value
		}

		var formData = new FormData();
		formData.append('network', networkId);
		formData.append('tokenAddress', validationForFirstStep.values.tokenAddress);
		formData.append('tokenSymbol', validationForFirstStep.values.tokenSymbol);
		formData.append('tokenDecimal', validationForFirstStep.values.tokenDecimal);
		formData.append('ownerAddress', validationForFirstStep.values.ownerAddress);
		formData.append('currency', currency);
		formData.append('refundType', "Buyback");
		formData.append('buybackWallet', validationForFirstStep.values.ownerAddress);
		formData.append('rateInUSDT', stageDetails[0].rateInUSDT.toString());
		formData.append('liquidity', stageDetails[0].liquidity.toString());
		formData.append('pump', stageDetails[0].pump.toString());
		formData.append('power', stageDetails[0].power.toString());
		formData.append('persistance', stageDetails[0].persistance.toString());
		formData.append('probability', stageDetails[0].probability.toString());
		formData.append('depositFee', 0);
		formData.append('tokenClaimFee', 0);
		formData.append('withdrawFee', 0);
		formData.append('startDate', stageDetails[0].startDate.toString());
		formData.append('endDate', stageDetails[0].depositEndDate.toString());
		formData.append('depositEndDate', stageDetails[0].depositEndDate.toString());
		formData.append("registerationEndDate", stageDetails[0].registerationEndDate.toString())
		formData.append("depositStartDate", stageDetails[0].depositStartDate.toString())
		formData.append("cliffingStartDate", stageDetails[0].cliffingStartDate.toString())
		formData.append("cliffingEndDate", stageDetails[0].cliffingEndDate.toString())
		formData.append('vestingFrequency', stageDetails[0].vestingFrequency.toString());
		formData.append('vestingPercentage', stageDetails[0].vestingPercentage.toString());
		formData.append('snapshotStartDate', stageDetails[0].snapshotStartDate.toString());
		formData.append("snapshotEndDate", stageDetails[0].snapshotEndDate.toString())
		formData.append('tokenReleaseDate', stageDetails[0].tokenReleaseDate.toString());
		formData.append('tokenReleasePercentage', stageDetails[0].tokenReleasePercentage.toString())
		// formData.append('logoUrl', validationForThirdStep.values.logoUrl.toString());  // 
		formData.append('website', validationForThirdStep.values.website.toString()); // validationForThirdStep.values.website.toString()
		formData.append('socialMedia[facebook]', validationForThirdStep.values.facebook.toString());
		formData.append('socialMedia[twitter]', validationForThirdStep.values.twitter.toString());
		formData.append('socialMedia[github]', validationForThirdStep.values.github.toString());
		formData.append('socialMedia[telegram]', validationForThirdStep.values.telegram.toString());
		formData.append('socialMedia[reddit]', validationForThirdStep.values.reddit.toString());
		formData.append('socialMedia[instagram]', validationForThirdStep.values.instagram.toString());
		formData.append('projectTitle', validationForThirdStep.values.projectTitle.toString());
		formData.append('projectSubTitle', validationForThirdStep.values.projectSubTitle.toString());
		formData.append('description', validationForThirdStep.values.description.toString());
		formData.append('embeddedVideoUrl', validationForThirdStep.values.embeddedVideoUrl.toString());
		formData.append('aboutProject', validationForThirdStep.values.aboutProject.toString());
		// let pictures = []

		teamInfo.forEach((member, index) => {
			formData.append(`teamMembers[${index}][name]`, String(member.name));
			formData.append(`teamMembers[${index}][designation]`, String(member.designation));
			if(!details?.id){
				if(!isEmpty(member.picture)){
					formData.append(`teamPictures`, member.picture);
				}
			}
		});

		if(!isEmpty(projectPicture.picture)){
			formData.append('projectPictures', projectPicture.picture)
		}

		tokenomicDetails.forEach((detail, index) => {
			formData.append(`tokenomics[${index}][label]`, String(detail.label));
			formData.append(`tokenomics[${index}][percentage]`, detail.percentage);
		});
		// if(validationForThirdStep.values?.mainPicture){
		// 	formData.append('marketingPictures', validationForThirdStep.values.mainPicture);
		// }
		formData.append('whitepaperUrl', validationForWhitePaper.values.whitepaperUrl.toString());
		if (details.id) {
			axios
				.patch(`${apiUrl}/projects/${details.id}`, formData)
				.then((response) => {
					Swal.fire({
						title: 'Success!',
						text: 'The project has been successfully updated!',
						icon: 'success',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed || result.value) {
							window.location.reload();
						}
					});
				})
				.catch((err) => {
					Swal.fire({
						title: 'Error!',
						text: `Failed to update the project: ${err.response?.data?.message || err.message}`,
						icon: 'error',
						confirmButtonText: 'OK'
					});
				});
		}
		else {
			axios
				.post(`${apiUrl}/projects`, formData, {
					headers: {
						'Content-Type': 'multipart/form-data'
					}
				})
				.then((response) => {
					Swal.fire({
						title: 'Success!',
						text: 'The project has been successfully saved!',
						icon: 'success',
						confirmButtonText: 'OK'
					}).then((result) => {
						if (result.isConfirmed || result.value) {
							window.location.reload(); 
						}
					});
				})
				.catch((err) => {
					Swal.fire({
						title: 'Error!',
						text: `Failed to save the project: ${err.response?.data?.message || err.message}`,
						icon: 'error',
						confirmButtonText: 'OK'
					});
				});
		}
	};

	const initializeWeb3 = () => {
		if (window.ethereum) {
			return new Web3(window.ethereum);
		} else {
			console.error('MetaMask is not installed');
			return null;
		}
	};
	
	const switchOrAddChain = async (network) => {
		const currentChainId = await window.ethereum.request({ method: 'eth_chainId' });
		if (currentChainId !== network.chainId) {
			try {
				await window.ethereum.request({
					method: 'wallet_switchEthereumChain',
					params: [{ chainId: network.chainId }],
				});
			} catch (error) {
				if (error.code === 4902) {
					await window.ethereum.request({
						method: 'wallet_addEthereumChain',
						params: [{
							chainId: network.chainId,
							chainName: network.name,
							rpcUrls: [network.rpc],
							blockExplorerUrls: [network.blockExplorerUrl],
						}],
					});
				} else {
					throw new Error(`Failed to switch to ${network.name}`);
				}
			}
		}
	};
	
	const getContractAbi = () => {
		try {
			return PublicSaleABI;
		} catch (error) {
			console.error('Error parsing contract ABI:', error);
			return null;
		}
	};

	const contractDeployment = async (data) => {
		const web3 = initializeWeb3();
		if (!web3) return;
	
		const { network, startDate, depositEndDate, tokenReleaseDate, cliffingStartDate, cliffingEndDate, ...rest } = data;
	
		const contractAddress = data.network.factoryContractAddress;
		const abi = getContractAbi();
		const facoryContract = FacoryContract
		if (!facoryContract) return;
	
		let factory = new web3.eth.Contract(facoryContract, contractAddress);

		const contract = new web3.eth.Contract(abi, contractAddress);
		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
		const fromAddress = sessionStorage.getItem('metamaskAddress') || accounts[0];
	
		if (!fromAddress) return

		const cliffingPeriod = Math.abs(new Date(cliffingEndDate).getTime() / 1000 - new Date(cliffingStartDate).getTime() / 1000);
		const startdate = new Date(data.depositStartDate);
		const startDateTimestamp = startdate.getTime();
	
		const erc20ABI = Erc20ABI;
		if (!erc20ABI) return;
	
		const erc20Contract = new web3.eth.Contract(erc20ABI, data.tokenAddress);
		if (!erc20Contract) return;
	
		const decimal = await erc20Contract.methods.decimals().call();
		const enddate = new Date(data.depositEndDate);
		const endDataTimeStamp = enddate.getTime();
	
		const tgDate = new Date(data.tokenReleaseDate);
		const tg_date = tgDate.getTime();
	
		try {
			await switchOrAddChain(network);
			const factoryOwner = await factory.methods.owner().call();
			const isAdmin = await factory.methods.adminList(accounts[0]).call();
			let owner = factoryOwner?.toString()?.toLowerCase();
			let acc = accounts[0]?.toString()?.toLowerCase();
			if ((owner !== acc) && !isAdmin) {
				Swal.fire({
					icon: 'error',
					title: 'Permission Denied',
					text: "This wallet does not have permission to execute this transaction",
				});
				return;
			}
	
			const receipt = await contract.methods.createpublicsale(
				data.tokenAddress,
				data.currency.contractAddress,
				data.rateInUSDT,
				startDateTimestamp / 1000,
				endDataTimeStamp / 1000,
				cliffingPeriod,
				// data.vestingFrequency * 60,
				data.vestingFrequency * 86400,
				data.vestingPercentage,
				tg_date / 1000,
				data.tokenReleasePercentage,
			).send({ from: fromAddress });
	
			let deployer = receipt.from.toString();
			const deployContractAddress = trimLeadingZeros(receipt.logs[1].data);
			await updateProject(data.id, receipt.status, receipt.transactionHash, deployContractAddress, deployer);
		} catch (error) {
			console.error('Error interacting with contract:', error);
			Swal.fire({
				icon: 'error',
				title: 'Transaction Failed',
				text: error.message || 'An error occurred during the transaction.'
			});
		}
	};

	function trimLeadingZeros(address) {
		return '0x' + address.slice(2).replace(/^0+/, '');
	}

	async function updateProject(id, status, transactionHash, address, deployer) {
		let data = {
			isActive: true,
			launchpadContractAddress: address.toString(),
			txHash: transactionHash.toString(),
			status: Number(status),
			isDeployed: Number(status) === 1 ? true : false,
			deployer
		}
		await axios.patch(`${apiUrl}/projects/${id}`, data, {
			headers: {
				'Content-Type': 'application/json'
			}
		})
			.then((response) => {
				Swal.fire({
					title: 'Success!',
					text: 'The project has been successfully deployed!',
					icon: 'success',
					confirmButtonText: 'OK'
				}).then((result) => {
					if (result.isConfirmed || result.value) {
						window.location.reload();
					}
				});
			})
			.catch((err) => {
				Swal.fire({
					title: 'Error!',
					text: `Failed to update the project: ${err.response?.data?.message || err.message}`,
					icon: 'error',
					confirmButtonText: 'OK'
				});
			});
	}

	const getCurrencyName = useMemo(() => {
		const currency = validationForFirstStep.values.currency;
		return currency ? currency.name : ''; 
	}, [validationForFirstStep.values.currency]);

	const handleAddNewStage = (isDirectInsert = false) => {
		if (stageDetails[0].active === true) {
			setStageDetailIndex(stageDetails.length);
		}
		toggleStageModal({
			action: "add",
			active: true,
			isDirectInsert: isDirectInsert,
		});
		validationForStage.resetForm();
	};

	const handleEditStage = (stageDetailIndex, isDirectInsert = false) => {
		setStageDetailIndex(stageDetailIndex);
		toggleStageModal({
			action: "edit",
			active: true,
			isDirectInsert: isDirectInsert,
		});
	};

	const handleAddNewTeamInfo = () => {
		if (teamInfo[0].active === true) {
			setTeamInfoIndex(teamInfo.length);
		} else {
			if (teamInfo.length === 1) setTeamInfoIndex(0);
		}
		toggleTeamInfoModal({ action: "add", active: true });
		validationForTeamInfoStep.resetForm();
	};


	const handleAddProjectPicture = () => {
		toggleProjectLogoModal(true);
		validationForProjectPicture.resetForm();
	};

	const handleEditTeamInfo = (index) => {
		setTeamInfoIndex(index);
		toggleTeamInfoModal({ action: "edit", active: true });
	};

	const handleDeleteTeamInfo = (index) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to delete?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				if (index === 0) {
					teamInfo[index].active = false;
					teamInfo[index].name = "",
					teamInfo[index].designation = "",
					teamInfo[index].picture = ""
				} else {
					teamInfo.splice(index, 1);
				}
				handleTeamInfoFormData([...teamInfo]);
			}
		});
	};

	const handleAddNewTokenomic = () => {
		if (tokenomicDetails[0].active === true) {
			setTokenomicDetailsIndex(tokenomicDetails.length);
		} else {
			if (tokenomicDetails.length === 1) setTokenomicDetailsIndex(0);
		}
		toggleTokenomicModal({ action: "add", active: true });
		validationForTokenomicStep.resetForm();
	};

	const handleEditTokenomic = (index) => {
		setTokenomicDetailsIndex(index);
		toggleTokenomicModal({ action: "edit", active: true });
	};

	const handleDeleteTokenomic = (index) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to delete?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				const newTokenomicDetails = [...tokenomicDetails];
				const removedPercentage = parseFloat(newTokenomicDetails[index].percentage || 0);
				if (index === 0) {
					tokenomicDetails[index].active = false;
					tokenomicDetails[index].label = "";
					tokenomicDetails[index].percentage = "";
				} else {
					tokenomicDetails.splice(index, 1);
				}
				const newTotalSum = totalSumTokenomic - removedPercentage;
            	setTotalSumTokenomic(newTotalSum);
				handleTokenomicFormData([...tokenomicDetails]);
			}
		});
	};

	const getAddressTokenDetails = () => {
		const { tokenAddress, network } = validationForFirstStep.values;
		if (tokenAddress && network) {
			dispatch(getTokenDetails({ tokenAddress, network }));
		}
	};

	const handleDeleteStage = (stageDetailIndex) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to delete?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				if (stageDetailIndex === 0) {
					stageDetails[stageDetailIndex].active = false;
				} else {
					stageDetails.splice(stageDetailIndex, 1);
					for (
						let stageIndex = stageDetailIndex;
						stageIndex < stageDetails.length;
						stageIndex++
					) {
						stageDetails[stageIndex]["stageNumber"] = stageIndex;
					}
				}
				handleStageFormData([...stageDetails]);
			}
		});
	};

	const checkNetwork = async (_chainId, rpc, networkName) => {
		if (window.ethereum) {
			const chainId = await window.ethereum.request({ method: 'eth_chainId' });
			if (chainId !== _chainId ) {
				try {
					await window.ethereum.request({
						method: 'wallet_switchEthereumChain',
						params: [{ chainId: _chainId}], 
					});
				} catch (error) {
					if (error.code === 4902) {
						try {
							// console.log(rpc)
							await window.ethereum.request({
								method: 'wallet_addEthereumChain',
								params: [
									{
										chainId: _chainId,
										chainName: networkName,
										rpcUrls: [rpc],
									},
								],
							});
							// window.location.reload()
						} catch (addError) {
							console.error('Failed to add the Fantom Testnet', addError);
						}
					}
					console.error('Failed to switch to the Fantom Testnet', error);
				}
			}
		} else {
			console.log('Ethereum object not found, install MetaMask.');
		}
	}

	const handleDeployLunchpad = async (data) => {
		const defaultToken = "0x0e80Ac387f4447f7B9f323c41B5504649bDf88b9"
		if(data.tokenAddress === defaultToken){
			Swal.fire({
				icon: 'error',
				title: 'Deploying default token',
				text: "Please check the token address",
			})
			return;
		}
		const isoDateString = data.startDate; 
		const givenDate = new Date(isoDateString).getTime();
		const currentDate = new Date().getTime();
		
		if (givenDate <= currentDate) {
			Swal.fire({
				icon: 'error',
				title: 'Permission Denied',
				text: "Time has already elapsed",
			});
			return; 
		}
		await checkNetwork(data.network.chainId, data.network.rpc, data.network.name)
		const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
				const fromAddress = sessionStorage.getItem('metamaskAddress', accounts[0]); 
				if (!fromAddress) return Swal.fire({
					icon: 'error',
					title: 'Wallet not connected',
					text: 'Please connect your wallet',
				});
		if (data?.id) {
			// console.log("data", data)
			let rateinwei = data.rateInUSDT * (10 ** 18)
			// let rateinwei = data.rateInUSDT * (10 ** data.currency.decimal)
			let _vestingPercentage = data.vestingPercentage * (10 ** 3)
			let _tokenReleasePercentage = data.tokenReleasePercentage * (10 ** 3)
			let deploy = await contractDeployment({...data,
				rateInUSDT : rateinwei,
				vestingPercentage : _vestingPercentage,
				tokenReleasePercentage : _tokenReleasePercentage,})
		}
		setDeployInfo({
			isOpenModal: !deployInfo.isOpenModal,
			launchpad: data,
		});
	};

	const handleListingStageInfo = (data) => {
		handleAddEditModal(data, true);
		if (listingStageInfo.isOpenModal === true) refreshTableData.current();
		setListingStageInfo({
			isOpenModal: !listingStageInfo.isOpenModal,
		});
	};

	const handleToggle = (data) => {
		Swal.fire({
			title: "Are you sure?",
			text: "Do you really want to update?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(toggle({ ...data, isActive: !data.isActive }));
			}
		});
	};
	
	const handleToggleStageSwap = (data) => {
		Swal.fire({
			title: "Are you sure?",
			text: `Do you really want to ${data.isSwapEnable === true ? "disable swap" : "enable swap"
				}?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#3085d6",
			cancelButtonColor: "#d33",
			confirmButtonText: "Confirm",
		}).then((result) => {
			if (result.value) {
				Swal.fire({
					title: "Updating",
					html: "Please wait...",
					allowEscapeKey: false,
					allowOutsideClick: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});
				dispatch(toggleStageSwap({ ...data, id: details.id }));
			}
		});
	};

	const validationSchemaBuyBack = Yup.object({
		refundAddress: Yup.string()
			.matches(/^0x[a-fA-F0-9]{40}$/, "Invalid address")
			.required("Buyback address is required"),
	});

	// Formik form setup
	const formik = useFormik({
		initialValues: {
			refundAddress: "",
		},
		validationSchemaBuyBack,
		onSubmit: (values) => {
			// Handle form submission
		},
	});

	const validationSchema = Yup.object({
		depositFee: Yup.number()
			.typeError("Deposit fee must be a number")
			.positive("Deposit fee must be positive")
			.required("Deposit fee is required"),
		withdrawalFee: Yup.number()
			.typeError("Withdrawal fee must be a number")
			.positive("Withdrawal fee must be positive")
			.required("Withdrawal fee is required"),
		tokenClaimFee: Yup.number()
			.typeError("Token claim fee must be a number")
			.positive("Token claim fee must be positive")
			.required("Token claim fee is required"),
	});

	const formikReclaim = useFormik({
		initialValues: {
			depositFee: '',
			withdrawalFee: '',
			tokenClaimFee: '',
		},
		validationSchema: validationSchema,
		onSubmit: values => {
			// console.log(values);
			// Perform your submission logic here
		},
	});

	const couldHaveAddUpdatePermission = () => {
		if (auth.user.isSuperAdmin) return true;
		const isUpdatePermission = hasPermission(
			["update:Projects"],
			auth.user.allowedRoles
		);
		const isAddPermission = hasPermission(
			["create:Projects"],
			auth.user.allowedRoles
		);
		if (isUpdatePermission && isAddPermission) return true;
		else if (isUpdatePermission && !isEmpty(details._id)) return true;
		else if (isAddPermission && isEmpty(details._id)) return true;
		else return false;
	};

	const handlePageChange = async (newPage) => {
		await setPage(newPage+1); 
		await refreshTableData.current();
	};
  
	const handleRowsPerPageChange = async (newRowsPerPage) => {
		await setPage(1)
	  	await setRowsPerPage(newRowsPerPage);
	  	await refreshTableData.current();
	};

	const columns = () => [
		{
			label: "Id",
			name: "id",
			options: {
				filter: false,
				sort: true,
				download: true,
				customBodyRender: (item) => {
						return (
						<div className="text-center">
							<div className=" font-size-14">
								{item}
							</div>
						</div>
					);
				},
			},
		},
		{
			label: "Name",
			name: "name",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => {
					return (

						<div className="text-left">
							<div className="font-size-14">
								{item}
							</div>
						</div>
					);
				},
			},
		},
		{
			label: "Network",
			name: "networkName",
			options: {
				filter: true,
				customFilterListOptions: { render: v => `Network: ${v}` },
				sort: false,
				download: true,
				customBodyRender: (item) => {
					return (

						<div className="text-center">
							<div className="font-size-14">
								{item}
							</div>
						</div>
					);
				},
			},
		},
		{
			name: "status",
			label: "Status",
			options: {
				filter: true,
				customFilterListOptions: { render: v => `Status: ${v}` },
				sort: false,
				download: true,
				customBodyRender: (item) => {
					return item === "Draft" ? (
						<div className="text-center"> 					
							<div className="badge text-bg-warning font-size-14">
								Draft
							</div>
						</div>
					) : item === "Deployed" ? (
						<div className="text-center"> 					
							<div className="badge text-bg-success font-size-14">
								Deployed
							</div>
						</div>
					) : ""
				},
			},
		},
		{
			label: "Active",
			name: "active",
			options: {
				filter: false,
				sort: false,
				download: false,
				customBodyRender: (item) => {
					return (						
							<div className="square-switch text-center">
								<Input
									type="checkbox"
									id={`update:Projects-${item.id}`}
									switch="none"
									checked={item.isActive}
									onClick={() => {
										handleToggle(item);
									}}
									onChange={() => {
										handleToggle(item);
									}}
									disabled={
										!(hasPermission(
											["create:Projects"],
											auth.user.allowedRoles
										) || auth.user.isSuperAdmin)
									}
								/>
								<Label
									htmlFor={`update:Projects-${item.id}`}
									data-on-label="Yes"
									data-off-label="No"
								></Label>
							</div>						
					);
				},
			},
		},
		{
            label: "Actions",
            name: "action",
            options: {
                filter: false,
                sort: false,
                empty: true,
                download: false,
				display:(hasPermission(
					["update:Projects","delete:Projects","create:Projects"],
					auth.user.allowedRoles
				) || auth.user.isSuperAdmin),
				viewColumns: (hasPermission(
					["read:Projects"],
					auth.user.allowedRoles
				) || auth.user.isSuperAdmin),
                customBodyRender: (data) => {
                    return (
                        <div className="d-flex flex-wrap gap-3 justify-content-center">
                            {data.status !== "finished" && (
								<HasAnyPermission
									permission={[
										"update:Projects",
										"read:Projects",
									]}
								>
								</HasAnyPermission>
							)}
							{data.isDeployed !== true && auth.user.isSuperAdmin && (
								<button
									type="button"
									onClick={(e) => {
										e.preventDefault();
										handleDeployLunchpad(data, true);
									}}
									className=" btn btn-sm btn-success waves-effect waves-light"
								>
									<i className="bx bx-rocket font-size-16 align-middle me-2"></i>{" "}
									Deploy
								</button>
							)}
							{data.isDeployed !== true && (hasPermission(
											["update:Projects"],
											auth.user.allowedRoles
										) || auth.user.isSuperAdmin) && (
									<button
										type="button"
										onClick={(e) =>
											handleAddEditModal(data)
										}
										className=" btn btn-sm btn-primary waves-effect waves-light"
									>

										<i className="bx bx-edit font-size-16 align-middle me-2"></i>{" "}
										Edit
									</button>
								)}
							{data.isDeployed === true && (hasPermission(
											["update:Projects"],
											auth.user.allowedRoles
										) || auth.user.isSuperAdmin ) && (
									<HasAnyPermission
										permission={[
											"read:Projects",
										]}
									>
										<button
											type="button"
											disabled={true}
											// onClick={(e) => removeItem(data.id)}
											className=" btn btn-sm btn-danger waves-effect"
										>
											<i className="bx bx-rocket align-middle font-size-16 me-1"></i>{" "}
											Live
										</button>
										<button
											type="button"
											onClick={(e) => projectSetting(data)}
											className="btn btn-sm btn-primary waves-effect waves-light"
										>
											<i className="bx bx-cog align-middle font-size-16 me-1"></i>{" "}
											Project Settings
										</button>
									</HasAnyPermission>
								)}
							{data.status === "finished" && (hasPermission(
											["read:Projects"],
											auth.user.allowedRoles
										) || auth.user.isSuperAdmin) && (
									<button
										type="button"
										className=" btn btn-sm btn-success waves-effect waves-light"
										disabled
									>
										Finished
									</button>
								)}
                            {data.isDeployed === true && (<button
                                type="button"
                                onClick={() => handleToggleTableVisibility(data)}
                                className="btn btn-sm btn-primary waves-effect waves-light"
                            >
							<i className="bx bx-user align-middle font-size-16 me-1"></i>{" "}
                                Registered Users
                            </button>)}
                            
                        </div>
                    );
                },
            },
        },
	];

	const resultFormatter = (result) => {
		return result.projects.map((item) => {
			return {
				...item,
				id: item.id,
				active: item,
				action: item,
				status: item.isDeployed ? "Deployed"  : "Draft",
				name: item.projectTitle,
				networkName: item.network?.name
			};
		});
	};

	const columns1 = () => [
		{
			label: "BID",
			name: "userBID",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => (
					<div className="text-center">
						<div className="font-size-14">
							{item}
						</div>
					</div>
				),
			},
		},
		{
			label: "Wallet Address",
			name: "walletAddress",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => (
					<div className="text-center">
						<div className="font-size-14">
							{item}
						</div>
					</div>
				),
			},
		},
		{
			label: "Date",
			name: "registrationDate",
			options: {
				filter: false,
				sort: false,
				download: true,
				customBodyRender: (item) => (
					<div className="text-left">
						<div className="font-size-14">
							{ new Date(item).toUTCString()}
						</div>
					</div>
				),
			},
		},
	];
	
	const resultFormatter1 = (result) => {
		// console.log("result", result)
		setRegisteredUsers(result.count)
		try{
			setIsLoadingUSDT(true)
			const publicSaleABI = PublicSaleABI
			const project = projects?.find(
			(project) => project.id === selectedProjectId
		);
			checkNetwork(project.network.chainId,project.network.rpc,project.network.name).then(() => {
			const address = project.launchpadContractAddress
			// const decimal = project.network.currencies[0].decimal;
			const web3 = initializeWeb3();
			const contract = new web3.eth.Contract(publicSaleABI, address);
			let totalRaised =  contract.methods.viewTotalRaisedUsdt().call().then( (result) => {
				result = parseInt(result,10)
				const formattedResult = result / (10 ** (project.network.currencies[0].decimal));
				setTotalRaisedUSDT(formattedResult.toFixed(2))
				setIsLoadingUSDT(false);
			})
			})
		}catch(e){ setIsLoadingUSDT(false)}
        return result.allUsers.map((item) => ({
            userBID: item.userBID,
            registrationDate: item.registrationDate,
            walletAddress: item.walletAddress,
        }));
    };
	return (
		<React.Fragment>
			<div className="page-content">
				<Helmet>
					<title>Launchpad | Rampstarter</title>
				</Helmet>
				<Container fluid>
					{/* Render Breadcrumbs */}
					<Breadcrumbs
						title="Admin"
						breadcrumbItem="Projects"
					/>
					{listingStageInfo.isOpenModal === true && (
						<ListingStageInfo
							handleListingStageInfo={handleListingStageInfo}
							listingStageInfo={listingStageInfo}
							stageDetails={stageDetails}
							handleAddNewStage={handleAddNewStage}
							handleEditStage={handleEditStage}
							// handleDeleteStage={handleDeleteStage}
							handleToggleStageSwap={handleToggleStageSwap}
						/>
					)}
					<TeamInfoModal
						teamInfoModalDetails={teamInfoModalDetails}
						toggleTeamInfoModal={toggleTeamInfoModal}
						validationForTeamInfoStep={validationForTeamInfoStep}
						selectedTeamFile={selectedTeamFile}
					/>
					<ProjectLogoModal
						projectLogoModalDetails={projectLogoModalDetails}
						toggleProjectLogoModal={toggleProjectLogoModal}
						validationForProjectPicture={validationForProjectPicture}
						selectedLogoFile={selectedLogoFile}
					/>
					<TokenomicModal
						tokenomicModalDetails={tokenomicModalDetails}
						toggleTokenomicModal={toggleTokenomicModal}
						validationForTokenomicStep={validationForTokenomicStep}
						couldHaveAddUpdatePermission={
							couldHaveAddUpdatePermission
						}
					/>
					<Row>
						<Col lg="12">
							<Card>
								<CardBody>
									<Row>
										<Col xl="12">
											<div className="table-rep-plugin">
												<div className="table-responsive">
													<HasAnyPermission
														permission={[
															"create:Projects","read:Projects","update:Projects"
														]}
													>
														<ReactDataTable
															title={'Projects'}		
															url={`${apiUrl}/projects/all?limit=${total}`}
															columns={columns()}
															resultFormatter={resultFormatter}
															setRefresh={refreshTableData}
															getLoaderStatus={getLoaderStatus}
															disableFilterIcon={false}
															disableSearchIcon={false}
															onChangePage={handlePageChange}
															onChangeRowsPerPage={handleRowsPerPageChange}
															total={total}
															sort={'des'}
															sortColumn={'id'}
															origin={
																<div className="row">
																	<div className="col-auto h4">
																		Projects
																		&nbsp;
																		<HasAnyPermission permission={["create:Projects"]}>
																			<button
																				onClick={() => {
																					handleAddEditModal();
																				}}
																				disabled={
																					!(
																						hasPermission(["create:Projects"], auth.user.allowedRoles) ||
																						auth.user.isSuperAdmin
																					)
																				}
																				type="button"
																				className="btn btn-primary waves-effect waves-light"
																			>
																				Add Project
																			</button>
																		</HasAnyPermission>
																	</div>
																</div>
															}
														/>

													</HasAnyPermission>

													<Modal
														isOpen={
															isOpenAddEditModal
														}
														// toggle={
														// 	handleAddEditModal
														// }
														size="xl"
														centered={true}
													>
														<ModalHeader
															toggle={
																handleAddEditModal
															}
															tag="h4"
														>
															{details?.id
																? "Edit Project"
																: "Add Project"}
														</ModalHeader>
														<ModalBody>
															<fieldset
																disabled={
																	!couldHaveAddUpdatePermission()
																}
															>
																<div
																	id="basic-pills-wizard"
																	className="twitter-bs-wizard"
																>
																	<ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
																		<NavItem>
																			<NavLink
																				href="#"
																				className={classnames(
																					{
																						active:
																							activeTabFormStep ===
																							1,
																					}
																				)}
																			// onClick={() => {
																			// 	setActiveTabFormStep(
																			// 		1
																			// 	);
																			// }}
																			>
																				<div
																					className="step-icon"
																					data-bs-toggle="tooltip"
																					data-bs-placement="top"
																					title="Token Details"
																				>
																					1
																				</div>
																			</NavLink>
																		</NavItem>
																		<NavItem>
																			<NavLink
																				href="#"
																				className={classnames(
																					{
																						active:
																							activeTabFormStep ===
																							2,
																					}
																				)}
																			>
																				<div
																					className="step-icon"
																					data-bs-toggle="tooltip"
																					data-bs-placement="top"
																					title="DeFi Launchpad Info"
																				>
																					2
																				</div>
																			</NavLink>
																		</NavItem>

																		<NavItem>
																			<NavLink
																				href="#"
																				className={classnames(
																					{
																						active:
																							activeTabFormStep ===
																							3,
																					}
																				)}
																				onClick={() => {
																					setActiveTabFormStep(
																						3
																					);
																				}}
																			>
																				<div
																					className="step-icon"
																					data-bs-toggle="tooltip"
																					data-bs-placement="top"
																					title="Team Info"
																				>
																					3
																				</div>
																			</NavLink>
																		</NavItem>
																		<NavItem>
																			<NavLink
																				href="#"
																				className={classnames(
																					{
																						active:
																							activeTabFormStep ===
																							4,
																					}
																				)}
																			// onClick={() => {
																			// 	setActiveTabFormStep(
																			// 		4
																			// 	);
																			// }}
																			>
																				<div
																					className="step-icon"
																					data-bs-toggle="tooltip"
																					data-bs-placement="top"
																					title="Add Additional Info"
																				>
																					4
																				</div>
																			</NavLink>
																		</NavItem>
																		<NavItem>
																			<NavLink
																				href="#"
																				className={classnames(
																					{
																						active:
																							activeTabFormStep ===
																							5,
																					}
																				)}
																			// onClick={() => {
																			// 	setActiveTabFormStep(
																			// 		4
																			// 	);
																			// }}
																			>
																				<div
																					className="step-icon"
																					data-bs-toggle="tooltip"
																					data-bs-placement="top"
																					title="Add Additional Info"
																				>
																					5
																				</div>
																			</NavLink>
																		</NavItem>
																	</ul>

																	<TabContent
																		className="twitter-bs-wizard-tab-content"
																		activeTab={
																			activeTabFormStep
																		}
																	>
																		<TabPane
																			tabId={
																				1
																			}
																		>
																			<VerifyTokenStepOne
																				networkInfo={
																					networkInfo
																				}
																				// fetchCurrency={
																				// 	fetchCurrency
																				// }
																				currencyOptions={
																					currencyOptions
																				}
																				setCurrencyOptions={
																					setCurrencyOptions
																				}
																				validationForFirstStep={
																					validationForFirstStep
																				}
																				getAddressTokenDetails={
																					getAddressTokenDetails
																				}
																				clearTokenResponse={
																					clearTokenResponse
																				}
																				handleChangeNetwork={
																					handleChangeNetwork
																				}
																				launchpad={
																					launchpad
																				}
																				details={
																					details
																				}
																				handleChangeCurrency={
																					handleChangeCurrency
																				}
																				getCurrencyOption={
																					getCurrencyOption
																				}
																				getCurrencyName={
																					getCurrencyName
																				}
																				initializeWeb3={initializeWeb3}
																			/>
																		</TabPane>

																		<TabPane
																			tabId={
																				2
																			}
																		>
																			<StageDetailStepSecond
																				validationForSecondStep={
																					validationForSecondStep
																				}
																				validationForFirstStep={
																					validationForFirstStep
																				}
																				handleAddNewStage={
																					handleAddNewStage
																				}
																				stageDetails={
																					stageDetails
																				}
																				handleDeleteStage={
																					handleDeleteStage
																				}
																				handleEditStage={
																					handleEditStage
																				}
																				stageModalDetails={stageModalDetails}
																				toggleStageModal={toggleStageModal}

																				validationForStage={validationForStage}
																				couldHaveAddUpdatePermission={
																					couldHaveAddUpdatePermission
																				}
																				launchpadOptions={launchpadOptions}
																			/>
																		</TabPane>

																		<TabPane
																			tabId={
																				3
																			}
																		>
																			<AdditionalInfoStepThird
																				validationForProjectPicture={validationForProjectPicture}
																				handleAddProjectPicture={
																					handleAddProjectPicture
																				}
																				projectPicture={projectPicture}
																				selectedLogoFile={selectedLogoFile}
																				validationForThirdStep={
																					validationForThirdStep
																				}
																				getCurrencyName={
																					getCurrencyName
																				}
																				onEditorChange={
																					onEditorChange
																				}
																				handleAddNewStage={
																					handleAddNewStage
																				}
																				stageDetails={
																					stageDetails
																				}
																				handleDeleteStage={
																					handleDeleteStage
																				}
																				handleEditStage={
																					handleEditStage
																				}
																				editor={
																					editor
																				}
																			/>
																		</TabPane>

																		<TabPane
																			tabId={
																				4
																			}
																		>
																			<TeamTokenomicWhitepaperInfoStepFour
																				validationForWhitePaper={
																					validationForWhitePaper
																				}
																				handleAddNewTeamInfo={
																					handleAddNewTeamInfo
																				}
																				teamInfo={
																					teamInfo
																				}
																				handleDeleteTeamInfo={
																					handleDeleteTeamInfo
																				}
																				handleEditTeamInfo={
																					handleEditTeamInfo
																				}
																				handleAddNewTokenomic={
																					handleAddNewTokenomic
																				}
																				tokenomicDetails={
																					tokenomicDetails
																				}
																				handleDeleteTokenomic={
																					handleDeleteTokenomic
																				}
																				handleEditTokenomic={
																					handleEditTokenomic
																				}
																				selectedFile={
																					selectedFile
																				}
																				whitepaper={
																					whitepaper
																				}
																			/>
																		</TabPane>

																		<TabPane
																			tabId={
																				5
																			}
																		>
																			<ReviewStepFive
																				validationForThirdStep={
																					validationForThirdStep
																				}
																				getCurrencyName={
																					getCurrencyName
																				}
																				validationForFirstStep={
																					validationForFirstStep
																				}
																				stageDetails={
																					stageDetails
																				}
																			/>
																		</TabPane>
																	</TabContent>
																	<ul className="pager wizard twitter-bs-wizard-pager-link">
																		{activeTabFormStep !==
																			1 && (
																				<li
																					className={
																						activeTabFormStep ===
																							1
																							? "previous disabled"
																							: "previous"
																					}
																				>
																					<Link
																						to="#"
																						className={
																							activeTabFormStep ===
																								1
																								? "btn btn-primary disabled"
																								: "btn btn-primary"
																						}
																						onClick={() => {
																							toggleTab(
																								activeTabFormStep -
																								1,
																								true
																							);
																						}}
																					>
																						<i className="bx bx-chevron-left me-1"></i>{" "}
																						Previous
																					</Link>
																				</li>
																			)}

																		<li
																			className={
																				activeTabFormStep ===
																					5
																					? "next disabled"
																					: "next"
																			}
																		>
																			<Link
																				to="#"
																				className="btn btn-primary"
																				disabled={
																					isLoading
																				}
																				onClick={(
																					e
																				) => {
																					e.preventDefault();
																					if (activeTabFormStep === 4 && totalSumTokenomic !== 100) {
																						toast.error("Total percentage must be 100% before proceeding.");
																						return;
																					}
																					if (!details?.id && activeTabFormStep === 3 && isEmpty(projectPicture.picture)) {
																						toast.error("Please upload logo");
																						return;
																					}
																					activeTabFormStep ===
																						5
																						? handleFinalFormSubmit()
																						: toggleTab(
																							activeTabFormStep +
																							1
																						);
																				}}

																			>
																				{activeTabFormStep ===
																					5
																					? "Submit"
																					: "Next"}

																				<i className="bx bx-chevron-right ms-1"></i>
																			</Link>
																		</li>
																	</ul>
																</div>
															</fieldset>
														</ModalBody>
													</Modal>
													<Modal
													isOpen={isTableVisible}
													size="xl"
													centered={true}
													toggle={handleToggleTableVisibility}
												>
													<ModalHeader
													toggle={handleToggleTableVisibility}
													>
														Project ID: {selectedProjectId ? selectedProjectId : ''} 
													</ModalHeader>
													<br></br>
													<div className=" align-self-center">
														<ul className="list-group">
															<li className="list-group-item">
																Total Raised USDT:
																{isLoadingUSDT ? <Spinner color="primary" className="small-spinner"/> :
																	<span className="badge badge-soft-success font-size-16 rounded-pill bg-soft-success float-end">
																	{totalRaisedUSDT}
																</span>}
															</li>
															<li className="list-group-item">
																Total Registered Users :
																<span className="badge badge-soft-primary font-size-16 rounded-pill bg-soft-primary float-end">
																{registeredUsers} 
																</span>
															</li>
														</ul>
													</div>
													<ModalBody>
															<ReactDataTable
																title={selectedProject ? selectedProject : ''} 
																url={`${apiUrl}/projects/interested/${selectedProjectId}`}
																columns={columns1()}
																resultFormatter={resultFormatter1}
																disableFilterIcon={true}
																disableSearchIcon={true}
																origin={
																	<div className="row">
																		<div className="col-auto h4">
																			{selectedProject ? selectedProject : ''}
																			&nbsp;
																		</div>
																	</div>
																}
															/>
													</ModalBody>
												</Modal>
												</div>
											</div>
										</Col>
									</Row>
								</CardBody>
							</Card>
						</Col>
					</Row>
				</Container>

				<ContractInteraction
					initializeWeb3={
						initializeWeb3
					}
					isAdditionalSetting={
						isAdditionalSetting
					}
					projectSetting={
						projectSetting
					}
					couldHaveAddUpdatePermission={
						couldHaveAddUpdatePermission
					}
					formik={
						formik
					}
					formikReclaim={
						formikReclaim
					}
					validationForFirstStep={
						validationForFirstStep
					}
					settingData={
						settingData
					}
					getContractAbi={
						getContractAbi
					}
					switchOrAddChain={
						switchOrAddChain
					}
				/>
			</div>
		</React.Fragment >
	);
};

export default Launchpad;