import React, { Component } from "react";
import axios from "axios";
import MUIDataTable, { ExpandButton } from "mui-datatables";
import { CircularProgress } from "@mui/material";
import CustomSearchRender from './CustomSearchRender';
const CancelToken = axios.CancelToken;
let cancel;

export default class ReactDataTable extends Component {
	constructor(props) {
		super(props);
		const { rowsPerPage, total , filters, sort, sortColumn} = props;
		this.state = {
			data: [],
			page: 1,
			count: total ? total : 1000,
			rowsPerPage: rowsPerPage ? rowsPerPage : 10,
			searchText: "",
			sort: sort ? sort : "",
			sortColumn: sortColumn ? sortColumn : "",
			filters: filters ? filters : [],
			url: "",
			isLoading: false,
			rowsExpanded: [],
			unExpandedRows: [],
			noMatch: (
				<CircularProgress size={30} style={{ color: "#3f51b5" }} />
			),
		};
	}

	componentDidMount = () => {
		this.getDataList();
		if (this.props.setRefresh) {
			this.props.setRefresh.current = this.getDataList;
		}
		if (this.props?.getLoaderStatus) {
			this.props.getLoaderStatus.current = this.getIsLoading;
		}
	};

	getIsLoading = () => {
		return this.state.isLoading;
	};

	sortData = (data, sortOrder, sortColumn) => {
		return data.slice().sort((a, b) => {
			if (a[sortColumn] < b[sortColumn]) return sortOrder === 'asc' ? -1 : 1;
			if (a[sortColumn] > b[sortColumn]) return sortOrder === 'asc' ? 1 : -1;
			return 0;
		});
	};

	getDataList = async () => {
		this.setState({
			noMatch: (
				<CircularProgress size={30} style={{ color: "#3f51b5" }} />
			),
			isLoading: true,
		});

		if (this.props.url === this.state.url) {
			cancel && cancel();
		}
		try {
			let filters = this.state.filters;
			if (this.props?.filters?.length)
				filters = [...filters, ...this.props.filters];
			const result = await axios.get(this.props.url, {
			// 	params,
				cancelToken: new CancelToken(function executor(c) {
					cancel = c;
				}),
			});
			if (result.status === 200) {
				let data,
					unExpandedRows = [];
				if (
					this.props?.expandableRows &&
					this.props?.isUnexpandedRows
				) {
					const formatData = this.props.resultFormatter(
						result.data?.data ? result.data.data : result.data
					);
					data = Array.isArray(formatData)
						? formatData
						: formatData.data;
					unExpandedRows = formatData.unExpandedRows
						? formatData.unExpandedRows
						: [];
				} else {
					data = this.props.resultFormatter(result.data?.data ? result.data.data : result.data);
				}
				let count = this.props.total;
				if(this.props.sort && this.props.sortColumn) {
					data = this.sortData(data, this.props.sort, this.props.sortColumn); 
				}
				this.setState({
					count: count,
					data: data,
					unExpandedRows: unExpandedRows,
					url: this.props.url,
					isLoading: false,
					noMatch: this.props?.noMatchTextLabels
						? this.props?.noMatchTextLabels
						: "Sorry, no matching records found.",
				});
			} else {
				this.setState({
					data: [],
					isLoading: false,
					noMatch: "Sorry, no matching records found.",
				});
			}
		} catch (error) {
			if (error.message)
				this.setState({
					data: [],
					isLoading: false,
				});
		}
	};

	handleFilterSubmit = (applyFilters) => {
		applyFilters();
		this.setState({
			page: 1,
			limit: 10,
			search: "xyz",
			active: true,
			status: 0
		});
		this.getDataList();
	};

	render() {
		const { data, count, searchText, noMatch, rowsExpanded } = this.state;
		const {
			url,
			columns,
			resultFormatter,
			setRefresh,
			origin,
			rowsPerPage,
			...otherProps
		} = this.props;
		const customComponents = {};
		const options = {
			// serverSide: true,
			filter: this.props?.disableFilterIcon === true ? false : true,
			search: this.props?.disableSearchIcon === true ? false : true,
			searchText: searchText,
			selectableRows: "none",
			print: true,
			download: true,
			responsive: "standard",
			count: count,
			viewColumns: false,
			textLabels: {
				body: {
					noMatch: noMatch,
				},
			},
			expandableRowsHeader: false,
			expandableRowsOnClick: false,
			setTableProps: () => ({
				className: "mb-0 table-bordered responsiveTable",
			}),
			customSearchRender: (searchText, handleSearch, hideSearch, options) => {
				return (
				  <CustomSearchRender
					searchText={searchText}
					onSearch={handleSearch}
					onHide={hideSearch}
					options={options}
				  />
				);
			  }
			};

		if (this.props?.expandableRows && this.props?.isUnexpandedRows) {
			customComponents["ExpandButton"] = (props) => {
				if (this.state.unExpandedRows?.includes(props.dataIndex))
					return <div style={{ width: "24px" }} />;
				return <ExpandButton {...props} />;
			};
		}
		const mergeOptions = { ...options, ...otherProps };
		return (
			<MUIDataTable
				title={origin}
				data={data}
				columns={columns}
				options={mergeOptions}
				components={customComponents}
			/>
		);
	}
}